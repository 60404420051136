import React, { useEffect, useState } from 'react'
import { InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react'
import { ToastContainer } from 'react-toastify'
import './app/styles/app.scss'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from './app/config/routes'
import { useAppDispatch, useAppSelector } from './app/hooks'
import AdminDashboardPage from './app/pages/dash'
import AppShell from './app/components/layout/app-shell'
import appAuthBindings from './app/helpers/auth/appAuthBindings'
import DummyAuth from './app/pages/dummy-auth'

import './app/styles/tailwind-boot.css'
import RecipesIndexPage from './app/pages/recipes'
import RecipesShowPage from './app/pages/recipes/show'
import RecipeCollectionSeriesEditPage from './app/pages/recipe-collections-series/edit'
import RecipeCollectionsShowPage from './app/pages/recipe-collections/show'
import RecipeVariantShowPage from './app/pages/recipes/recipe-variants/show'
import RecipeEditPage from './app/pages/recipes/edit'
import IngredientsIndexPage from './app/pages/ingredients'
import RecipesBulkUploadPage from './app/pages/recipes/bulk-upload'
import IngredientsShowPage from './app/pages/ingredients/show'
import IngredientsEditPage from './app/pages/ingredients/edit'
import RecipeBrowseCategoriesIndexPage from './app/pages/recipe-browse-categories/index'
import RecipeBrowseCategoriesShowPage from './app/pages/recipe-browse-categories/show'
import RecipeBrowseCategoriesEditPage from './app/pages/recipe-browse-categories/edit'
import IngredientExclusionCategoriesIndexPage from './app/pages/ingredient-exclusion-categories'
import IngredientExclusionCategoriesShowPage from './app/pages/ingredient-exclusion-categories/show'
import IngredientExclusionCategoriesEditPage from './app/pages/ingredient-exclusion-categories/edit'
import RecipeVariantEditPage from './app/pages/recipes/recipe-variants/edit'
import RecipeVariantNewPage from './app/pages/recipes/recipe-variants/new'
import RecipeVariantCookingTipsIndexPage from './app/pages/recipe-variant-cooking-tips'
import RecipeVariantStepCookingTipsIndexPage from './app/pages/recipe-variant-step-cooking-tips'
import RecipeVariantCookingTipsShowPage from './app/pages/recipe-variant-cooking-tips/show'
import RecipeVariantStepCookingTipsShowPage from './app/pages/recipe-variant-step-cooking-tips/show'
import RecipeVariantCookingTipsEditPage from './app/pages/recipe-variant-cooking-tips/edit'
import RecipeVariantStepCookingTipsEditPage from './app/pages/recipe-variant-step-cooking-tips/edit'
import IngredientExclusionCategoriesNewPage from './app/pages/ingredient-exclusion-categories/new'
import IngredientsNewPage from './app/pages/ingredients/new'
import SystemIndexPage from './app/pages/system'

function App() {
  // const query = useQuery()
  const dispatch = useAppDispatch()
  const authToken = useAppSelector(state => state.app.authToken)
  const [authError, setAuthError] = useState(false)

  useEffect(() => {
    appAuthBindings(dispatch, {
      onAuthError: () => {
        setAuthError(true)
      },
    })
  }, [])

  if (authError) return <div>An unknown auth error occured.</div>

  return (
    <div id="app">
      <CentralAuthentication>
        {process.env.REACT_APP_PSYCHIC_ENV === 'test' && !authToken ? <UnauthedApp /> : <AuthedApp />}
      </CentralAuthentication>
    </div>
  )
}

function CentralAuthentication({ children }: { children: React.ReactNode }) {
  return process.env.REACT_APP_PSYCHIC_ENV === 'test' ? (
    <>{children}</>
  ) : (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: ['User.Read'] }}
      interactionType={InteractionType.Redirect}
    >
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </MsalAuthenticationTemplate>
  )
}

function AuthedApp() {
  return (
    <AppShell>
      <Routes>
        <Route path={routes.app.dummyAuth} element={<Navigate to={routes.app.home} />} />
        <Route path={routes.app.home} element={<AdminDashboardPage />} />

        <Route path={routes.app.recipes.index} element={<RecipesIndexPage />} />
        <Route path={routes.app.recipes.bulkUpload} element={<RecipesBulkUploadPage />} />
        <Route path={routes.app.recipes.show(':id')} element={<RecipesShowPage />} />
        <Route path={routes.app.recipes.edit(':id')} element={<RecipeEditPage />} />
        <Route path={routes.app.recipes.recipeVariants.new(':id')} element={<RecipeVariantNewPage />} />
        <Route path={routes.app.recipes.recipeVariants.show(':id')} element={<RecipeVariantShowPage />} />
        <Route path={routes.app.recipes.recipeVariants.edit(':id')} element={<RecipeVariantEditPage />} />

        <Route path={routes.app.ingredients.index} element={<IngredientsIndexPage />} />
        <Route path={routes.app.ingredients.new} element={<IngredientsNewPage />} />
        <Route path={routes.app.ingredients.show(':id')} element={<IngredientsShowPage />} />
        <Route path={routes.app.ingredients.edit(':id')} element={<IngredientsEditPage />} />

        <Route
          path={routes.app.ingredientExclusionCategories.index}
          element={<IngredientExclusionCategoriesIndexPage />}
        />
        <Route
          path={routes.app.ingredientExclusionCategories.new}
          element={<IngredientExclusionCategoriesNewPage />}
        />
        <Route
          path={routes.app.ingredientExclusionCategories.show(':id')}
          element={<IngredientExclusionCategoriesShowPage />}
        />
        <Route
          path={routes.app.ingredientExclusionCategories.edit(':id')}
          element={<IngredientExclusionCategoriesEditPage />}
        />

        <Route path={routes.app.recipeCollectionSeries.edit} element={<RecipeCollectionSeriesEditPage />} />
        <Route path={routes.app.recipeCollections.show(':id')} element={<RecipeCollectionsShowPage />} />

        <Route path={routes.app.recipeBrowseCategories.index} element={<RecipeBrowseCategoriesIndexPage />} />
        <Route
          path={routes.app.recipeBrowseCategories.show(':id')}
          element={<RecipeBrowseCategoriesShowPage />}
        />
        <Route
          path={routes.app.recipeBrowseCategories.edit(':id')}
          element={<RecipeBrowseCategoriesEditPage />}
        />

        <Route
          path={routes.app.recipeVariantCookingTips.index}
          element={<RecipeVariantCookingTipsIndexPage />}
        />
        <Route
          path={routes.app.recipeVariantCookingTips.show(':id')}
          element={<RecipeVariantCookingTipsShowPage />}
        />
        <Route
          path={routes.app.recipeVariantCookingTips.edit(':id')}
          element={<RecipeVariantCookingTipsEditPage />}
        />

        <Route
          path={routes.app.recipeVariantStepCookingTips.index}
          element={<RecipeVariantStepCookingTipsIndexPage />}
        />
        <Route
          path={routes.app.recipeVariantStepCookingTips.show(':id')}
          element={<RecipeVariantStepCookingTipsShowPage />}
        />
        <Route
          path={routes.app.recipeVariantStepCookingTips.edit(':id')}
          element={<RecipeVariantStepCookingTipsEditPage />}
        />

        <Route path={routes.app.system.index} element={<SystemIndexPage />} />
      </Routes>
      <ToastContainer />
    </AppShell>
  )
}

function UnauthedApp() {
  return (
    <Routes>
      <Route path={routes.app.home} element={<Navigate to={routes.app.dummyAuth} />} />
      {process.env.REACT_APP_PSYCHIC_ENV === 'test' && (
        <Route path={routes.app.dummyAuth} element={<DummyAuth />} />
      )}
    </Routes>
  )
}

export default App
