import { useState } from 'react'
import { Select, SelectItem, Switch } from '@nextui-org/react'
import { RecipeFilterData, cuisines, dishTypes, mealTypes, subcuisines } from '../../../api/recipes'
import AppliancesAPI, { ApplianceSummary } from '../../../api/appliances'
import useMountEffect from '../../../hooks/useMountEffect'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import DietaryPreferencesAPI, { DietaryPreferenceSummary } from '../../../api/dietaryPreferences'

export default function RecipeFilters({
  onChange,
  idPrefix = '',
}: {
  onChange: (newFilters: RecipeFilterData) => void
  idPrefix?: string
}) {
  const [appliances, setAppliances] = useState<ApplianceSummary[]>([])
  const [dietaryPreferences, setDietaryPreferences] = useState<DietaryPreferenceSummary[]>([])
  const [cuisine, setCuisine] = useState<(typeof cuisines)[number] | null>(null)
  const [subCuisines, setSubCuisines] = useState<(typeof subcuisines)[number][]>([])
  const [mealType, setMealType] = useState<(typeof mealTypes)[number] | null>(null)
  const [dishType, setDishType] = useState<(typeof dishTypes)[number] | null>(null)
  const [applianceId, setApplianceId] = useState<string | null>(null)
  const [dietaryPreferenceId, setDietaryPreferenceId] = useState<string | null>(null)
  const [totalTime, setTotalTime] = useState<number | null>(null)
  const [activeTime, setActiveTime] = useState<number | null>(null)
  const [showHidden, setShowHidden] = useState<boolean>(false)

  const fetchAppliances = async () => {
    const results = await AppliancesAPI.index()
    setAppliances(results.data)
  }

  const fetchDietaryPreferences = async () => {
    const results = await DietaryPreferencesAPI.index()
    setDietaryPreferences(results.data)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchAppliances()
    // eslint-disable-next-line
    fetchDietaryPreferences()
  }, [])

  useUpdateEffect(() => {
    onChange({
      applianceId,
      mealType,
      cuisine,
      dietaryPreferenceId,
      dishType,
      totalTime,
      activeTime,
      subCuisines,
      showHidden,
    })
  }, [
    applianceId,
    dietaryPreferenceId,
    cuisine,
    mealType,
    dishType,
    totalTime,
    activeTime,
    subCuisines,
    showHidden,
  ])

  const times = [10, 20, 40, 60, 80, 120]

  return (
    <div style={{ marginTop: 20, marginBottom: 60 }}>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-cuisine`}
          label="cuisine"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setCuisine(null)
            else setCuisine(e.target.value as any)
          }}
        >
          {['none', ...cuisines].map(cuisine =>
            cuisine === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={cuisine as any} value={cuisine}>
                {cuisine}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-subcuisine`}
          label="subcuisine"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setSubCuisines([])
            else setSubCuisines([e.target.value as any])
          }}
        >
          {['none', ...subcuisines].map(subcuisine =>
            subcuisine === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={subcuisine as any} value={subcuisine}>
                {subcuisine}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          fullWidth={false}
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-appliance`}
          label="appliance"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setApplianceId(null)
            else setApplianceId(e.target.value)
          }}
          value={applianceId || 'none'}
        >
          {['none', ...appliances].map(appliance =>
            appliance === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={(appliance as ApplianceSummary).id} value={(appliance as ApplianceSummary).id}>
                {(appliance as ApplianceSummary).name}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-dietary-preference`}
          label="dietary preference"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setDietaryPreferenceId(null)
            else setDietaryPreferenceId(e.target.value)
          }}
          value={dietaryPreferenceId || 'none'}
        >
          {['none', ...dietaryPreferences].map(dietaryPreference =>
            dietaryPreference === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem
                key={(dietaryPreference as DietaryPreferenceSummary).id}
                value={(dietaryPreference as DietaryPreferenceSummary).id}
              >
                {(dietaryPreference as DietaryPreferenceSummary).name}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-food-grouping`}
          label="food grouping"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setDishType(null)
            else setDishType(e.target.value as any)
          }}
          value={dishType || 'none'}
        >
          {['none', ...dishTypes].map(dishType =>
            dishType === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={dishType} value={dishType}>
                {dishType}
              </SelectItem>
            )
          )}
        </Select>
      </div>

      <div style={{ marginBottom: 60 }}>
        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-meal-type`}
          label="mealType"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setMealType(null)
            else setMealType(e.target.value as any)
          }}
          value={mealType || 'none'}
        >
          {['none', ...mealTypes].map(mealType =>
            mealType === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mealType} value={mealType}>
                {mealType}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-total-time`}
          label="total time"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setTotalTime(null)
            else setTotalTime(e.target.value as any)
          }}
          value={totalTime ? totalTime : 'none'}
        >
          {['none', ...times].map(mins =>
            mins === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mins} value={mins} textValue={`less than ${mins} minutes`}>
                less than {mins} minutes
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-filter-select-active-time`}
          label="active time"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setActiveTime(null)
            else setActiveTime(e.target.value as any)
          }}
          value={activeTime || 'none'}
        >
          {['none', ...times].map(mins =>
            mins === 'none' ? (
              <SelectItem key="none" textValue="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mins} value={mins} textValue={`less than ${mins} minutes`}>
                less than {mins} minutes
              </SelectItem>
            )
          )}
        </Select>

        <Switch id="recipe-filter-show-hidden-recipes" isSelected={showHidden} onValueChange={setShowHidden}>
          Show Hidden Recipes
        </Switch>
      </div>
    </div>
  )
}
