import { useCallback, useEffect, useState } from 'react'
import IngredientsAPI from '../../api/ingredients'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem, Input } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import IngredientForm from '../../components/resources/ingredient/form'
import { AdminIngredientDetail } from '../../api/schema'
import useMountEffect from '../../hooks/useMountEffect'
import Loader from '../../components/loader'

export default function IngredientEditPage() {
  const navigate = useNavigate()
  const [ingredient, setIngredient] = useState<AdminIngredientDetail | null>(null)
  const { id } = useParams()

  const fetchIngredient = useCallback(async () => {
    const results = await IngredientsAPI.show(id!)
    setIngredient(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchIngredient()
  }, [id])

  if (!ingredient) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.index}>Ingredients</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.show(id!)}>{ingredient.name}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.edit(id!)}>edit</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <IngredientForm
        ingredient={ingredient}
        onSave={async () => {
          await fetchIngredient()
          navigate(routes.app.ingredients.show(id!))
        }}
      />
    </div>
  )
}
