import { Input, Pagination } from '@nextui-org/react'
import { CiSearch } from 'react-icons/ci'
import IngredientsAPI, { IngredientSummary, IngredientsIndexScope } from '../../../api/ingredients'
import { ReactNode, useCallback, useState } from 'react'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import useMountEffect from '../../../hooks/useMountEffect'

export default function IngredientSearch({
  onChange,
  children,
  scope = null,
}: {
  onChange: (ingredients: IngredientSummary[]) => void
  children: ReactNode
  scope?: IngredientsIndexScope | null
}) {
  const [ingredients, setIngredients] = useState<IngredientSummary[]>([])
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(1)
  const [searchText, setSearchText] = useState<string>('')

  const fetchIngredients = useCallback(async () => {
    const results = await IngredientsAPI.index({ search: searchText, page, scope })
    const ingredients = results.data.ingredients
    setIngredients(ingredients)
    setNumPages(results.data.numPages)
    onChange(ingredients)
  }, [searchText, page])

  useUpdateEffect(() => {
    // eslint-disable-next-line
    fetchIngredients()
  }, [page, searchText])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchIngredients()
  }, [])

  return (
    <div>
      <Input
        type="text"
        label="Search"
        name="search"
        id="search-ingredients-input"
        labelPlacement="outside"
        startContent={<CiSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />}
        onChange={async e => {
          setSearchText(e.target.value)
        }}
      />

      {children}

      <Pagination
        total={numPages}
        initialPage={1}
        onChange={async page => {
          setPage(page)
        }}
        size="lg"
        boundaries={Math.floor(window.innerWidth / 80 / 2)}
      />
    </div>
  )
}
