import { useState } from 'react'
import RecipesAPI from '../../api/recipes'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem, Button } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import notify from '../../helpers/notify'
import { AxiosError } from 'axios'

export default function RecipeBulkUploadPage() {
  const navigate = useNavigate()
  const [file, setFile] = useState<File | null>(null)
  const [errors, setErrors] = useState<string[]>([])
  const [submittingFile, setSubmittingFile] = useState(false)

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.bulkUpload}>Bulk upload recipes</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <h1>Bulk upload recipes</h1>

      <input
        id="bulk-upload-recipes-file-input"
        type="file"
        accept=".csv"
        onChange={e => {
          setFile(e.target.files?.[0] || null)
        }}
      />

      <Button
        onPress={async () => {
          setSubmittingFile(true)
          setErrors([])

          try {
            await RecipesAPI.bulkUpload(file!)
            notify('Your csv was successfully processed')
            navigate(routes.app.recipes.index)
          } catch (err) {
            const errors = ((err as AxiosError).response!.data as any).errors as string[]
            setErrors(errors)
            console.error(errors)

            notify(
              <div>
                Your csv failed to process. The errors found are:
                <br />
                {errors.map((err, i) => (
                  <div style={{ color: 'red' }} key={i}>
                    {i + 1}: {err}
                  </div>
                ))}
              </div>,
              'error'
            )
          }
        }}
        disabled={!file || submittingFile}
      >
        Upload
      </Button>

      {errors.length ? (
        <div>
          <h2 style={{ color: 'red' }}>Upload errors:</h2>
          {errors.map((error, index) => (
            <div style={{ color: 'red' }} key={index}>
              {index + 1}: {error}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
