import { AnyExtension, EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { CSSProperties, ReactNode, useEffect } from 'react'

const Tiptap = ({
  id,
  content,
  onUpdate = () => {},
  readonly = false,
  controlled = false,
  slotBefore = null,
  label = null,
}: {
  id: string
  content: string
  onUpdate?: (newHTML: string) => void
  readonly?: boolean
  controlled?: boolean
  slotBefore?: ReactNode
  label?: string | null
}) => {
  return (
    <div id={id} className="tiptap-wrapper" style={{ position: 'relative', zIndex: 2, marginBottom: 20 }}>
      {label && <div className="form-label">{label}</div>}
      <EditorProvider
        slotBefore={
          <>
            {slotBefore}
            <TiptapUpdateHandler controlled={controlled} content={content} onUpdate={onUpdate} />
          </>
        }
        editable={!readonly}
        extensions={tiptapExtensions as AnyExtension[]}
        content={content}
        onUpdate={({ editor }) => {
          const html = editor.getHTML()
          onUpdate(html)
        }}
      >
        {null}
      </EditorProvider>
    </div>
  )
}

function TiptapUpdateHandler({
  content,
  controlled,
  onUpdate,
}: {
  content: string
  controlled: boolean
  onUpdate: (newHtml: string) => void
}) {
  const { editor } = useCurrentEditor()

  useEffect(() => {
    if (controlled && editor) {
      editor.commands.setContent(content)
    }
  }, [controlled, content])

  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    return (
      <input
        className="tiptap-test-input"
        value={content}
        onChange={e => {
          editor!.commands.setContent(e.target.value)
          onUpdate(e.target.value)
        }}
      />
    )
  }

  return null
}

const tiptapExtensions = [StarterKit]

export default Tiptap
