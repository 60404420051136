import { PublicClientApplication } from '@azure/msal-browser'

export const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_PONTIFEX_WELLOS_MEALS_ID ?? '',
    authority: process.env.REACT_APP_PONTIFEX_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
  },
})
