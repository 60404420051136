export default function ImageDisplay({
  id,
  src,
  size = 'md',
}: {
  id: string
  src: string | null
  size?: ImageSize
}) {
  const { width, height } = imageDimensions(size)

  if (src)
    return (
      <div
        id={id}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          width,
          height,
          borderRadius: 15,
        }}
      ></div>
    )

  return (
    <div
      style={{
        padding: 150,
        paddingTop: 250,
        width,
        height,
        textAlign: 'center',
        verticalAlign: 'center',
        background: 'whitesmoke',
        borderRadius: 15,
      }}
    >
      no image provided
    </div>
  )
}

function imageDimensions(size: ImageSize) {
  switch (size) {
    case 'xsm':
      return { width: 75, height: 75 }

    case 'sm':
      return { width: 100, height: 100 }

    case 'md':
      return { width: 300, height: 300 }

    case 'lg':
      return { width: 500, height: 500 }
  }
}

export type ImageSize = 'xsm' | 'sm' | 'md' | 'lg'
