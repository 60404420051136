import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem, Input } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import IngredientCreateForm from '../../components/resources/ingredient/create-form'

export default function IngredientsNewPage() {
  const navigate = useNavigate()

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.index}>Ingredients</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.new}>new</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <IngredientCreateForm />
    </div>
  )
}
