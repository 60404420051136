import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import IngredientsAPI from '../../api/ingredients'
import { useParams, NavLink } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import routes from '../../config/routes'
import { IoPencil } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { AdminIngredientDetail } from '../../api/schema'
import Loader from '../../components/loader'

export default function IngredientsShowPage() {
  const [ingredient, setIngredient] = useState<AdminIngredientDetail | null>(null)
  const { id } = useParams()

  const fetchIngredient = useCallback(async () => {
    const results = await IngredientsAPI.show(id!)
    setIngredient(results.data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchIngredient()
  }, [id])

  if (!ingredient) return <Loader />

  const basicInfo = {
    'Is placeholder': ingredient.isPlaceholder ? 'true' : 'false',
    'Protein type': ingredient.proteinType || 'N/A',
    'Secondary Protein type': ingredient.secondaryProteinType || 'N/A',
    Dairy: ingredient.dairy ? 'true' : 'false',
    'Common Pantry Item': ingredient.commonPantryItem ? 'true' : 'false',
    'Omit from grocery list': ingredient.omitFromGroceryList ? 'true' : 'false',
  }

  const nutritionInfo = {
    'Added sugar': ingredient.addedSugar || 'N/A',
    Calcium: ingredient.calcium || 'N/A',
    Calories: ingredient.calories || 'N/A',
    Carbs: ingredient.carbs || 'N/A',
    Fat: ingredient.fat || 'N/A',
    Fiber: ingredient.fiber || 'N/A',
    Iron: ingredient.iron || 'N/A',
    Potassium: ingredient.potassium || 'N/A',
    Protein: ingredient.protein || 'N/A',
    'Saturated Fat': ingredient.saturatedFat || 'N/A',
    Sodium: ingredient.sodium || 'N/A',
    Sugar: ingredient.sugar || 'N/A',
    'Vitamin D': ingredient.vitaminD || 'N/A',
  }

  const seasonalityInfo = {
    Seasonality: ingredient.seasonality || 'N/A',
    'Northern hemisphere season start day': ingredient.northernHemisphereSeasonStartDay || 'N/A',
    'Northern hemisphere season end day': ingredient.northernHemisphereSeasonEndDay || 'N/A',
    'Southern hemisphere season start day': ingredient.southernHemisphereSeasonStartDay || 'N/A',
    'Southern hemisphere season end day': ingredient.southernHemisphereSeasonEndDay || 'N/A',
  }

  const metricsInfo = {
    'Integer units': ingredient.integerUnits || 'N/A',
    'Exclude from half size rounding constraint': ingredient.excludeFromHalfSizeRoundingConstraint || 'N/A',
    'Imperial unit': ingredient.imperialUnit || 'N/A',
    'Imperial grocery list unit': ingredient.imperialGroceryListUnit || 'N/A',
    'Grams per imperial unit': ingredient.gramsPerImperialUnit || 'N/A',
    'Imperial grocery list unit per imperial unit':
      ingredient.imperialGroceryListUnitPerImperialUnit || 'N/A',
    'Metric unit': ingredient.metricUnit || 'N/A',
    'Metric grocery list unit': ingredient.metricGroceryListUnit || 'N/A',
    'Grams per metric unit': ingredient.gramsPerMetricUnit || 'N/A',
    'Metric grocery list unit per metric unit': ingredient.metricGroceryListUnitPerMetricUnit || 'N/A',
  }

  const substitutionInfo = {
    'Substitute for dietary preference': ingredient.substituteForDietaryPreference || 'N/A',
    'Default substitute for dietary preferences': ingredient.defaultSubstituteForDietaryPreferences || 'N/A',
    'Directly includable substitute': ingredient.directlyIncludableSubstitute || 'N/A',
    'Is placeholder': ingredient.isPlaceholder || 'N/A',
    'Placeholder type': ingredient.placeholderType || 'N/A',
    'Substitute bread type': ingredient.substituteBreadType || 'N/A',
    'Substitute milk type': ingredient.substituteMilkType || 'N/A',
    'Substitute pasta type': ingredient.substitutePastaType || 'N/A',
    'Substitute pre-chopped vegetable type': ingredient.substitutePreChoppedVegetableType || 'N/A',
    'Substitute pre-cooked protein type': ingredient.substitutePreCookedProteinType || 'N/A',
    'Substitute rice type': ingredient.substituteRiceType || 'N/A',
    'Substitution notes': ingredient.substitutionNotes || 'N/A',
  }

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.index}>Ingredients</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.show(id!)}>{ingredient.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>{ingredient.name}</h1>
        <Button
          as={Link}
          to={routes.app.ingredients.edit(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoPencil size={20} />
          Edit
        </Button>
      </div>

      <h2>Basic info</h2>

      <Table
        aria-label="Basic info"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {Object.keys(basicInfo).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key}</TableCell>
              <TableCell>{(basicInfo as any)[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Nutrition info</h2>

      <Table
        aria-label="Nutrition info"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {Object.keys(nutritionInfo).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key}</TableCell>
              <TableCell>{(nutritionInfo as any)[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Seasonality</h2>

      <Table
        aria-label="Seasonality"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {Object.keys(seasonalityInfo).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key}</TableCell>
              <TableCell>{(seasonalityInfo as any)[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Metrics</h2>

      <Table
        aria-label="Metrics"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {Object.keys(metricsInfo).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key}</TableCell>
              <TableCell>{(metricsInfo as any)[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Substitutions</h2>

      <Table
        aria-label="Substitutions"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {Object.keys(substitutionInfo).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{key}</TableCell>
              <TableCell>{(substitutionInfo as any)[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
