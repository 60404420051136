import { Button, Input } from '@nextui-org/react'
import { useState } from 'react'
import FormGroup from '../../forms/form-group'
import { AdminRecipeVariantCookingTipSummary } from '../../../api/schema'
import RecipeVariantStepCookingTipsAPI from '../../../api/recipeVariantStepCookingTips'

export default function RecipeVariantStepCookingTipForm({
  recipeVariantStepCookingTip,
  onSave,
}: {
  recipeVariantStepCookingTip: AdminRecipeVariantCookingTipSummary
  onSave: () => void
}) {
  const [name, setName] = useState(recipeVariantStepCookingTip.name)
  const [body, setBody] = useState(recipeVariantStepCookingTip.body)

  return (
    <div style={{ marginTop: 100 }}>
      <FormGroup>
        <Input
          type="text"
          label="Name"
          name="name"
          autoComplete="off"
          id="edit-recipe-variant-step-cooking-tip-name-input"
          labelPlacement="outside"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="text"
          label="Body"
          name="body"
          autoComplete="off"
          id="edit-recipe-variant-step-cooking-tip-body-input"
          labelPlacement="outside"
          value={body}
          onChange={async e => {
            setBody(e.target.value)
          }}
        />
      </FormGroup>

      <Button
        onPress={async () => {
          await RecipeVariantStepCookingTipsAPI.update(recipeVariantStepCookingTip.id, {
            name,
            body,
          })
          onSave()
        }}
      >
        Save
      </Button>
    </div>
  )
}
