import routes from '../config/routes'
import { api } from './common'
import { CuisinesEnum, DishTypesEnum, MealTypesEnum } from './schema'

export default class RecipeBrowseCategoriesAPI {
  static index() {
    return api.get(routes.api.recipeBrowseCategories.index)
  }

  static show(id: string) {
    return api.get(routes.api.recipeBrowseCategories.show(id))
  }

  static update(id: string, attrs: RecipeBrowseCategoryParams) {
    return api.patch(routes.api.recipeBrowseCategories.edit(id), attrs)
  }
}

export interface RecipeBrowseCategoryParams {
  name?: string
  filters?: RecipeVariantFilterData
  recipeVariantId?: string
}

export interface RecipeVariantFilterData {
  query?: string
  ingredientQuery?: string
  filters?: RecipeVariantSubfilterData
}

export interface RecipeVariantSubfilterData {
  activeTime?: number
  applianceId?: string
  conformToUserPreferences?: boolean
  cuisines?: CuisinesEnum[]
  dietaryPreferenceId?: string
  dishTypes?: DishTypesEnum[]
  mealTypes?: MealTypesEnum[]
  minRating?: MinRatingValue
  totalTime?: number
}

export type MinRatingValue = 1 | 2 | 3 | 4 | 5 | null
