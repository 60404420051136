import routes from '../config/routes'
import { api } from './common'

export default class RecipeVariantCookingTipsAPI {
  static index() {
    return api.get(routes.api.recipeVariantCookingTips.index)
  }

  static create(attrs: Partial<RecipeVariantCookingTipParams>) {
    return api.post(routes.api.recipeVariantCookingTips.create, attrs)
  }

  static show(id: string) {
    return api.get(routes.api.recipeVariantCookingTips.show(id))
  }

  static update(id: string, attrs: Partial<RecipeVariantCookingTipParams>) {
    return api.patch(routes.api.recipeVariantCookingTips.update(id), attrs)
  }

  static destroy(id: string) {
    return api.delete(routes.api.recipeVariantCookingTips.destroy(id))
  }
}

export interface RecipeVariantCookingTipParams {
  name: string
  body: string
}
