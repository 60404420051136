import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import IngredientExclusionCategoryForm from '../../components/resources/ingredient-exclusion-category/form'

export default function IngredientExclusionCategoriesNewPage() {
  const navigate = useNavigate()

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.index}>IngredientExclusionCategories</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.new}>new</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <IngredientExclusionCategoryForm
        onSave={async id => {
          navigate(routes.app.ingredientExclusionCategories.show(id!))
        }}
      />
    </div>
  )
}
