import { BreadcrumbItem, Breadcrumbs, Button, Divider, Listbox, ListboxItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import SystemApi from '../../api/system'

export default function SystemIndexPage() {
  return (
    <div>
      <div className="flex gap-4 items-center">
        <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
          <BreadcrumbItem>
            <NavLink to={routes.app.home}>Home</NavLink>
          </BreadcrumbItem>
        </Breadcrumbs>
      </div>

      <Listbox aria-label="Actions" className="t-1">
        <ListboxItem key="recipes">
          <Button
            color="success"
            style={{ color: 'white', marginLeft: 'auto' }}
            elementType={NavLink}
            onPress={() => {
              SystemApi.signIntoCentralBullboard()
                .then(url => (window.location.href = url))
                .catch(() => null)
            }}
          >
            Bullboard
          </Button>
        </ListboxItem>
      </Listbox>
    </div>
  )
}
