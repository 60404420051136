import { useCallback, useState } from 'react'
import {
  BreadcrumbItem,
  Breadcrumbs,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import { Link, NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import { AdminIngredientExclusionCategorySummary } from '../../api/schema'
import IngredientExclusionCategoriesAPI from '../../api/ingredientExclusionCategories'
import useMountEffect from '../../hooks/useMountEffect'
import { IoAddSharp } from 'react-icons/io5'

export default function IngredientExclusionCategoriesIndexPage() {
  const [ingredientExclusionCategories, setIngredientExclusionCategories] = useState<
    AdminIngredientExclusionCategorySummary[]
  >([])

  const fetchIngredientExclusionCategories = async () => {
    const results = await IngredientExclusionCategoriesAPI.index()
    setIngredientExclusionCategories(results.data)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchIngredientExclusionCategories()
  }, [])

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.index}>
            Ingredient Exclusion Categories
          </NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h2>Recipe Variants</h2>
        <Button
          as={Link}
          to={routes.app.ingredientExclusionCategories.new}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoAddSharp size={20} />
          New ingredient exclusion category
        </Button>
      </div>

      <div style={{ marginBottom: 20, marginTop: 20 }}>
        <Table
          aria-label="Recipes"
          classNames={{
            base: 'table-base search',
          }}
          shadow="none"
        >
          <TableHeader>
            <TableColumn>ID</TableColumn>
            <TableColumn>NAME</TableColumn>
          </TableHeader>

          <TableBody
            style={{
              maxHeight: 'calc(100vh - 400px)',
            }}
          >
            {ingredientExclusionCategories.map((ingredientExclusionCategory, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: 40 }}>
                  <NavLink to={routes.app.ingredientExclusionCategories.show(ingredientExclusionCategory.id)}>
                    {ingredientExclusionCategory.id}
                  </NavLink>
                </TableCell>
                <TableCell style={{ verticalAlign: 'center' }}>
                  <NavLink to={routes.app.ingredientExclusionCategories.show(ingredientExclusionCategory.id)}>
                    {ingredientExclusionCategory.name}
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
