export type AisleNamesEnum = 'baby_care' | 'bakery' | 'beverages' | 'breakfast_cereal' | 'bulk' | 'canned_goods' | 'condiments_spices_baking' | 'dairy' | 'deli' | 'flowers' | 'frozen' | 'grains_pasta' | 'international' | 'meat_seafood' | 'other' | 'paper_cleaning_home' | 'personal_care_health' | 'pet_care' | 'produce' | 'snacks_cookies_crackers' | 'wine_beer_spirits'
export const AisleNamesEnumValues = [
  'baby_care',
  'bakery',
  'beverages',
  'breakfast_cereal',
  'bulk',
  'canned_goods',
  'condiments_spices_baking',
  'dairy',
  'deli',
  'flowers',
  'frozen',
  'grains_pasta',
  'international',
  'meat_seafood',
  'other',
  'paper_cleaning_home',
  'personal_care_health',
  'pet_care',
  'produce',
  'snacks_cookies_crackers',
  'wine_beer_spirits'
]

export type ApplianceNamesEnum = 'blender' | 'food_processor' | 'instant_pot' | 'microwave' | 'oven' | 'slow_cooker' | 'stovetop'
export const ApplianceNamesEnumValues = [
  'blender',
  'food_processor',
  'instant_pot',
  'microwave',
  'oven',
  'slow_cooker',
  'stovetop'
]

export type CuisinesEnum = 'african' | 'asian' | 'caribbean' | 'eastern_european' | 'indian' | 'latin_american' | 'mediterranean' | 'middle_eastern' | 'northern_european' | 'southern_us' | 'traditional_american'
export const CuisinesEnumValues = [
  'african',
  'asian',
  'caribbean',
  'eastern_european',
  'indian',
  'latin_american',
  'mediterranean',
  'middle_eastern',
  'northern_european',
  'southern_us',
  'traditional_american'
]

export type CuratorRolesEnum = 'coach' | 'influencer'
export const CuratorRolesEnumValues = [
  'coach',
  'influencer'
]

export type DefaultMenuViewsEnum = 'all' | 'daily'
export const DefaultMenuViewsEnumValues = [
  'all',
  'daily'
]

export type DeliveryOptionsEnum = 'amazon_fresh' | 'instacart' | 'kroger' | 'shipt' | 'target' | 'walmart'
export const DeliveryOptionsEnumValues = [
  'amazon_fresh',
  'instacart',
  'kroger',
  'shipt',
  'target',
  'walmart'
]

export type DietaryPreferenceClassificationsEnum = 'dietary_pattern' | 'other' | 'protein'
export const DietaryPreferenceClassificationsEnumValues = [
  'dietary_pattern',
  'other',
  'protein'
]

export type DietaryPreferenceNamesEnum = '30_day_cleanse' | 'dairy_free' | 'diabetic_prediabetic' | 'flexitarian' | 'gluten_free' | 'ketogenic' | 'kosher' | 'low_carb' | 'low_fat' | 'low_sodium' | 'mediterranean' | 'no_dietary_pattern' | 'no_protein_restriction' | 'no_red_meat' | 'paleo' | 'pescatarian' | 'platejoy_path' | 'pregnant_or_nursing' | 'vegan' | 'vegetarian' | 'whole_food_diet'
export const DietaryPreferenceNamesEnumValues = [
  '30_day_cleanse',
  'dairy_free',
  'diabetic_prediabetic',
  'flexitarian',
  'gluten_free',
  'ketogenic',
  'kosher',
  'low_carb',
  'low_fat',
  'low_sodium',
  'mediterranean',
  'no_dietary_pattern',
  'no_protein_restriction',
  'no_red_meat',
  'paleo',
  'pescatarian',
  'platejoy_path',
  'pregnant_or_nursing',
  'vegan',
  'vegetarian',
  'whole_food_diet'
]

export type DishTypesEnum = 'batch' | 'bento' | 'bowl' | 'casserole' | 'curry' | 'eggs' | 'hashes' | 'oats' | 'other' | 'pasta' | 'pizza' | 'salad' | 'sandwich' | 'skillet' | 'smoothie' | 'soup' | 'toast'
export const DishTypesEnumValues = [
  'batch',
  'bento',
  'bowl',
  'casserole',
  'curry',
  'eggs',
  'hashes',
  'oats',
  'other',
  'pasta',
  'pizza',
  'salad',
  'sandwich',
  'skillet',
  'smoothie',
  'soup',
  'toast'
]

export type DoublingPreferencesEnum = 'always' | 'never' | 'sometimes'
export const DoublingPreferencesEnumValues = [
  'always',
  'never',
  'sometimes'
]

export type HemispheresEnum = 'northern' | 'southern'
export const HemispheresEnumValues = [
  'northern',
  'southern'
]

export type ImperialGroceryListUnitsEnum = 'count' | 'dozen' | 'fluid_ounce' | 'gallon' | 'milliliter' | 'ounce' | 'package' | 'pint' | 'pound' | 'quart' | 'slice' | 'stick'
export const ImperialGroceryListUnitsEnumValues = [
  'count',
  'dozen',
  'fluid_ounce',
  'gallon',
  'milliliter',
  'ounce',
  'package',
  'pint',
  'pound',
  'quart',
  'slice',
  'stick'
]

export type ImperialUnitsEnum = 'count' | 'cup' | 'dozen' | 'drop' | 'fluid_ounce' | 'gallon' | 'ounce' | 'package' | 'pound' | 'quart' | 'slice' | 'spray' | 'stick' | 'tablespoon' | 'teaspoon'
export const ImperialUnitsEnumValues = [
  'count',
  'cup',
  'dozen',
  'drop',
  'fluid_ounce',
  'gallon',
  'ounce',
  'package',
  'pound',
  'quart',
  'slice',
  'spray',
  'stick',
  'tablespoon',
  'teaspoon'
]

export type IngredientTagsEnum = 'egg' | 'stevia'
export const IngredientTagsEnumValues = [
  'egg',
  'stevia'
]

export type IngredientTypesEnum = 'CustomIngredient' | 'Ingredient'
export const IngredientTypesEnumValues = [
  'CustomIngredient',
  'Ingredient'
]

export type LocalesEnum = 'de-DE' | 'en-AU' | 'en-BZ' | 'en-CA' | 'en-cb' | 'en-GB' | 'en-IE' | 'en-IN' | 'en-JM' | 'en-MT' | 'en-MY' | 'en-NZ' | 'en-PH' | 'en-SG' | 'en-TT' | 'en-US' | 'en-ZA' | 'en-ZW' | 'es-ES' | 'fr-FR' | 'it-IT' | 'ja-JP' | 'ko-KR' | 'pt-BR' | 'zh-CN' | 'zh-TW'
export const LocalesEnumValues = [
  'de-DE',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-cb',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MT',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-ES',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'pt-BR',
  'zh-CN',
  'zh-TW'
]

export type LocalizableTypesEnum = 'Image' | 'RecipeBrowseCategory' | 'RecipeCollection'
export const LocalizableTypesEnumValues = [
  'Image',
  'RecipeBrowseCategory',
  'RecipeCollection'
]

export type LocalizedTextTypesEnum = 'ImageLocalizedText' | 'StandardLocalizedText'
export const LocalizedTextTypesEnumValues = [
  'ImageLocalizedText',
  'StandardLocalizedText'
]

export type MealTypesEnum = 'batch' | 'breakfast' | 'dinner' | 'lunch' | 'snack_dessert'
export const MealTypesEnumValues = [
  'batch',
  'breakfast',
  'dinner',
  'lunch',
  'snack_dessert'
]

export type MenuConfigOverridesEnum = 'high_protein' | 'low_added_sugar'
export const MenuConfigOverridesEnumValues = [
  'high_protein',
  'low_added_sugar'
]

export type MetricGroceryListUnitsEnum = 'count' | 'dozen' | 'gram' | 'kilogram' | 'liter' | 'milliliter' | 'package' | 'slice' | 'stick'
export const MetricGroceryListUnitsEnumValues = [
  'count',
  'dozen',
  'gram',
  'kilogram',
  'liter',
  'milliliter',
  'package',
  'slice',
  'stick'
]

export type MetricUnitsEnum = 'count' | 'cubic_centimeter' | 'dozen' | 'drop' | 'gram' | 'kilogram' | 'liter' | 'milliliter' | 'package' | 'slice' | 'spray' | 'stick' | 'tablespoon' | 'teaspoon'
export const MetricUnitsEnumValues = [
  'count',
  'cubic_centimeter',
  'dozen',
  'drop',
  'gram',
  'kilogram',
  'liter',
  'milliliter',
  'package',
  'slice',
  'spray',
  'stick',
  'tablespoon',
  'teaspoon'
]

export type PlaceholderTypesEnum = 'bread' | 'dietary_preference' | 'milk' | 'pasta' | 'pre_chopped' | 'pre_cooked' | 'rice'
export const PlaceholderTypesEnumValues = [
  'bread',
  'dietary_preference',
  'milk',
  'pasta',
  'pre_chopped',
  'pre_cooked',
  'rice'
]

export type ProteinTypesEnum = 'dairy' | 'egg' | 'meatballs' | 'poultry' | 'red_meat' | 'sausage' | 'seafood' | 'vegetable_protein'
export const ProteinTypesEnumValues = [
  'dairy',
  'egg',
  'meatballs',
  'poultry',
  'red_meat',
  'sausage',
  'seafood',
  'vegetable_protein'
]

export type SeasonalitiesEnum = 'hard' | 'na' | 'soft'
export const SeasonalitiesEnumValues = [
  'hard',
  'na',
  'soft'
]

export type SecondaryProteinTypesEnum = 'bacon' | 'beef' | 'beyond_beef' | 'chicken' | 'dairy' | 'duck' | 'egg' | 'lamb' | 'legume' | 'meatballs' | 'non_salmon_fish' | 'pork' | 'salmon' | 'sausage' | 'seitan' | 'shellfish' | 'tempeh' | 'tofu' | 'turkey' | 'veggie_burger'
export const SecondaryProteinTypesEnumValues = [
  'bacon',
  'beef',
  'beyond_beef',
  'chicken',
  'dairy',
  'duck',
  'egg',
  'lamb',
  'legume',
  'meatballs',
  'non_salmon_fish',
  'pork',
  'salmon',
  'sausage',
  'seitan',
  'shellfish',
  'tempeh',
  'tofu',
  'turkey',
  'veggie_burger'
]

export type SubCuisinesEnum = 'argentinian' | 'austrian' | 'brazilian' | 'british' | 'burmese' | 'cajun' | 'californian' | 'cambodian' | 'canadian' | 'central_american' | 'chilean' | 'chinese' | 'creole' | 'cuban' | 'east_african' | 'egyptian' | 'english' | 'ethiopian' | 'filipino' | 'french' | 'german' | 'greek' | 'hawaiian' | 'hungarian' | 'irish' | 'italian' | 'jamaican' | 'japanese' | 'jewish' | 'korean' | 'lebanese' | 'mexican' | 'mongolian' | 'moroccan' | 'north_african' | 'north_indian' | 'persian' | 'peruvian' | 'polish' | 'portuguese' | 'russian' | 'scottish' | 'soul_food' | 'south_african' | 'south_indian' | 'southeast_asian' | 'southern' | 'southwest' | 'spanish' | 'swiss' | 'tex_mex' | 'thai' | 'turkish' | 'vietnamese' | 'west_african'
export const SubCuisinesEnumValues = [
  'argentinian',
  'austrian',
  'brazilian',
  'british',
  'burmese',
  'cajun',
  'californian',
  'cambodian',
  'canadian',
  'central_american',
  'chilean',
  'chinese',
  'creole',
  'cuban',
  'east_african',
  'egyptian',
  'english',
  'ethiopian',
  'filipino',
  'french',
  'german',
  'greek',
  'hawaiian',
  'hungarian',
  'irish',
  'italian',
  'jamaican',
  'japanese',
  'jewish',
  'korean',
  'lebanese',
  'mexican',
  'mongolian',
  'moroccan',
  'north_african',
  'north_indian',
  'persian',
  'peruvian',
  'polish',
  'portuguese',
  'russian',
  'scottish',
  'soul_food',
  'south_african',
  'south_indian',
  'southeast_asian',
  'southern',
  'southwest',
  'spanish',
  'swiss',
  'tex_mex',
  'thai',
  'turkish',
  'vietnamese',
  'west_african'
]

export type SubstituteBreadTypesEnum = 'white' | 'whole_grain'
export const SubstituteBreadTypesEnumValues = [
  'white',
  'whole_grain'
]

export type SubstituteMilkTypesEnum = 'almond' | 'coconut' | 'cow_1_percent' | 'cow_2_percent' | 'cow_skim' | 'cow_whole' | 'goat' | 'hemp' | 'lactose_free' | 'oat' | 'rice' | 'soy'
export const SubstituteMilkTypesEnumValues = [
  'almond',
  'coconut',
  'cow_1_percent',
  'cow_2_percent',
  'cow_skim',
  'cow_whole',
  'goat',
  'hemp',
  'lactose_free',
  'oat',
  'rice',
  'soy'
]

export type SubstitutePastaTypesEnum = 'regular' | 'whole_wheat'
export const SubstitutePastaTypesEnumValues = [
  'regular',
  'whole_wheat'
]

export type SubstitutePreChoppedVegetableTypesEnum = 'pre_chopped' | 'whole'
export const SubstitutePreChoppedVegetableTypesEnumValues = [
  'pre_chopped',
  'whole'
]

export type SubstitutePreCookedProteinTypesEnum = 'pre_cooked' | 'raw'
export const SubstitutePreCookedProteinTypesEnumValues = [
  'pre_cooked',
  'raw'
]

export type SubstituteRiceTypesEnum = 'brown' | 'microwave_brown' | 'microwave_white' | 'white'
export const SubstituteRiceTypesEnumValues = [
  'brown',
  'microwave_brown',
  'microwave_white',
  'white'
]

export type TimesOfDayEnum = 'afternoon' | 'evening' | 'morning' | 'noon'
export const TimesOfDayEnumValues = [
  'afternoon',
  'evening',
  'morning',
  'noon'
]

export type UnitPreferenceEnum = 'imperial' | 'metric'
export const UnitPreferenceEnumValues = [
  'imperial',
  'metric'
]

export interface AdminAppliance {
  id: string
  name: string
}

export interface AdminDietaryPreference {
  id: string
  name: string
}

export interface AdminIngredientDetail {
  id: string
  name: string
  isPlaceholder: boolean
  tag: IngredientTagsEnum
  metricUnit: MetricUnitsEnum
  imperialUnit: ImperialUnitsEnum
  metricGroceryListUnit: MetricGroceryListUnitsEnum
  imperialGroceryListUnit: ImperialGroceryListUnitsEnum
  metricGroceryListUnitPerMetricUnit: number
  imperialGroceryListUnitPerImperialUnit: number
  costPerMetricGroceryListUnit: number
  costPerImperialGroceryListUnit: number
  gramsPerMetricUnit: number
  gramsPerImperialUnit: number
  integerUnits: boolean
  commonPantryItem: boolean
  dairy: boolean
  excludeFromHalfSizeRoundingConstraint: boolean
  omitFromGroceryList: boolean
  userIngredient: boolean
  addedSugar: number
  calcium: number
  calories: number
  carbs: number
  fat: number
  fiber: number
  iron: number
  potassium: number
  protein: number
  saturatedFat: number
  sodium: number
  sugar: number
  vitaminD: number
  seasonality: SeasonalitiesEnum
  northernHemisphereSeasonStartDay: string
  northernHemisphereSeasonEndDay: string
  southernHemisphereSeasonStartDay: string
  southernHemisphereSeasonEndDay: string
  proteinType: ProteinTypesEnum
  secondaryProteinType: SecondaryProteinTypesEnum
  placeholderType: PlaceholderTypesEnum
  substituteBreadType: SubstituteBreadTypesEnum
  substituteMilkType: SubstituteMilkTypesEnum
  substitutePastaType: SubstitutePastaTypesEnum
  substitutePreChoppedVegetableType: SubstitutePreChoppedVegetableTypesEnum
  substitutePreCookedProteinType: SubstitutePreCookedProteinTypesEnum
  substituteRiceType: SubstituteRiceTypesEnum
  substituteForDietaryPreference: boolean
  defaultSubstituteForDietaryPreferences: boolean
  directlyIncludableSubstitute: boolean
  undoEshaCandidateIds: number
  redoEshaCandidateIds: number
  substitutionNotes: string
  platejoyId: number
  tempMetricRecipeIngredientAmountMultiplier: number
}

export interface AdminIngredientExclusionCategoryDetail {
  id: string
  name: string
  ingredientExclusionCategoryIngredients: AdminIngredientExclusionCategoryIngredientSummary[]
}

export interface AdminIngredientExclusionCategoryIngredientSummary {
  id: string
  ingredientId: string
  ingredient: AdminIngredientSummary
}

export interface AdminIngredientExclusionCategorySummary {
  id: string
  name: string
}

export interface AdminIngredientSummary {
  id: string
  name: string
  metricUnit: MetricUnitsEnum
  imperialUnit: ImperialUnitsEnum
  isPlaceholder: boolean
}

export interface AdminIngredientWithSubstitutes {
  id: string
  name: string
  metricUnit: MetricUnitsEnum
  imperialUnit: ImperialUnitsEnum
  isPlaceholder: boolean
  substitutes: AdminIngredientSummary[]
}

export interface AdminLocalizedText {
  id: string
  name: string
  title: string
  type: string
  subtitle: string
  summaryDescription: string
  activeSummaryDescription: string
  description: string
  body: string
  alt: string
  cta: string
  callout: string
  transcription: string
  locale: any
  localizableType: any
  localizableId: string
}

export interface AdminRecipeBrowseCategoryDetail {
  id: string
  name: string
  filters: any
  recipeVariant: AdminRecipeVariantSummary
  recipeVariants: AdminRecipeVariantSummary[]
}

export interface AdminRecipeBrowseCategorySummary {
  id: string
  name: string
}

export interface AdminRecipeCollectionDetail {
  id: number
  name: string
  proteinPreference: AdminDietaryPreference
  dietaryPatternPreference: AdminDietaryPreference
  otherDietaryPreferences: AdminDietaryPreference[]
  recipes: AdminRecipeSummary[]
  localizedTexts: AdminLocalizedText[]
}

export interface AdminRecipeCollection {
  id: string
  name: string
}

export interface AdminRecipeCollectionSeries {
  id: number
  enhancedDisplay: boolean
  focusedRecipeCollectionId: string
  recipeCollections: any
}

export interface AdminRecipe {
  id: string
  name: string
  hidden: boolean
  mealType: MealTypesEnum
  dishType: DishTypesEnum
  cuisine: CuisinesEnum
  subCuisines: SubCuisinesEnum[]
  seasonality: SeasonalitiesEnum
  northernHemisphereSeasonStartDay: string
  northernHemisphereSeasonEndDay: string
  southernHemisphereSeasonStartDay: string
  southernHemisphereSeasonEndDay: string
  servings: number
  fixedPortionSize: boolean
  doubleable: boolean
  prePrepared: boolean
  exotic: boolean
  introduction: string
  description: string
  imageUrl: string
  recipeVariants: AdminRecipeVariant[]
  recipeCollections: AdminRecipeCollection[]
}

export interface AdminRecipeSummary {
  id: string
  name: string
  mealType: MealTypesEnum
  dishType: DishTypesEnum
  cuisine: CuisinesEnum
  subCuisines: SubCuisinesEnum[]
  seasonality: SeasonalitiesEnum
  northernHemisphereSeasonStartDay: string
  northernHemisphereSeasonEndDay: string
  southernHemisphereSeasonStartDay: string
  southernHemisphereSeasonEndDay: string
  servings: number
  fixedPortionSize: boolean
  doubleable: boolean
  prePrepared: boolean
  exotic: boolean
  introduction: string
  description: string
}

export interface AdminRecipeVariantApplianceSummary {
  id: string
  appliance: AdminAppliance
}

export interface AdminRecipeVariantCookingTipSummary {
  id: string
  name: string
  body: string
}

export interface AdminRecipeVariantDetail {
  id: string
  name: string
  imageUrl: string
  imageCreditName: string
  imageCreditUrl: string
  imageCreditDetails: string
  published: boolean
  rating: number
  ratingVotes: number
  approximateActiveMinutes: number
  approximatePassiveMinutes: number
  approximateTotalMinutes: number
  mealType: MealTypesEnum
  proteinType: ProteinTypesEnum
  secondaryProteinType: SecondaryProteinTypesEnum
  recipeName: string
  recipeId: string
  baseVariant: boolean
  nutritionInfo: any
  recipeVariantSteps: AdminRecipeVariantStep[]
  recipe: AdminRecipeSummary
  recipeVariantCookingTip: AdminRecipeVariantCookingTipSummary
  variantDefiningDietaryPreference: AdminDietaryPreference
  recipeVariantDietaryPreferences: AdminRecipeVariantDietaryPreferenceSummary[]
  recipeVariantAppliances: AdminRecipeVariantApplianceSummary[]
  recipeVariantExclusiveToDietaryPreferences: AdminRecipeVariantDietaryPreferenceSummary[]
  recipeVariantIngredients: AdminRecipeVariantIngredient[]
  recipeVariantIngredientOverrideOptions: AdminRecipeVariantIngredientOverrideOptionDetail[]
  recipeVariantsWithConflictingDietaryPreferences: AdminRecipeVariantSummary[]
}

export interface AdminRecipeVariantDietaryPreferenceSummary {
  id: string
  dietaryPreference: AdminDietaryPreference
}

export interface AdminRecipeVariantIngredientOverrideOptionDetail {
  id: string
  metricAmount: number
  imperialAmount: number
  optional: boolean
  recipeVariantId: string
  ingredient: AdminIngredientSummary
  overriddenIngredient: AdminIngredientSummary
}

export interface AdminRecipeVariantIngredient {
  id: string
  metricAmount: number
  imperialAmount: number
  optional: boolean
  recipeVariantId: string
  ingredient: AdminIngredientWithSubstitutes
}

export interface AdminRecipeVariant {
  id: string
  name: string
  imageUrl: string
  baseVariant: boolean
}

export interface AdminRecipeVariantStep {
  id: string
  header: string
  body: string
  position: number
  activeMinutes: number
  passiveMinutes: number
  activeMinutesFactor: number
  passiveMinutesFactor: number
  renderedBody: string
  ingredientIds: string[]
  ingredient: AdminIngredientSummary
  recipeVariantStepCookingTip: AdminRecipeVariantCookingTipSummary
}

export interface AdminRecipeVariantSummary {
  id: string
  name: string
  imageUrl: string
  baseVariant: boolean
}

export interface Aisle {
  id: string
  name: string
  title: string
}

export interface Appliance {
  name: any
}

export interface Curator {
  role: any
  name: any
  image: Image
}

export interface CustomIngredient {
  id: string
  name: string
  aisleId: string
}

export interface GroceryListAisle {
  name: AisleNamesEnum
  title: string
  ingredients: any[]
}

export interface GroceryListCustomIngredient {
  ingredientType: IngredientTypesEnum
  ingredientId: string
  label: string
  checkedOff: boolean
}

export interface GroceryList {
  menuId: string
  menuTitle: string
  recipeVariants: any[]
  aisles: any[]
}

export interface GroceryListIngredient {
  ingredientType: IngredientTypesEnum
  ingredientId: string
  label: string
  checkedOff: boolean
  details: string
  amount: any
  recipeVariantIds: any
}

export interface GroceryListRecipeVariant {
  menuRecipeVariantId: string
  id: string
  name: string
}

export interface GroceryListIngredientStatus {
  menuId: any
  ingredientId: any
  ingredientType: any
  checkedOff: boolean
  deletedAt: any
}

export interface Image {
  id: any
  url: any
  title: any
  alt: any
}

export interface IngredientExclusionCategorySummary {
  id: string
  name: string
}

export interface IngredientExclusionCategory {
  id: string
  name: string
}

export interface IngredientSummary {
  id: string
  name: string
}

export interface Ingredient {
  id: string
  name: string
}

export interface BaseLocalizedText {
}

export interface ImageLocalizedText {
  id: any
  title: any
  alt: any
  locale: any
}

export interface StandardLocalizedText {
  id: any
  title: any
  summaryDescription: any
  description: any
  locale: any
}

export interface SummaryMenu {
  id: string
  title: string
  groceryListCreated: boolean
  recipeCount: number
  recipeVariantIds: string[]
  recipeVariantImages: string[]
}

export interface DetailedMenu {
  id: string
  title: string
  groceryListCreated: boolean
  recipeVariants: any[]
}

export interface OneTimeMenuConfigOverride {
  id: any
  override: any
}

export interface RecipeBrowseCategory {
  label: any
  filters: any
  imageUrl: any
  recipeCount: any
  imageAltText: any
}

export interface RecipeCollection {
  id: any
  title: any
  subtitle: any
  description: any
  curators: any
}

export interface RecipeCollectionSeries {
  displayPosition: any
  focusedPosition: any
  enhancedDisplay: boolean
}

export interface RecipeVariantIngredientOverrideOption {
}

export interface RecipeVariantStepCookingTip {
  name: any
  body: any
}

export interface ScheduledMenuRecipe {
  menuRecipeId: any
  scheduledOn: string
  timeOfDay: any
}

export interface SummaryTailoredRecipe {
  menuRecipeVariantId: string
  hidden: string
  recipeId: string
  mealType: MealTypesEnum
  mealTypeLabel: string
  recipeVariantId: string
  recipeVariantName: string
  imageUrl: string
  introduction: string
  alternateRecipeVariants: any
  activeDietaryPreferenceIds: string[]
  trackerId: string
  servings: number
  servingOverrideOptions: number[]
  activeCookTime: number
  passiveCookTime: number
  totalCookTime: number
  rating: number
  ratingVotes: number
  userRating: number
  userRecipeStatus: any
  id: string
  recipeVariants: any
}

export interface DetailedTailoredRecipe {
  menuRecipeVariantId: string
  hidden: string
  recipeId: string
  mealType: MealTypesEnum
  mealTypeLabel: string
  recipeVariantId: string
  recipeVariantName: string
  imageUrl: string
  introduction: string
  alternateRecipeVariants: any
  activeDietaryPreferenceIds: string[]
  trackerId: string
  servings: number
  servingOverrideOptions: number[]
  activeCookTime: number
  passiveCookTime: number
  totalCookTime: number
  rating: number
  ratingVotes: number
  userRating: number
  userRecipeStatus: any
  id: string
  recipeVariants: any
  shareUrl: any
  nutritionInfo: any
  ingredients: any
  steps: any
  notes: any
  tags: any
}

export interface UserFamilyMember {
  name: any
  portionSize: any
  mealTypes: any
}

export interface UserIngredientExclusionSetting {
  ingredients: IngredientSummary[]
  ingredientExclusionCategories: IngredientExclusionCategorySummary[]
}

export interface UserRecipeClassificationSetting {
  breakfastsPerMenu: number
  lunchesPerMenu: number
  batchMealsPerMenu: number
  dinnersPerMenu: number
  snacksDessertsPerMenu: number
  breakfastMaxCookMinutes: number
  lunchMaxCookMinutes: number
  dinnerMaxCookMinutes: number
  snackDessertMaxCookMinutes: number
  dinnerLeftoversForLunch: boolean
  doubleBreakfasts: DoublingPreferencesEnum
}

export interface UserRecipeIngredientOverride {
}

export interface UserRecipeNote {
  body: any
}

export interface UserRecipeRating {
  rating: number
  timeFeltRight: boolean
  comments: string
}

export interface UserRecipeStatus {
  favorite: boolean
  permanentlyRemoved: boolean
  includeInNextMenu: boolean
}

export interface UserSettings {
  timezone: any
  hemisphere: any
  deliveryPreference: any
  defaultMenuView: any
  newMenuDayOfWeek: any
  shopOn: string
  includeOutOfSeasonIngredients: boolean
  includeOutOfSeasonMeals: boolean
  ketoMaxNetCarbs: any
  omitSmoothies: boolean
}

export interface UserSubstituteIngredientSetting {
  substituteBreadType: any
  substituteMilkType: any
  substitutePastaType: any
  substituteRiceType: any
}

