import { format } from 'date-fns'
import { DateTime } from 'luxon'
import { DayPicker } from 'react-day-picker'

export default function DateInput({
  id,
  value,
  maxDate,
  minDate,
  onChange,
}: {
  id: string
  value: string | DateTime
  maxDate?: string | DateTime
  minDate?: string | DateTime
  onChange: (value: Date | undefined) => void
}) {
  const date = typeof value === 'string' ? DateTime.fromISO(value) : value
  const month = new Date(date.year, date.month - 1)

  let maxValue = typeof maxDate === 'string' ? DateTime.fromISO(maxDate) : maxDate
  let minValue = typeof minDate === 'string' ? DateTime.fromISO(minDate) : minDate

  return (
    <DayPicker
      id={id}
      mode="single"
      selected={date.toJSDate()}
      defaultMonth={month}
      fromDate={minValue?.toJSDate()}
      toDate={maxValue?.toJSDate()}
      onSelect={date => {
        onChange(date)
      }}
    />
  )
}
