import { ReactNode } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'

export default function ConfirmModal({
  open,
  onClose,
  onConfirm,
  children,
  header = null,
  confirmText = 'Ok',
  cancelText = 'Cancel',
  size = 'sm',
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  header?: ReactNode | null
  children: ReactNode
  confirmText?: string
  cancelText?: string
  size?: string
}) {
  return (
    <Modal size={size as any} isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            {header && <ModalHeader className="flex flex-col gap-1">{header}</ModalHeader>}
            <ModalBody>{children}</ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                {cancelText}
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  onConfirm()
                }}
              >
                {confirmText}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
