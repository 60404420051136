import { Input, Pagination } from '@nextui-org/react'
import RecipeFilters from './filters'
import { CiSearch } from 'react-icons/ci'
import RecipesAPI, { RecipeFilterData } from '../../../api/recipes'
import { ReactNode, useCallback, useState } from 'react'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import useMountEffect from '../../../hooks/useMountEffect'
import { AdminRecipe, AdminRecipeSummary } from '../../../api/schema'

export default function RecipeSearch<T extends AdminRecipeSummary | AdminRecipe>({
  onChange,
  children,
}: {
  onChange: (recipes: T[]) => void
  children: ReactNode
}) {
  const [recipes, setRecipes] = useState<T[]>([])
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(1)
  const [searchText, setSearchText] = useState<string>('')
  const [filters, setFilters] = useState<RecipeFilterData>({
    applianceId: null,
    mealType: null,
    cuisine: null,
    dietaryPreferenceId: null,
    dishType: null,
    subCuisines: [],
    showHidden: false,
  })

  const fetchRecipes = useCallback(async () => {
    const results = await RecipesAPI.index({ ...filters, search: searchText, page })
    const recipes = results.data.recipes
    setRecipes(recipes)
    setNumPages(results.data.numPages)
    onChange(recipes)
  }, [searchText, page, filters])

  useUpdateEffect(() => {
    // eslint-disable-next-line
    fetchRecipes()
  }, [page, searchText, filters])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipes()
  }, [])

  return (
    <div>
      <RecipeFilters
        onChange={newFilters => {
          setFilters(newFilters)
        }}
      />

      <Input
        type="text"
        label="Search"
        name="search"
        id="search-recipes-input"
        labelPlacement="outside"
        startContent={<CiSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />}
        onChange={async e => {
          setSearchText(e.target.value)
        }}
      />

      {children}

      <Pagination
        total={numPages}
        initialPage={1}
        onChange={async page => {
          setPage(page)
        }}
        size="lg"
        boundaries={Math.floor(window.innerWidth / 80 / 2)}
      />
    </div>
  )
}
