import routes from '../config/routes'
import { api } from './common'
import { DietaryPreferenceSummary } from './dietaryPreferences'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { RecipeSummary } from './recipes'

export default class RecipeCollectionsAPI {
  static show(id: string) {
    return api.get(routes.api.recipeCollections.show(id))
  }

  static create({ name, recipeCollectionSeriesId }: { name: string; recipeCollectionSeriesId: string }) {
    return api.post(routes.api.recipeCollections.create(), { name, recipeCollectionSeriesId })
  }

  static update(id: string, opts: Partial<RecipeCollectionDetail>) {
    return api.patch(routes.api.recipeCollections.update(id), opts)
  }

  public static async createLocalizedText(recipeCollectionId: string, params: LocalizedTextParams) {
    console.log('doin it', params, routes.api.recipeCollections.localizedTexts.create(recipeCollectionId))
    return await api.post(routes.api.recipeCollections.localizedTexts.create(recipeCollectionId), {
      localizedText: params,
    })
  }

  static addDietaryPreference({
    recipeCollectionId,
    dietaryPreferenceId,
  }: {
    recipeCollectionId: string
    dietaryPreferenceId: string
  }) {
    return api.post(routes.api.recipeCollections.addDietaryPreference(recipeCollectionId), {
      id: dietaryPreferenceId,
    })
  }

  static addRecipe({ recipeCollectionId, recipeId }: { recipeCollectionId: string; recipeId: string }) {
    return api.post(routes.api.recipeCollections.addRecipe(recipeCollectionId), { id: recipeId })
  }

  static removeDietaryPreference({
    recipeCollectionId,
    dietaryPreferenceId,
  }: {
    recipeCollectionId: string
    dietaryPreferenceId: string
  }) {
    return api.delete(
      routes.api.recipeCollections.removeDietaryPreference(recipeCollectionId, dietaryPreferenceId)
    )
  }

  static removeRecipe({ recipeCollectionId, recipeId }: { recipeCollectionId: string; recipeId: string }) {
    return api.delete(routes.api.recipeCollections.removeRecipe(recipeCollectionId, recipeId))
  }
}

export interface RecipeCollectionSummary {
  id: string
  name: string
}

export interface RecipeCollectionDetail {
  id: string
  name: string
  proteinPreference: DietaryPreferenceSummary
  dietaryPatternPreference: DietaryPreferenceSummary
  otherDietaryPreferences: DietaryPreferenceSummary[]
  recipes: RecipeSummary[]
  localizedTexts: LocalizedText[]
}
