import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/stores'
import { MsalProvider } from '@azure/msal-react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import App from './app'
import reportWebVitals from './reportWebVitals'
import { pca } from './app/helpers/auth/pca'

import { NextUIProvider } from '@nextui-org/react'

import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/dist/style.css'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <Router>
            <NextUIProvider>
              <App />
            </NextUIProvider>
          </Router>
        </Provider>
      </MsalProvider>
    </DndProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
