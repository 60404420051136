import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import RecipeVariantsAPI from '../../../api/recipeVariants'
import { IoAddCircleOutline, IoPencil, IoTrash } from 'react-icons/io5'
import { useState } from 'react'
import AddIngredientModal from '../../modals/add-ingredient-modal'
import EditRecipeVariantIngredientModal from '../../modals/edit-recipe-variant-ingredient-modal'
import RecipeVariantIngredientsAPI from '../../../api/recipeVariantIngredients'
import { AdminRecipeVariantDetail, AdminRecipeVariantIngredient } from '../../../api/schema'
import ConfirmModal from '../../modals/confirm'

type RecipeVariantIngredientModalType = 'add_ingredient'

export default function RecipeVariantIngredientsCrud({
  recipeVariant,
  onSave,
}: {
  recipeVariant: AdminRecipeVariantDetail
  onSave: () => void
}) {
  const [modalType, setModalType] = useState<RecipeVariantIngredientModalType | null>(null)

  return (
    <div style={{ marginBottom: 40 }}>
      <div className="flex gap-4 items-center">
        <h2>Ingredients</h2>
        <Button
          className="text-white"
          color="success"
          aria-label="Add recipe"
          style={{ marginLeft: 'auto' }}
          onClick={async () => {
            setModalType('add_ingredient')
          }}
        >
          <IoAddCircleOutline size={20} />
          Add Ingredient
        </Button>
      </div>

      <Table aria-label="Dietary Preferences" shadow="none">
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>IMPERIAL AMOUNT AND UNITS</TableColumn>
          <TableColumn>METRIC AMOUNT AND UNITS</TableColumn>
          <TableColumn>ACTIONS</TableColumn>
        </TableHeader>

        <TableBody>
          {recipeVariant.recipeVariantIngredients.map((recipeVariantIngredient, index) => (
            <TableRow key={index}>
              <TableCell>{recipeVariantIngredient.ingredient.id || 'N/A'}</TableCell>
              <TableCell>
                <div>{recipeVariantIngredient.ingredient.name || 'N/A'}</div>
                {recipeVariant.recipeVariantIngredientOverrideOptions
                  .filter(option => option.overriddenIngredient.id === recipeVariantIngredient.ingredient.id)
                  .map((override, index2) => (
                    <div key={index2} style={{ marginBottom: 5, marginTop: 5 }}>
                      <Chip>alternate: {override.ingredient.name}</Chip>
                    </div>
                  ))}
                {recipeVariantIngredient.ingredient.isPlaceholder &&
                  recipeVariantIngredient.ingredient.substitutes.map((ingredient, index2) => (
                    <div key={index2} style={{ marginBottom: 5, marginTop: 5 }}>
                      <Chip>substitute: {ingredient.name}</Chip>
                    </div>
                  ))}
              </TableCell>
              <TableCell>
                {recipeVariantIngredient.imperialAmount} ({recipeVariantIngredient.ingredient.imperialUnit})
              </TableCell>
              <TableCell>
                {recipeVariantIngredient.metricAmount} ({recipeVariantIngredient.ingredient.metricUnit})
              </TableCell>
              <TableCell>
                <RecipeVariantIngredientActionsRow
                  key={index}
                  recipeVariant={recipeVariant}
                  recipeVariantIngredient={recipeVariantIngredient}
                  onSave={onSave}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AddIngredientModal
        open={modalType === 'add_ingredient'}
        onClose={async () => {
          setModalType(null)
          onSave()
        }}
        onSelect={async ingredient => {
          await RecipeVariantsAPI.addIngredient(recipeVariant.id, {
            ingredientId: ingredient.id,
          })
          onSave()
        }}
        addedIngredientIds={recipeVariant.recipeVariantIngredients.map(rvi => rvi.ingredient.id)}
        scope="non-substitute-ingredients"
      />
    </div>
  )
}

type RecipeVariantIngredientRowModalType = 'edit_ingredient' | 'confirm_delete_ingredient'

function RecipeVariantIngredientActionsRow({
  recipeVariant,
  recipeVariantIngredient,
  onSave,
}: {
  recipeVariant: AdminRecipeVariantDetail
  recipeVariantIngredient: AdminRecipeVariantIngredient
  onSave: () => void
}) {
  const [modalType, setModalType] = useState<RecipeVariantIngredientRowModalType | null>(null)

  return (
    <>
      <Button
        isIconOnly
        id={`edit-recipe-variant-ingredient-${recipeVariantIngredient.id}`}
        onClick={async () => {
          setModalType('edit_ingredient')
        }}
      >
        <IoPencil size={20} />
      </Button>
      <Button
        isIconOnly
        color="danger"
        style={{ marginLeft: 20 }}
        id={`delete-recipe-variant-ingredient-${recipeVariantIngredient.id}`}
        onClick={async () => {
          setModalType('confirm_delete_ingredient')
        }}
      >
        <IoTrash size={20} />
      </Button>

      <EditRecipeVariantIngredientModal
        open={modalType === 'edit_ingredient'}
        onClose={() => {
          setModalType(null)
        }}
        onSave={() => {
          onSave()
        }}
        recipeVariant={recipeVariant}
        recipeVariantIngredient={recipeVariantIngredient}
      />

      <ConfirmModal
        open={modalType === 'confirm_delete_ingredient'}
        onConfirm={async () => {
          await RecipeVariantIngredientsAPI.destroy(recipeVariantIngredient.id)
          setModalType(null)
          onSave()
        }}
        onClose={() => {
          setModalType(null)
        }}
        header={`Remove ${recipeVariantIngredient.ingredient.name}?`}
      >
        Are you sure you want to remove {recipeVariantIngredient.ingredient.name} from this recipe variant?
      </ConfirmModal>
    </>
  )
}
