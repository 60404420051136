import { Card, CardHeader, CardBody, Divider, Chip, Button } from '@nextui-org/react'
import { RecipeCollectionSeriesSummary } from '../../../api/recipeCollectionSeries'
import { IoAdd, IoApps } from 'react-icons/io5'
import { NavLink, useNavigate } from 'react-router-dom'
import routes from '../../../config/routes'
import { useState } from 'react'
import NewRecipeCollectionModal from '../../modals/new-recipe-collection-modal'

export type RecipeCollectionSeriesCardModalType = 'add_collection'

export default function RecipeCollectionSeriesCard({
  series,
  seriesNumber,
}: {
  series: RecipeCollectionSeriesSummary
  seriesNumber: number
}) {
  const navigate = useNavigate()
  const [modalType, setModalType] = useState<RecipeCollectionSeriesCardModalType | null>(null)

  return (
    <Card className="max-w-[400px]" style={{ marginBottom: 20, marginTop: 20 }} shadow="none">
      <CardHeader className="flex gap-3">
        <IoApps />
        <div className="flex-1 flex-col">
          <p className="text-md">
            series {seriesNumber}
            {series.enhancedDisplay && (
              <Chip style={{ marginLeft: 20 }} variant="bordered" color="secondary">
                enhanced
              </Chip>
            )}
          </p>
        </div>
        <div className="flex-1" style={{ textAlign: 'right' }}>
          <Button
            id={`add-collection-to-series-${series.id}`}
            variant="flat"
            color="success"
            startContent={<IoAdd />}
            onPress={() => {
              setModalType('add_collection')
            }}
          >
            add collection
          </Button>
        </div>
      </CardHeader>
      <Divider />

      <CardBody>
        {series.recipeCollections.map((collection, index) => (
          <NavLink
            to={routes.app.recipeCollections.show(collection.id)}
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            <Chip
              color={collection.id === series.focusedRecipeCollectionId ? 'success' : 'default'}
              variant="flat"
            >
              {collection.name}
            </Chip>
          </NavLink>
        ))}
      </CardBody>

      <NewRecipeCollectionModal
        open={modalType === 'add_collection'}
        onClose={() => {
          setModalType(null)
        }}
        onSave={id => {
          navigate(routes.app.recipeCollections.show(id))
        }}
        recipeCollectionSeriesId={series.id}
      />
    </Card>
  )
}
