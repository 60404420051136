import { useState } from 'react'
import RecipeVariantStepsAPI, { RecipeVariantStepSummary } from '../../../api/recipeVariantSteps'
import { sortBy } from 'lodash'
import Tiptap from '../../providers/Tiptap'
import { IoAddCircleOutline, IoPencil, IoTrash } from 'react-icons/io5'
import { Button } from '@nextui-org/react'
import EditRecipeVariantStepModal from '../../modals/edit-recipe-variant-step-modal'
import AddRecipeVariantStepModal from '../../modals/add-variant-step-modal'
import ConfirmModal from '../../modals/confirm'
import { AdminRecipeVariantDetail, AdminRecipeVariantStep } from '../../../api/schema'
import Banner from '../../ui/banner'

export default function RecipeVariantStepsCrud({
  recipeVariant,
  onSave,
}: {
  recipeVariant: AdminRecipeVariantDetail
  onSave: () => void
}) {
  const [createMode, setCreateMode] = useState(false)

  const allIngredientIds = recipeVariant.recipeVariantIngredients.map(rvi => rvi.ingredient.id)
  const allStepIngredientIds: string[] = recipeVariant.recipeVariantSteps.flatMap(step => step.ingredientIds)
  const missingIngredientIds = allIngredientIds.filter(id => !allStepIngredientIds.includes(id))

  return (
    <div>
      <div className="flex gap-4 items-center">
        <h2>Recipe Steps</h2>
        <Button
          className="text-white"
          color="success"
          aria-label="Add recipe"
          style={{ marginLeft: 'auto' }}
          onClick={async () => {
            setCreateMode(true)
          }}
        >
          <IoAddCircleOutline size={20} />
          Add step
        </Button>
      </div>

      {missingIngredientIds.length ? (
        <Banner type="error" heading="Warning">
          <span>The following ingredients are not included in any recipe steps:</span>
          {missingIngredientIds.map(ingredientId => (
            <div style={{ paddingLeft: 20, fontWeight: 'bold' }}>
              {recipeVariant.recipeVariantIngredients.find(rvi => rvi.ingredient.id === ingredientId)
                ?.ingredient?.name || ingredientId}
            </div>
          ))}
        </Banner>
      ) : null}
      {sortBy(recipeVariant.recipeVariantSteps, 'position').map((recipeVariantStep, index) => (
        <RecipeVariantStepCrud
          key={index}
          recipeVariant={recipeVariant}
          recipeVariantStep={recipeVariantStep}
          onSave={onSave}
        />
      ))}

      <AddRecipeVariantStepModal
        open={createMode}
        onClose={() => {
          setCreateMode(false)
        }}
        onSave={() => {
          setCreateMode(false)
          onSave()
        }}
        recipeVariant={recipeVariant}
      />
    </div>
  )
}

export function RecipeVariantStepCrud({
  recipeVariantStep,
  recipeVariant,
  onSave,
}: {
  recipeVariantStep: AdminRecipeVariantStep
  recipeVariant: AdminRecipeVariantDetail
  onSave: () => void
}) {
  const [activeModal, setActiveModal] = useState<RecipeVariantStepCrudModalType | null>(null)

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <div style={{ width: 110, display: 'inline-flex', verticalAlign: 'top' }}>
        <Button
          id={`edit-recipe-variant-step-${recipeVariantStep.id}`}
          isIconOnly
          onPress={() => setActiveModal('edit_recipe_step')}
        >
          <IoPencil />
        </Button>
        <Button
          id={`delete-recipe-variant-step-${recipeVariantStep.id}`}
          style={{ marginLeft: 10 }}
          isIconOnly
          onPress={() => setActiveModal('confirm_delete_recipe_step')}
        >
          <IoTrash />
        </Button>
      </div>
      <div style={{ width: 800, display: 'inline-flex', verticalAlign: 'top' }}>
        {
          <div>
            <div>
              <Tiptap
                id={`display-recipe-step-${recipeVariantStep.id}`}
                readonly
                controlled
                content={(recipeVariantStep as any).renderedBody}
              />
            </div>
            {recipeVariantStep.ingredient && (
              <div style={{ marginTop: -15, marginBottom: 15 }}>
                <i>
                  Step visible when recipe variant is customized with{' '}
                  <strong>{recipeVariantStep.ingredient.name}</strong>
                </i>
              </div>
            )}
          </div>
        }
      </div>

      <EditRecipeVariantStepModal
        open={activeModal === 'edit_recipe_step'}
        onClose={() => {
          setActiveModal(null)
        }}
        onSave={({ closeModal }) => {
          if (closeModal) setActiveModal(null)
          onSave()
        }}
        recipeVariant={recipeVariant}
        recipeVariantStep={recipeVariantStep}
      />

      <ConfirmModal
        open={activeModal === 'confirm_delete_recipe_step'}
        onClose={() => {
          setActiveModal(null)
        }}
        onConfirm={async () => {
          await RecipeVariantStepsAPI.destroy(recipeVariantStep.id)
          setActiveModal(null)
          onSave()
        }}
        header="Confirm"
      >
        Are you sure you want to delete this recipe step?
      </ConfirmModal>
    </div>
  )
}

type RecipeVariantStepCrudModalType = 'edit_recipe_step' | 'confirm_delete_recipe_step'
