import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import RecipesAPI from '../../api/recipes'
import { useParams, NavLink } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip,
} from '@nextui-org/react'
import routes from '../../config/routes'
import { IoAddSharp, IoPencil } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Loader from '../../components/loader'
import { AdminRecipe } from '../../api/schema'
import ImageDisplay from '../../components/ui/image-display'

export default function RecipesShowPage() {
  const [recipe, setRecipe] = useState<AdminRecipe | null>(null)
  const { id } = useParams()

  const fetchRecipe = useCallback(async () => {
    const results = await RecipesAPI.show(id!)
    setRecipe(results.data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchRecipe()
  }, [id])

  if (!recipe) return <Loader />

  // 'prePrepared',
  // 'exotic',
  // 'introduction',
  // 'description',
  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.show(id!)}>{recipe.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>
          {recipe.name}
          {recipe.hidden ? (
            <Chip
              color="warning"
              variant="flat"
              style={{ marginLeft: 10, top: '-3px', position: 'relative' }}
            >
              Hidden
            </Chip>
          ) : (
            <Chip
              color="success"
              variant="flat"
              style={{ marginLeft: 10, top: '-3px', position: 'relative' }}
            >
              Visible
            </Chip>
          )}
        </h1>
        <Button
          as={Link}
          to={routes.app.recipes.edit(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoPencil size={20} />
          Edit
        </Button>
      </div>

      <ImageDisplay
        id="recipe-image"
        src={recipe.imageUrl ? `${recipe.imageUrl}?w=500&h=500` : null}
        size="lg"
      />

      <h2>Properties</h2>

      <Table
        aria-label="Properties"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell>Hidden</TableCell>
            <TableCell>{recipe.hidden ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Meal Type</TableCell>
            <TableCell>{recipe.mealType || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Dish Type</TableCell>
            <TableCell>{recipe.dishType || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cuisine</TableCell>
            <TableCell>{recipe.cuisine || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Subcuisines</TableCell>
            <TableCell>{recipe.subCuisines?.length ? recipe.subCuisines.join(',') : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Servings</TableCell>
            <TableCell>{recipe.servings}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fixed Portion Size</TableCell>
            <TableCell>{recipe.fixedPortionSize ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Doubleable</TableCell>
            <TableCell>{recipe.doubleable ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Doubleable</TableCell>
            <TableCell>{recipe.doubleable ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pre-Prepared</TableCell>
            <TableCell>{recipe.prePrepared ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Exotic</TableCell>
            <TableCell>{recipe.exotic ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Seasonality</TableCell>
            <TableCell>{recipe.seasonality || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Northern Hemisphere Season Start Day</TableCell>
            <TableCell>{recipe.northernHemisphereSeasonStartDay || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Northern Hemisphere Season End Day</TableCell>
            <TableCell>{recipe.northernHemisphereSeasonEndDay || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Southern Hemisphere Season Start Day</TableCell>
            <TableCell>{recipe.southernHemisphereSeasonStartDay || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Southern Hemisphere Season End Day</TableCell>
            <TableCell>{recipe.southernHemisphereSeasonEndDay || 'N/A'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className="flex gap-4 items-center">
        <h2>Recipe Variants</h2>
        <Button
          as={Link}
          to={routes.app.recipes.recipeVariants.new(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoAddSharp size={20} />
          New recipe variant
        </Button>
      </div>

      <Table
        aria-label="Recipe Variants"
        shadow="none"
        classNames={{
          base: 'table-base',
        }}
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>BASE VARIANT</TableColumn>
        </TableHeader>

        <TableBody>
          {recipe.recipeVariants.map((recipeVariant, index) => (
            <TableRow key={index}>
              <TableCell>{recipeVariant.id || 'N/A'}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipes.recipeVariants.show(recipeVariant.id)}>
                  {recipeVariant.name || 'N/A'}
                </NavLink>
              </TableCell>
              <TableCell>{recipeVariant.baseVariant ? 'true' : 'false'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Recipe Collections</h2>
      <Table
        aria-label="Recipe Collections"
        shadow="none"
        classNames={{
          base: 'table-base',
        }}
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
        </TableHeader>

        <TableBody>
          {recipe.recipeCollections.map((recipeCollection, index) => (
            <TableRow key={index}>
              <TableCell>{recipeCollection.id || 'N/A'}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipeCollections.show(recipeCollection.id)}>
                  {recipeCollection.name || 'N/A'}
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
