import routes from '../config/routes'
import { api } from './common'

export default class DietaryPreferencesAPI {
  static index() {
    return api.get(routes.api.dietaryPreferences.index)
  }
}

export interface DietaryPreferenceSummary {
  id: string
  name: string
}
