import { useCallback, useEffect, useState } from 'react'
import RecipeBrowseCategoriesAPI from '../../api/recipeBrowseCategories'
import { useParams, NavLink } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import routes from '../../config/routes'
import { IoPencil } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { AdminRecipeBrowseCategoryDetail } from '../../api/schema'
import useMountEffect from '../../hooks/useMountEffect'
import Loader from '../../components/loader'
import AddRecipeVariantToRecipeBrowseCategoryModal from '../../components/modals/add-recipe-variant-to-recipe-browse-category-modal'
import isTest from '../../helpers/isTest'
import notify from '../../helpers/notify'

export default function RecipeBrowseCategoriesShowPage() {
  const [recipeBrowseCategory, setRecipeBrowseCategory] = useState<AdminRecipeBrowseCategoryDetail | null>(
    null
  )
  const [modalType, setModalType] = useState<RecipeBrowseCategoriesShowPageModalType>(null)
  const { id } = useParams()

  const fetchRecipeBrowseCategories = useCallback(async () => {
    const results = await RecipeBrowseCategoriesAPI.show(id!)
    setRecipeBrowseCategory(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeBrowseCategories()
  }, [id])

  if (!recipeBrowseCategory) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.index}>Recipe Browse Categories</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.show(id!)}>{recipeBrowseCategory.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>{recipeBrowseCategory.name}</h1>

        <Button
          as={Link}
          to={routes.app.recipeBrowseCategories.edit(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoPencil size={20} />
          Edit
        </Button>
      </div>

      <div className="flex gap-4 items-center">
        <h2>Primary Recipe Variant</h2>

        <Button
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
          onPress={() => {
            setModalType('edit_recipe_variant')
          }}
        >
          <IoPencil size={20} />
          Change Primary Recipe Variant
        </Button>
      </div>

      <Table
        aria-label="Primary Recipe Variant"
        classNames={{
          base: 'table-base',
        }}
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>IMAGE</TableColumn>
          <TableColumn>NAME</TableColumn>
        </TableHeader>

        <TableBody
          style={{
            maxHeight: 'calc(100vh - 400px)',
          }}
        >
          <TableRow>
            <TableCell>{recipeBrowseCategory.recipeVariant?.id || 'N/A'}</TableCell>
            <TableCell>
              {recipeBrowseCategory.recipeVariant?.imageUrl ? (
                <div
                  style={{
                    backgroundImage: `url(${recipeBrowseCategory.recipeVariant.imageUrl})`,
                    width: 100,
                    height: 100,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              ) : (
                'N/A'
              )}
            </TableCell>
            <TableCell>{recipeBrowseCategory.recipeVariant?.name || 'N/A'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Filters</h2>

      <Table
        aria-label="Filters"
        classNames={{
          base: 'table-base',
        }}
      >
        <TableHeader>
          <TableColumn>FILTER NAME</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody
          style={{
            maxHeight: 'calc(100vh - 400px)',
          }}
        >
          <TableRow>
            <TableCell>Conform to User Preferences</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.conformToUserPreferences ? 'true' : 'false'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Query</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.query || 'N/A'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Ingredients Query</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.ingredientQuery || 'N/A'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Cuisines</TableCell>
            <TableCell>
              {recipeBrowseCategory.filters?.filters?.cuisines?.length
                ? recipeBrowseCategory.filters?.filters?.cuisines.join(', ')
                : 'N/A'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Meal Types</TableCell>
            <TableCell>
              {recipeBrowseCategory.filters?.filters?.mealTypes?.length
                ? recipeBrowseCategory.filters?.filters?.mealTypes.join(', ')
                : 'N/A'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Dish Types</TableCell>
            <TableCell>
              {recipeBrowseCategory.filters?.filters?.dishTypes?.length
                ? recipeBrowseCategory.filters?.filters?.dishTypes.join(', ')
                : 'N/A'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Active Time</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.filters?.activeTime || 'N/A'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Total Time</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.filters?.totalTime || 'N/A'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Appliance Id</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.filters?.applianceId || 'N/A'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Dietary Preference Id</TableCell>
            <TableCell>{recipeBrowseCategory.filters?.filters?.dietaryPreferenceId || 'N/A'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Recipe Variants ({recipeBrowseCategory.recipeVariants.length})</h2>

      <Table
        isHeaderSticky
        aria-label="Recipe Variants"
        classNames={{
          base: 'table-base search',
        }}
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>BASE VARIANT</TableColumn>
        </TableHeader>

        <TableBody>
          {recipeBrowseCategory.recipeVariants.map((recipeVariant, index) => (
            <TableRow key={index}>
              <TableCell>{recipeVariant.id || 'N/A'}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipes.recipeVariants.show(recipeVariant.id)}>
                  {recipeVariant.name || 'N/A'}
                </NavLink>
              </TableCell>
              <TableCell>{recipeVariant.baseVariant ? 'true' : 'false'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AddRecipeVariantToRecipeBrowseCategoryModal
        open={modalType === 'edit_recipe_variant'}
        availableRecipeVariants={recipeBrowseCategory.recipeVariants}
        currentRecipeVariant={recipeBrowseCategory.recipeVariant}
        onClose={() => {
          setModalType(null)
        }}
        onSelect={async recipeVariant => {
          setModalType(null)
          await RecipeBrowseCategoriesAPI.update(recipeBrowseCategory.id, {
            recipeVariantId: recipeVariant.id,
          })

          await fetchRecipeBrowseCategories()

          if (isTest()) {
            notify('Successfully updated primary recipe variant')
          }
        }}
      />
    </div>
  )
}

type RecipeBrowseCategoriesShowPageModalType = 'edit_recipe_variant' | null
