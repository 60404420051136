import { Button, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../../api/ingredients'
import FormGroup from '../../../forms/form-group'
import { AdminIngredientDetail, SeasonalitiesEnumValues } from '../../../../api/schema'
import SeasonalityInput from '../../../forms/seasonality-input'

export default function IngredientSeasonalityForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  const [northernHemisphereSeasonStartDay, setNorthernHemisphereSeasonStartDay] = useState(
    ingredient.northernHemisphereSeasonStartDay
  )
  const [northernHemisphereSeasonEndDay, setNorthernHemisphereSeasonEndDay] = useState(
    ingredient.northernHemisphereSeasonEndDay
  )
  const [southernHemisphereSeasonStartDay, setSouthernHemisphereSeasonStartDay] = useState(
    ingredient.southernHemisphereSeasonStartDay
  )
  const [southernHemisphereSeasonEndDay, setSouthernHemisphereSeasonEndDay] = useState(
    ingredient.southernHemisphereSeasonEndDay
  )
  const [seasonality, setSeasonality] = useState(ingredient.seasonality)

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Seasonality</h2>

      <FormGroup>
        <Select
          style={{ marginBottom: 20 }}
          label="Seasonality"
          id="edit-ingredient-seasonality-select"
          onChange={e => {
            setSeasonality(e.target.value as any)
          }}
          selectedKeys={[seasonality]}
        >
          {SeasonalitiesEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <SeasonalityInput
          id="northern-hemisphere-seasonality-date-picker"
          label="Northern hemisphere season"
          startRange={northernHemisphereSeasonStartDay}
          endRange={northernHemisphereSeasonEndDay}
          onStartChange={newStart => {
            setNorthernHemisphereSeasonStartDay(newStart)
          }}
          onEndChange={newEnd => {
            setNorthernHemisphereSeasonEndDay(newEnd)
          }}
        />
      </FormGroup>

      <FormGroup>
        <SeasonalityInput
          id="southern-hemisphere-seasonality-date-picker"
          label="Southern hemisphere season"
          startRange={southernHemisphereSeasonStartDay}
          endRange={southernHemisphereSeasonEndDay}
          onStartChange={newStart => {
            setSouthernHemisphereSeasonStartDay(newStart)
          }}
          onEndChange={newEnd => {
            setSouthernHemisphereSeasonEndDay(newEnd)
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await IngredientsAPI.update(ingredient.id, {
              northernHemisphereSeasonStartDay,
              northernHemisphereSeasonEndDay,
              southernHemisphereSeasonStartDay,
              southernHemisphereSeasonEndDay,
              seasonality,
            })
            onSave()
          }}
        >
          Save seasonality
        </Button>
      </div>
    </div>
  )
}
