import React, { ReactNode } from 'react'
import { sidebarWidth } from '../../config/dimensions'
import useWindowSize from '../../hooks/useWindowSize'

export default function AppContent({ children }: { children: ReactNode }) {
  const windowSize = useWindowSize()

  return (
    <div
      style={{
        width: `calc(100vw - ${sidebarWidth(windowSize.width)}px)`,
        marginLeft: sidebarWidth(windowSize.width),
        height: '100vh',
        overflowY: 'auto',
        padding: '20px 45px 20px 20px',
      }}
    >
      {children}
    </div>
  )
}
