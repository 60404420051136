import { CheckboxGroup, Checkbox } from '@nextui-org/react'
import { useCallback, useState } from 'react'
import AppliancesAPI from '../../../api/appliances'
import { AdminAppliance } from '../../../api/schema'
import useMountEffect from '../../../hooks/useMountEffect'

export default function RecipeVariantAppliancesCrud({
  selectedAppliances,
  onAddAppliance,
  onRemoveAppliance,
}: {
  selectedAppliances: AdminAppliance[]
  onAddAppliance: (appliance: AdminAppliance) => void
  onRemoveAppliance: (appliance: AdminAppliance) => void
}) {
  const [selectedApplianceIds, setSelectedApplianceIds] = useState<string[]>(
    selectedAppliances.map(dp => dp.id)
  )
  const [appliances, setAppliances] = useState<AdminAppliance[]>([])

  const fetchAppliances = useCallback(async () => {
    const results = await AppliancesAPI.index()
    setAppliances(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchAppliances()
  }, [])

  const id = 'recipe-variant-appliances-crud'
  const header = 'Appliances'
  return (
    <div id={id} style={{ marginBottom: 40 }}>
      <h2>{header}</h2>

      <CheckboxGroup
        id={`${id}-checkbox-group`}
        orientation="horizontal"
        color="secondary"
        value={selectedApplianceIds}
        onValueChange={newApplianceIds => {
          setSelectedApplianceIds(newApplianceIds)
          const variantApplianceIds = selectedAppliances.map(dp => dp.id)

          if (newApplianceIds.length > selectedAppliances.length) {
            const addedPreferenceId = newApplianceIds.find(id => !variantApplianceIds.includes(id))
            const addedPreference = appliances.find(dp => dp.id === addedPreferenceId)!
            onAddAppliance(addedPreference)
          } else {
            const removedPreferenceId = variantApplianceIds.find(id => !newApplianceIds.includes(id))
            const removedPreference = appliances.find(dp => dp.id === removedPreferenceId)!
            onRemoveAppliance(removedPreference)
          }
        }}
      >
        {appliances.map((appliance, index) => (
          <Checkbox
            id={`${id}-${appliance.id}-checkbox`}
            key={index}
            value={appliance.id}
            style={{ minWidth: 300 }}
          >
            {appliance.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
  )
}
