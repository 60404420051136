import React, { useEffect, useRef } from 'react'

const useMountEffect = (effect: () => void, dependencies: any[]) => {
  const alreadyRun = useRef(false)

  useEffect(() => {
    if (!alreadyRun.current) {
      alreadyRun.current = true
      return effect()
    }
  }, dependencies)
}

export default useMountEffect
