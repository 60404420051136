import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip,
} from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import RecipeCollectionsAPI, { RecipeCollectionDetail } from '../../api/recipeCollections'
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5'
import AddRecipeModal from '../../components/modals/add-recipe-modal'
import AddDietaryPreferenceModal from '../../components/modals/add-dietary-preference-modal'
import { DietaryPreferenceSummary } from '../../api/dietaryPreferences'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import EditableInput from '../../components/forms/editable-input'
import notify from '../../helpers/notify'
import { AdminRecipeSummary } from '../../api/schema'
import Loader from '../../components/loader'
import ConfirmModal from '../../components/modals/confirm'

type RecipeCollectionShowModalType =
  | 'add_recipe'
  | 'add_dietary_preference'
  | 'confirm_remove_recipe'
  | 'confirm_remove_dietary_preference'

export default function RecipeCollectionsShowPage() {
  const [collection, setCollection] = useState<RecipeCollectionDetail | null>(null)
  const [collectionName, setCollectionName] = useState('')
  const [modalType, setModalType] = useState<RecipeCollectionShowModalType | null>(null)
  const [recipeIdToRemove, setRecipeIdToRemove] = useState<string | null>(null)
  const [dietaryPreferenceIdToRemove, setDietaryPreferenceIdToRemove] = useState<string | null>(null)

  const { id } = useParams()

  const fetchCollection = useCallback(async () => {
    const results = await RecipeCollectionsAPI.show(id!)
    setCollection(results.data.recipeCollection)
    setCollectionName(results.data.recipeCollection.name)
  }, [id])

  useEffect(() => {
    // eslint-disable-next-line
    fetchCollection()
  }, [])

  if (!collection) return <Loader />

  const dietaryPreferenceData = [
    { label: 'Protein Preference', dietaryPreference: collection.proteinPreference },
    { label: 'Dietary Pattern Preference', dietaryPreference: collection.dietaryPatternPreference },
    ...collection.otherDietaryPreferences.map(dp => ({
      label: 'Other Dietary Preference',
      dietaryPreference: dp,
    })),
  ]

  const allDietaryPreferenceIds = collection.otherDietaryPreferences.map(dp => dp.id)
  if (collection.proteinPreference?.id) allDietaryPreferenceIds.push(collection.proteinPreference.id)
  if (collection.dietaryPatternPreference?.id)
    allDietaryPreferenceIds.push(collection.dietaryPatternPreference.id)

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeCollectionSeries.edit}>Recipe Collection Series</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeCollections.show(id!)}>{collection.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <EditableInput
        idPrefix="edit-collection-name"
        value={collectionName}
        onChange={async val => {
          setCollectionName(val)
        }}
        onSave={async val => {
          await RecipeCollectionsAPI.update(collection.id, { name: val })
          notify('Name saved successfully')
        }}
        onCancel={() => {
          setCollectionName(collection.name)
        }}
      />

      <div className="flex gap-4 items-center">
        <h2>Dietary Preferences</h2>
        <Button
          className="text-white"
          color="success"
          aria-label="Add dietary preference"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setModalType('add_dietary_preference')
          }}
        >
          <IoAddCircleOutline size={20} />
          Add Dietary Preference
        </Button>
      </div>

      <Table aria-label="Dietary Preferences" shadow="none">
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>TYPE</TableColumn>
          <TableColumn>ACTIONS</TableColumn>
        </TableHeader>

        <TableBody>
          {dietaryPreferenceData.map((dpData, index) => (
            <TableRow key={index}>
              <TableCell>{dpData.dietaryPreference?.id || 'N/A'}</TableCell>
              <TableCell>{dpData.dietaryPreference?.name || 'N/A'}</TableCell>
              <TableCell>{dpData.label}</TableCell>
              <TableCell>
                {dpData.dietaryPreference && (
                  <Button
                    isIconOnly
                    color="danger"
                    id={`delete-dietary-preference-${dpData.dietaryPreference.id}`}
                    onClick={async () => {
                      setModalType('confirm_remove_dietary_preference')
                      setDietaryPreferenceIdToRemove(dpData.dietaryPreference.id)
                    }}
                  >
                    <IoTrash size={20} />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="flex gap-4 items-center">
        <h2>Recipes</h2>
        <Button
          className="text-white"
          color="success"
          aria-label="Add recipe"
          style={{ marginLeft: 'auto' }}
          onClick={async () => {
            setModalType('add_recipe')
          }}
        >
          <IoAddCircleOutline size={20} />
          Add Recipe
        </Button>
      </div>

      <Table aria-label="Recipes" shadow="none">
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>CLASSIFICATION</TableColumn>
          <TableColumn>ACTIONS</TableColumn>
        </TableHeader>

        <TableBody>
          {collection.recipes.map((recipe, index) => (
            <TableRow key={index}>
              <TableCell>{recipe.id}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipes.show(recipe.id)}>{recipe.name}</NavLink>
              </TableCell>
              <TableCell>
                <Chip color="secondary" variant="flat">
                  {recipe.mealType}
                </Chip>
              </TableCell>
              <TableCell>
                <Button
                  isIconOnly
                  color="danger"
                  id={`delete-recipe-${recipe.id}`}
                  onClick={async () => {
                    setRecipeIdToRemove(recipe.id)
                    setModalType('confirm_remove_recipe')
                  }}
                >
                  <IoTrash size={20} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <FormLocalizationSection
        apiModule={RecipeCollectionsAPI}
        existingLocalizedTexts={collection.localizedTexts}
        localizableId={collection.id}
        onChange={async _ => {
          await fetchCollection()
        }}
        excludedFields={['summaryDescription']}
      />

      <AddRecipeModal
        open={modalType === 'add_recipe'}
        onClose={async () => {
          setModalType(null)
          await fetchCollection()
        }}
        onSelect={async (recipe: AdminRecipeSummary) => {
          await RecipeCollectionsAPI.addRecipe({ recipeCollectionId: collection.id, recipeId: recipe.id })
          await fetchCollection()
        }}
        addedRecipeIds={collection.recipes.map(recipe => recipe.id)}
      />

      <AddDietaryPreferenceModal
        open={modalType === 'add_dietary_preference'}
        onClose={async () => {
          setModalType(null)
          await fetchCollection()
        }}
        onSelect={async (dietaryPreference: DietaryPreferenceSummary) => {
          await RecipeCollectionsAPI.addDietaryPreference({
            recipeCollectionId: collection.id,
            dietaryPreferenceId: dietaryPreference.id,
          })
          await fetchCollection()
        }}
        addedDietaryPreferenceIds={allDietaryPreferenceIds}
      />

      <ConfirmModal
        open={modalType === 'confirm_remove_recipe'}
        onConfirm={async () => {
          await RecipeCollectionsAPI.removeRecipe({
            recipeCollectionId: collection.id,
            recipeId: recipeIdToRemove!,
          })
          setModalType(null)
          setRecipeIdToRemove(null)
          await fetchCollection()
        }}
        onClose={() => {
          setModalType(null)
          setRecipeIdToRemove(null)
        }}
        header={`Remove ${collection.recipes.find(recipe => recipe.id === recipeIdToRemove!)?.name}?`}
      >
        Are you sure you want to remove{' '}
        {collection.recipes.find(recipe => recipe.id === recipeIdToRemove!)?.name} from this recipe
        collection?
      </ConfirmModal>

      <ConfirmModal
        open={modalType === 'confirm_remove_dietary_preference'}
        onConfirm={async () => {
          await RecipeCollectionsAPI.removeDietaryPreference({
            recipeCollectionId: collection.id,
            dietaryPreferenceId: dietaryPreferenceIdToRemove!,
          })
          await fetchCollection()

          setModalType(null)
          setDietaryPreferenceIdToRemove(null)
          await fetchCollection()
        }}
        onClose={() => {
          setModalType(null)
          setDietaryPreferenceIdToRemove(null)
        }}
        header={`Remove ${dietaryPreferenceData.find(
          dp => dp?.dietaryPreference?.id === dietaryPreferenceIdToRemove!
        )?.dietaryPreference?.name}?`}
      >
        Are you sure you want to remove{' '}
        {
          dietaryPreferenceData.find(dp => dp?.dietaryPreference?.id === dietaryPreferenceIdToRemove!)
            ?.dietaryPreference?.name
        }{' '}
        from this recipe collection?
      </ConfirmModal>
    </div>
  )
}
