import { ImageFormat } from '../components/ui/editable-image'
import routes from '../config/routes'
import { api } from './common'
import { ImageContentType } from './images'
import { RecipeVariantStepSummary } from './recipeVariantSteps'
import { ProteinTypesEnum, SecondaryProteinTypesEnum } from './schema'

export default class RecipeVariantsAPI {
  static show(id: string) {
    return api.get(routes.api.recipeVariants.show(id))
  }

  static create(attrs: RecipeVariantParams) {
    return api.post(routes.api.recipeVariants.create, attrs)
  }

  static update(id: string, attrs: RecipeVariantParams) {
    return api.patch(routes.api.recipeVariants.update(id), attrs)
  }

  static updateImage(id: string, contentType: ImageContentType) {
    return api.patch(routes.api.recipeVariants.updateImage(id), { contentType })
  }

  static addIngredient(id: string, attrs: { ingredientId: string }) {
    return api.post(routes.api.recipeVariants.ingredients.create(id), attrs)
  }

  static removeIngredient(id: string, attrs: { ingredientId: string }) {
    return api.delete(routes.api.recipeVariants.ingredients.destroy(id, attrs.ingredientId))
  }

  static addStep(id: string, attrs: Partial<RecipeVariantStepSummary>) {
    return api.post(routes.api.recipeVariants.steps.create(id), attrs)
  }

  static addDietaryPreference(id: string, dietaryPreferenceId: string) {
    return api.post(routes.api.recipeVariants.dietaryPreferences.create(id), { dietaryPreferenceId })
  }

  static addAppliance(id: string, applianceId: string) {
    return api.post(routes.api.recipeVariants.appliances.create(id), { applianceId })
  }

  static addExclusiveToDietaryPreference(id: string, dietaryPreferenceId: string) {
    return api.post(routes.api.recipeVariants.exclusiveToDietaryPreferences.create(id), {
      dietaryPreferenceId,
    })
  }
}

export interface RecipeVariantSummary {
  id: string
  name: string
  baseVariant: boolean
  recipeId: string
}

export interface RecipeVariantParams {
  name?: string
  imageCreditName?: string | null
  imageCreditUrl?: string | null
  imageCreditDetails?: string | null
  proteinType?: ProteinTypesEnum | null
  secondaryProteinType?: SecondaryProteinTypesEnum | null
  baseVariant?: boolean
  recipeId?: string
  variantDefiningDietaryPreferenceId?: string | null
  recipeVariantCookingTipId?: string | null
  published?: boolean
}

export interface RecipeVariantNutritionInfo {}

export interface NutrientData {
  nutrient: Nutrient
  label: string
  value: ValueAndUnit
}

export interface RecipeVariantNutritionInfo {
  familyMemberName?: string
  macros: NutrientData[]
  nutrition: NutrientData[]
}

export type NutrientWithUnits =
  | 'addedSugar'
  | 'calcium'
  | 'carbs'
  | 'fat'
  | 'fiber'
  | 'iron'
  | 'potassium'
  | 'protein'
  | 'saturatedFat'
  | 'sodium'
  | 'sugar'
  | 'vitaminD'
export type NutrientWithoutUnits = 'calories'
export type Nutrient = NutrientWithUnits | NutrientWithoutUnits

export interface ValueAndUnit {
  label: string | null
  value: number | null
}
