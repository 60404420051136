const baseUrl = () => {
  if (process.env.REACT_APP_API_HOST) return process.env.REACT_APP_API_HOST
  switch (process.env.REACT_APP_PSYCHIC_ENV) {
    case 'production':
      return 'https://admin.meals-dev.wellos.com'
    case 'test':
      return 'http://localhost:7779'
    default:
      return 'http://localhost:7797'
  }
}

const routes = {
  baseURL: baseUrl(),

  app: {
    home: '/',
    login: '/login',
    logout: '/logout',
    dummyAuth: '/dummy-auth',
    ingredients: {
      index: '/ingredients',
      new: '/ingredients/new',
      show: (id: string) => `/ingredients/${id}`,
      edit: (id: string) => `/ingredients/${id}/edit`,
    },
    ingredientExclusionCategories: {
      index: '/ingredient-exclusion-categories',
      new: '/ingredient-exclusion-categories/new',
      show: (id: string) => `/ingredient-exclusion-categories/${id}`,
      edit: (id: string) => `/ingredient-exclusion-categories/${id}/edit`,
    },
    recipeBrowseCategories: {
      index: '/recipe-browse-categories',
      show: (id: string) => `/recipe-browse-categories/${id}`,
      edit: (id: string) => `/recipe-browse-categories/${id}/edit`,
    },
    recipes: {
      index: '/recipes',
      show: (id: string) => `/recipes/${id}`,
      edit: (id: string) => `/recipes/${id}/edit`,
      bulkUpload: '/recipes/bulk-upload',
      recipeVariants: {
        new: (id: string) => `/recipes/${id}/recipe-variants/new`,
        show: (id: string) => `/recipe-variants/${id}`,
        edit: (id: string) => `/recipe-variants/${id}/edit`,
      },
    },
    recipeVariantCookingTips: {
      index: '/recipe-variant-cooking-tips',
      show: (id: string) => `/recipe-variant-cooking-tips/${id}`,
      edit: (id: string) => `/recipe-variant-cooking-tips/${id}/edit`,
    },
    recipeVariantStepCookingTips: {
      index: '/recipe-variant-step-cooking-tips',
      show: (id: string) => `/recipe-variant-step-cooking-tips/${id}`,
      edit: (id: string) => `/recipe-variant-step-cooking-tips/${id}/edit`,
    },
    recipeCollectionSeries: {
      edit: '/recipe-collection-series/edit',
    },
    recipeCollections: {
      show: (id: string) => `/recipe-collections/${id}`,
    },
    system: {
      index: '/system',
    },
  },

  api: {
    appliances: {
      index: '/admin/appliances',
    },
    dietaryPreferences: {
      index: '/admin/dietary-preferences',
    },
    ingredients: {
      index: '/admin/ingredients',
      create: '/admin/ingredients',
      show: (id: string) => `/admin/ingredients/${id}`,
      update: (id: string) => `/admin/ingredients/${id}`,
    },
    ingredientExclusionCategories: {
      index: '/admin/ingredient-exclusion-categories',
      create: '/admin/ingredient-exclusion-categories',
      show: (id: string) => `/admin/ingredient-exclusion-categories/${id}`,
      update: (id: string) => `/admin/ingredient-exclusion-categories/${id}`,
      ingredients: {
        create: (id: string) => `/admin/ingredient-exclusion-categories/${id}/ingredients`,
      },
    },
    ingredientExclusionCategoryIngredients: {
      destroy: (id: string) => `/admin/ingredient-exclusion-category-ingredients/${id}`,
    },
    localizedTexts: {
      update: (id: string) => `/admin/localized-texts/${id}`,
      delete: (id: string) => `/admin/localized-texts/${id}`,
    },
    recipes: {
      index: '/admin/recipes',
      show: (id: string) => `/admin/recipes/${id}`,
      update: (id: string) => `/admin/recipes/${id}`,
      bulkUpload: '/admin/recipes/bulk-upload',
    },
    recipeBrowseCategories: {
      index: '/admin/recipe-browse-categories',
      show: (id: string) => `/admin/recipe-browse-categories/${id}`,
      edit: (id: string) => `/admin/recipe-browse-categories/${id}`,
    },
    recipeCollectionSeries: {
      index: '/admin/recipe-collection-series',
    },
    recipeCollections: {
      create: () => '/admin/recipe-collections',
      show: (id: string) => `/admin/recipe-collections/${id}`,
      update: (id: string) => `/admin/recipe-collections/${id}`,
      addRecipe: (id: string) => `/admin/recipe-collections/${id}/recipes`,
      removeRecipe: (id: string, recipeId: string) => `/admin/recipe-collections/${id}/recipes/${recipeId}`,
      addDietaryPreference: (id: string) => `/admin/recipe-collections/${id}/dietary-preferences`,
      removeDietaryPreference: (id: string, dietaryPreferenceId: string) =>
        `/admin/recipe-collections/${id}/dietary-preferences/${dietaryPreferenceId}`,
      localizedTexts: {
        create: (id: string) => `/admin/recipe-collections/${id}/localized-texts`,
      },
    },
    recipeVariants: {
      create: '/admin/recipe-variants',
      show: (id: string) => `/admin/recipe-variants/${id}`,
      update: (id: string) => `/admin/recipe-variants/${id}`,
      updateImage: (id: string) => `/admin/recipe-variants/${id}/update-image`,
      appliances: {
        create: (id: string) => `/admin/recipe-variants/${id}/appliances`,
      },
      dietaryPreferences: {
        create: (id: string) => `/admin/recipe-variants/${id}/dietary-preferences`,
      },
      exclusiveToDietaryPreferences: {
        create: (id: string) => `/admin/recipe-variants/${id}/exclusive-to-dietary-preferences`,
      },
      ingredients: {
        create: (id: string) => `/admin/recipe-variants/${id}/ingredients`,
        destroy: (id: string, ingredientId: string) =>
          `/admin/recipe-variants/${id}/ingredients/${ingredientId}`,
      },
      steps: {
        create: (id: string) => `/admin/recipe-variants/${id}/steps`,
      },
    },
    recipeVariantAppliances: {
      destroy: (id: string) => `/admin/recipe-variant-appliances/${id}`,
    },
    recipeVariantCookingTips: {
      index: '/admin/recipe-variant-cooking-tips',
      create: '/admin/recipe-variant-cooking-tips',
      show: (id: string) => `/admin/recipe-variant-cooking-tips/${id}`,
      update: (id: string) => `/admin/recipe-variant-cooking-tips/${id}`,
      destroy: (id: string) => `/admin/recipe-variant-cooking-tips/${id}`,
    },
    recipeVariantStepCookingTips: {
      index: '/admin/recipe-variant-step-cooking-tips',
      create: '/admin/recipe-variant-step-cooking-tips',
      show: (id: string) => `/admin/recipe-variant-step-cooking-tips/${id}`,
      update: (id: string) => `/admin/recipe-variant-step-cooking-tips/${id}`,
      destroy: (id: string) => `/admin/recipe-variant-step-cooking-tips/${id}`,
    },
    recipeVariantDietaryPreferences: {
      destroy: (id: string) => `/admin/recipe-variant-dietary-preferences/${id}`,
    },
    recipeVariantExclusiveToDietaryPreferences: {
      destroy: (id: string) => `/admin/recipe-variant-exclusive-to-dietary-preferences/${id}`,
    },
    recipeVariantIngredients: {
      update: (id: string) => `/admin/recipe-variant-ingredients/${id}`,
      destroy: (id: string) => `/admin/recipe-variant-ingredients/${id}`,
    },
    recipeVariantIngredientOverrideOptions: {
      create: (id: string) => `/admin/recipe-variants/${id}/ingredient-override-options`,
      destroy: (recipeVariantIngredientOverrideOptionId: string) =>
        `/admin/ingredient-override-options/${recipeVariantIngredientOverrideOptionId}`,
    },
    recipeVariantSteps: {
      update: (id: string) => `/admin/recipe-variant-steps/${id}`,
      destroy: (id: string) => `/admin/recipe-variant-steps/${id}`,
    },
    system: {
      centralBullboard: '/admin/meals-bull-login',
    },
  },
}

export default routes
