import routes from '../config/routes'
import { api } from './common'
import { RecipeCollectionSummary } from './recipeCollections'

export default class RecipeCollectionSeriesAPI {
  static index() {
    return api.get(routes.api.recipeCollectionSeries.index)
  }
}

export interface RecipeCollectionSeriesSummary {
  id: string
  recipeCollections: RecipeCollectionSummary[]
  enhancedDisplay: boolean
  focusedRecipeCollectionId: string
}
