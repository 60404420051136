import { Button, Input, Progress } from '@nextui-org/react'
import { useState } from 'react'
import ImageDisplay, { ImageSize } from './image-display'

export default function EditableImage({
  id,
  src,
  size,
  onChange,
  progress,
  allowedFormats = ['jpg', 'jpeg'],
}: {
  id: string
  src: string | null | undefined
  onChange: (img: File) => void
  progress?: number
  size?: ImageSize
  allowedFormats?: ImageFormat[]
}) {
  const [displaySrc, setDisplaySrc] = useState<string | null>(src || null)
  const [file, setFile] = useState<File | null>(null)
  const [saveBtn, setSaveBtn] = useState(false)
  const displayId = `${id}-display`

  return (
    <div id={id} style={{ width: 500 }}>
      <ImageDisplay id={displayId} src={displaySrc} size={size} />

      <div style={{ position: 'relative', textAlign: 'right', width: 500 }}>
        {progress ? (
          <div style={{ marginTop: 20 }}>
            <Progress value={progress} color="success" />
          </div>
        ) : null}
        <div style={{ width: 99, display: 'inline-block', position: 'relative', marginTop: 20 }}>
          <Button>edit image</Button>
          <input
            id={`${id}-file-input`}
            type="file"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              opacity: 0,
              zIndex: 2,
              width: 99,
              fontSize: 0,
              height: 40,
              cursor: 'pointer',
            }}
            accept={allowedFormats.map(fmt => `.${fmt}`).join(', ')}
            onChange={e => {
              const file = e.target.files?.[0]
              setFile(file || null)

              if (FileReader && file) {
                setSaveBtn(true)

                var fr = new FileReader()
                fr.onload = function () {
                  const base64Url = fr.result?.toString()
                  console.log(base64Url)
                  setDisplaySrc(base64Url!)
                }
                fr.readAsDataURL(file)
              }
            }}
          />
        </div>
        {saveBtn && file ? (
          <Button
            color="success"
            style={{ marginLeft: 20, color: 'white', marginTop: 20 }}
            onPress={() => {
              if (file) {
                setSaveBtn(false)
                onChange(file)
              }
            }}
          >
            save image
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export type ImageFormat = 'jpg' | 'jpeg' | 'png'
