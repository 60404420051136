import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import routes from '../../config/routes'
import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  AdminIngredientExclusionCategoryDetail,
  AdminIngredientExclusionCategoryIngredientSummary,
} from '../../api/schema'
import IngredientExclusionCategoriesAPI from '../../api/ingredientExclusionCategories'
import Loader from '../../components/loader'
import AddIngredientModal from '../../components/modals/add-ingredient-modal'
import ConfirmModal from '../../components/modals/confirm'
import IngredientExclusionCategoryIngredientsAPI from '../../api/ingredientExclusionCategoryIngredients'

type IngredientExclusionCategoriesShowModalType = 'add_ingredients' | 'confirm_remove_ingredient' | null

export default function IngredientExclusionCategoriesShowPage() {
  const [ingredientExclusionCategory, setIngredientExclusionCategory] =
    useState<AdminIngredientExclusionCategoryDetail | null>(null)
  const [modalType, setModalType] = useState<IngredientExclusionCategoriesShowModalType>(null)
  const [ingredientExclusionCategoryToRemove, setIngredientExclusionCategoryToRemove] =
    useState<AdminIngredientExclusionCategoryIngredientSummary | null>(null)
  const { id } = useParams()

  const fetchIngredientExclusionCategory = useCallback(async () => {
    const results = await IngredientExclusionCategoriesAPI.show(id!)
    setIngredientExclusionCategory(results.data)
  }, [id])

  useEffect(() => {
    // eslint-disable-next-line
    fetchIngredientExclusionCategory()
  }, [id])

  if (!ingredientExclusionCategory) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.index}>
            Ingredient Exclusion Categories
          </NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.show(id!)}>
            {ingredientExclusionCategory.name}
          </NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>{ingredientExclusionCategory.name}</h1>
        <Button
          as={Link}
          to={routes.app.ingredientExclusionCategories.edit(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoPencil size={20} />
          Edit
        </Button>
      </div>

      <div className="flex gap-4 items-center">
        <h2>Ingredients</h2>
        <Button
          as={Link}
          id="add-ingredient-to-ingredient-exclusion-category"
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
          onPress={() => {
            setModalType('add_ingredients')
          }}
        >
          <IoAdd size={20} />
          Add Ingredient
        </Button>
      </div>

      <Table
        aria-label="Ingredients"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
          <TableColumn>ACTIONS</TableColumn>
        </TableHeader>

        <TableBody>
          {ingredientExclusionCategory.ingredientExclusionCategoryIngredients.map(
            (ingredientExclusionCategoryIngredient, index) => (
              <TableRow key={index}>
                <TableCell>{ingredientExclusionCategoryIngredient.ingredient.id}</TableCell>
                <TableCell>{ingredientExclusionCategoryIngredient.ingredient.name}</TableCell>
                <TableCell>
                  <Button
                    isIconOnly
                    id={`delete-ingredient-exclusion-category-ingredient-${ingredientExclusionCategoryIngredient.id}`}
                    as={Link}
                    className="text-white"
                    color="danger"
                    aria-label="remove ingredient"
                    style={{ marginLeft: 'auto' }}
                    onPress={() => {
                      setModalType('confirm_remove_ingredient')
                      setIngredientExclusionCategoryToRemove(ingredientExclusionCategoryIngredient)
                    }}
                  >
                    <IoTrash size={20} />
                  </Button>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <AddIngredientModal
        scope="non-substitute-ingredients"
        open={modalType === 'add_ingredients'}
        onClose={() => setModalType(null)}
        onSelect={async ingredient => {
          await IngredientExclusionCategoriesAPI.addIngredient(ingredientExclusionCategory.id, ingredient.id)
          await fetchIngredientExclusionCategory()
        }}
        addedIngredientIds={ingredientExclusionCategory.ingredientExclusionCategoryIngredients.map(
          ieci => ieci.ingredientId
        )}
      />

      <ConfirmModal
        open={modalType === 'confirm_remove_ingredient'}
        onConfirm={async () => {
          await IngredientExclusionCategoryIngredientsAPI.destroy(ingredientExclusionCategoryToRemove!.id)
          setModalType(null)
          setIngredientExclusionCategoryToRemove(null)
          await fetchIngredientExclusionCategory()
        }}
        onClose={() => {
          setModalType(null)
          setIngredientExclusionCategoryToRemove(null)
        }}
        header={`Remove ${ingredientExclusionCategoryToRemove?.ingredient?.name}?`}
      >
        Are you sure you want to remove {ingredientExclusionCategoryToRemove?.ingredient?.name} from the{' '}
        {ingredientExclusionCategory.name} ingredient exclusion category?
      </ConfirmModal>
    </div>
  )
}
