import { useState } from 'react'
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from '@nextui-org/react'
import { NavLink, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import RecipeSearch from '../../components/resources/recipe/search'
import { IoAddCircle } from 'react-icons/io5'
import { AdminRecipe } from '../../api/schema'
import useWindowSize from '../../hooks/useWindowSize'

export default function RecipesIndexPage() {
  const windowSize = useWindowSize()
  const navigate = useNavigate()
  const [recipes, setRecipes] = useState<AdminRecipe[]>([])

  return (
    <div>
      <div className="flex gap-4 items-center">
        <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
          <BreadcrumbItem>
            <NavLink to={routes.app.home}>Home</NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
          </BreadcrumbItem>
        </Breadcrumbs>

        <Button
          color="success"
          startContent={<IoAddCircle size={20} />}
          style={{ color: 'white', marginLeft: 'auto' }}
          elementType={NavLink}
          onPress={() => {
            navigate(routes.app.recipes.bulkUpload)
          }}
        >
          Bulk upload recipes
        </Button>
      </div>

      <RecipeSearch<AdminRecipe> onChange={recipes => setRecipes(recipes)}>
        <Table
          aria-label="Recipes"
          classNames={{
            base: 'table-base search',
          }}
          shadow="none"
        >
          <TableHeader>
            <TableColumn>ID</TableColumn>
            <TableColumn>NAME</TableColumn>
          </TableHeader>

          <TableBody
            style={{
              maxHeight: 'calc(100vh - 400px)',
            }}
          >
            {recipes.map((recipe, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: 40 }}>
                  <NavLink
                    to={routes.app.recipes.show(recipe.id)}
                    style={{ position: 'relative', top: '-4px' }}
                  >
                    {recipe.id}
                  </NavLink>
                </TableCell>
                <TableCell style={{ verticalAlign: 'center' }}>
                  <div
                    style={{
                      background: `url(${
                        recipe.imageUrl ? `${recipe.imageUrl}?w=40&h=40` : '/img/logo-isolated.svg'
                      })`,
                      backgroundSize: recipe.imageUrl ? 'cover' : 'contain',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: recipe.imageUrl ? 2 : 0,
                      width: 20,
                      height: 20,
                      display: 'inline-block',
                    }}
                  ></div>
                  <NavLink
                    to={routes.app.recipes.show(recipe.id)}
                    style={{ marginLeft: 10, display: 'inline-block', verticalAlign: 'top' }}
                  >
                    {recipe.name}
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </RecipeSearch>
    </div>
  )
}
