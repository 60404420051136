import { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from '@nextui-org/react'
import RecipeCollectionsAPI from '../../api/recipeCollections'

export default function NewRecipeCollectionModal({
  recipeCollectionSeriesId,
  open,
  onClose,
  onSave,
}: {
  recipeCollectionSeriesId: string
  open: boolean
  onClose: () => void
  onSave: (recipeCollectionId: string) => void
}) {
  const [name, setName] = useState('')

  return (
    <Modal size="md" placement="bottom-center" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Create a new recipe collection</ModalHeader>
            <ModalBody>
              <Input
                type="text"
                label="Name"
                name="name"
                autoComplete="off"
                id="recipe-collection-name-input"
                labelPlacement="outside"
                onChange={async e => {
                  setName(e.target.value)
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  const results = await RecipeCollectionsAPI.create({
                    recipeCollectionSeriesId,
                    name,
                  })
                  const id = results.data
                  onSave(id)
                }}
              >
                Create
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
