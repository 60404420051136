import { Button, Input } from '@nextui-org/react'
import { useState } from 'react'
import FormGroup from '../../forms/form-group'
import { AdminRecipeVariantCookingTipSummary } from '../../../api/schema'
import RecipeVariantCookingTipsAPI from '../../../api/recipeVariantCookingTips'

export default function RecipeVariantCookingTipForm({
  recipeVariantCookingTip,
  onSave,
}: {
  recipeVariantCookingTip: AdminRecipeVariantCookingTipSummary
  onSave: () => void
}) {
  const [name, setName] = useState(recipeVariantCookingTip.name)
  const [body, setBody] = useState(recipeVariantCookingTip.body)

  return (
    <div style={{ marginTop: 100 }}>
      <FormGroup>
        <Input
          type="text"
          label="Name"
          name="name"
          autoComplete="off"
          id="edit-recipe-variant-cooking-tip-name-input"
          labelPlacement="outside"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="text"
          label="Body"
          name="body"
          autoComplete="off"
          id="edit-recipe-variant-cooking-tip-body-input"
          labelPlacement="outside"
          value={body}
          onChange={async e => {
            setBody(e.target.value)
          }}
        />
      </FormGroup>

      <Button
        onPress={async () => {
          await RecipeVariantCookingTipsAPI.update(recipeVariantCookingTip.id, {
            name,
            body,
          })
          onSave()
        }}
      >
        Save
      </Button>
    </div>
  )
}
