import React, { useState } from 'react'
import { Button, Switch, Input, Select, SelectItem, RadioGroup, Radio, Textarea } from '@nextui-org/react'
import RecipesAPI, { cuisines, dishTypes, mealTypes, subcuisines } from '../../../api/recipes'
import { AdminRecipe, CuisinesEnum, DishTypesEnum, MealTypesEnum, SubCuisinesEnum } from '../../../api/schema'
import FormGroup from '../../forms/form-group'
import RecipeSeasonalityForm from './form-sections/seasonality'

export default function RecipeForm({ recipe, onSave }: { recipe: AdminRecipe; onSave: () => void }) {
  const [name, setName] = useState(recipe.name)
  const [cuisine, setCuisine] = useState<CuisinesEnum | null>(recipe.cuisine)
  const [subCuisines, setSubCuisines] = useState<SubCuisinesEnum[]>(recipe.subCuisines)
  const [mealType, setMealType] = useState<MealTypesEnum | null>(recipe.mealType)
  const [dishType, setDishType] = useState<DishTypesEnum | null>(recipe.dishType)
  const [servings, setServings] = useState(recipe.servings)
  const [doubleable, setDoubleable] = useState(recipe.doubleable)
  const [prePrepared, setPrePrepared] = useState(recipe.prePrepared)
  const [exotic, setExotic] = useState(recipe.exotic)
  const [introduction, setIntroduction] = useState(recipe.introduction)
  const [fixedPortionSize, setFixedPortionSize] = useState(recipe.fixedPortionSize)
  const [hidden, setHidden] = useState(recipe.hidden)

  return (
    <div style={{ marginTop: 100 }}>
      <FormGroup>
        <Input
          type="text"
          label="Name"
          name="name"
          autoComplete="off"
          id="edit-recipe-name-input"
          labelPlacement="outside"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <Switch
          id="edit-recipe-hidden-toggle-switch"
          className="toggle-switch"
          aria-label="Automatic updates"
          isSelected={hidden}
          onValueChange={setHidden}
        >
          Hidden
        </Switch>
      </FormGroup>

      <FormGroup>
        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id="edit-recipe-cuisine-select"
          label="cuisine"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'None') setCuisine(null)
            else setCuisine(e.target.value as any)
          }}
          selectedKeys={cuisine ? [cuisine as string] : []}
        >
          {['none', ...cuisines].map(cuisine =>
            cuisine === 'none' ? (
              <SelectItem key="None" value="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={cuisine as any} textValue={cuisine}>
                {cuisine}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          selectionMode="multiple"
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id="edit-recipe-subcuisines-select"
          label="subcuisines"
          style={{ marginRight: 10 }}
          onChange={e => {
            const values = e.target.value.split(',').filter(val => !!val)
            if (values.includes('None')) setSubCuisines([] as any)
            else setSubCuisines(values as any)
          }}
          selectedKeys={subCuisines}
        >
          {['none', ...subcuisines].map(subcuisine =>
            subcuisine === 'none' ? (
              <SelectItem key="None" textValue="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={subcuisine as any} textValue={subcuisine}>
                {subcuisine}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id="edit-recipe-meal-type-select"
          label="meal type"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'None') setMealType(null)
            else setMealType(e.target.value as any)
          }}
          selectedKeys={[mealType as any]}
        >
          {['none', ...mealTypes].map(mealType =>
            mealType === 'none' ? (
              <SelectItem key="None" value="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mealType as any} textValue={mealType}>
                {mealType}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id="edit-recipe-dish-type-select"
          label="dish type"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'None') setDishType(null)
            else setDishType(e.target.value as any)
          }}
          selectedKeys={[dishType as any]}
        >
          {['none', ...dishTypes].map(dishType =>
            dishType === 'none' ? (
              <SelectItem key="None" value="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={dishType as any} textValue={dishType}>
                {dishType}
              </SelectItem>
            )
          )}
        </Select>

        <Input
          type="number"
          label="Servings"
          name="servings"
          autoComplete="off"
          id="edit-recipe-servings-input"
          className="input-sm"
          value={servings.toString()}
          onChange={async e => {
            setServings(parseInt(e.target.value))
          }}
        />
      </FormGroup>

      <FormGroup>
        <Textarea
          label="Introduction"
          name="introduction"
          autoComplete="off"
          id="edit-recipe-introduction-input"
          value={introduction}
          onChange={async e => {
            setIntroduction(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-recipe-doubleable-radio-group"
          label="Doubleable"
          onChange={e => {
            setDoubleable(e.target.value === 'true')
          }}
          value={doubleable ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-recipe-pre-prepared-radio-group"
          label="Pre-prepared"
          onChange={e => {
            setPrePrepared(e.target.value === 'true')
          }}
          value={prePrepared ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-recipe-exotic-radio-group"
          label="Exotic"
          onChange={e => {
            setExotic(e.target.value === 'true')
          }}
          value={exotic ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-recipe-fixed-portion-size-radio-group"
          label="Fixed portion size"
          onChange={e => {
            setFixedPortionSize(e.target.value === 'true')
          }}
          value={fixedPortionSize ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await RecipesAPI.update(recipe.id, {
              name,
              cuisine,
              subCuisines,
              mealType,
              dishType,
              servings,
              fixedPortionSize,
              doubleable,
              prePrepared,
              exotic,
              introduction,
              hidden,
            })
            onSave()
          }}
        >
          Save basic info
        </Button>
      </div>

      <RecipeSeasonalityForm
        recipe={recipe}
        onSave={() => {
          onSave()
        }}
      />
    </div>
  )
}
