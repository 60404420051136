import { DateTime } from 'luxon'
import interpretIngredientSeasonalityRange from '../../helpers/interpretIngredientSeasonalityRange'
import FormGroup from './form-group'
import DateInput from './date-input'
import { useState } from 'react'
import { Chip } from '@nextui-org/react'

export default function SeasonalityInput({
  id,
  label,
  startRange,
  endRange,
  onStartChange,
  onEndChange,
}: {
  id: string
  label: string
  startRange: string
  endRange: string
  onStartChange: (str: string) => void
  onEndChange: (str: string) => void
}) {
  const [startYear, setStartYear] = useState(new Date().getFullYear())
  const { startDate, endDate, maxDate, minDate, startMonth, startDay, endMonth, endDay } =
    interpretIngredientSeasonalityRange(startRange, endRange, startYear)

  return (
    <div id={id}>
      <label>{label}</label>
      <div>
        from{' '}
        <Chip style={{ marginRight: 10, marginLeft: 5 }}>
          {startMonth}-{startDay}
        </Chip>
        to{' '}
        <Chip style={{ marginRight: 10, marginLeft: 5 }}>
          {endMonth}-{endDay}
        </Chip>
      </div>
      <FormGroup className="ingredient-seasonality-input">
        <DateInput
          id={id + '-start-date-selector'}
          value={startDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={newStartDate => {
            const luxonDate = DateTime.fromJSDate(newStartDate!)
            setStartYear(luxonDate.year)
            onStartChange(`${luxonDate.month}-${luxonDate.day}`)
          }}
        />
        <DateInput
          id={id + '-end-date-selector'}
          value={endDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={newEndDate => {
            const luxonDate = DateTime.fromJSDate(newEndDate!)
            if (luxonDate.year > startDate.year || luxonDate < startDate) {
              onEndChange(`${luxonDate.month + 12}-${luxonDate.day}`)
            } else {
              onEndChange(`${luxonDate.month}-${luxonDate.day}`)
            }
          }}
        />
      </FormGroup>
    </div>
  )
}
