import { ReactNode } from 'react'

export default function Banner({
  type,
  children,
  heading,
}: {
  type: BannerType
  children: ReactNode
  heading?: ReactNode
}) {
  return (
    <div
      style={{
        color: bannerColor(type),
        padding: 40,
        paddingTop: 20,
        background: bannerBgColor(type),
        borderRadius: 10,
      }}
    >
      <h2>{heading || bannerTitle(type)}</h2>

      {children}
    </div>
  )
}

function bannerTitle(type: BannerType) {
  switch (type) {
    case 'success':
      return 'Success'
    case 'error':
      return 'Error'
  }
}

function bannerColor(type: BannerType) {
  switch (type) {
    case 'success':
      return 'green'
    case 'error':
      return 'red'
  }
}

function bannerBgColor(type: BannerType) {
  switch (type) {
    case 'success':
      return 'rgb(213, 255, 214)'
    case 'error':
      return 'rgb(255, 213, 213)'
  }
}

type BannerType = 'error' | 'success'
