import React, { ReactNode } from 'react'
import Sidebar from './sidebar'
import AppContent from './app-content'

export default function AppShell(props: { children: ReactNode }) {
  return (
    <div>
      <Sidebar />
      <AppContent>{props.children}</AppContent>
    </div>
  )
}
