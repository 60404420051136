import { Button, Input, Textarea } from '@nextui-org/react'
import { ReactNode, useState } from 'react'
import { IoPencil } from 'react-icons/io5'

export default function EditableInput({
  idPrefix,
  value,
  onChange,
  onSave,
  onCancel,
  renderAs = 'h1',
  inputType = 'text',
}: {
  idPrefix: string
  value: string
  onChange: (newValue: string) => void
  onSave: (newValue: string) => void
  onCancel: () => void
  renderAs?: 'h1' | 'div'
  inputType?: 'text' | 'textarea'
}) {
  const [editMode, setEditMode] = useState(false)

  return (
    <div>
      {editMode ? (
        <>
          <div
            style={{
              background: 'transparent',
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            onClick={() => {
              setEditMode(false)
              onCancel()
            }}
          ></div>
          <EditableTextOrTextareaInput
            inputType={inputType}
            idPrefix={idPrefix}
            value={value}
            onChange={onChange}
            onSave={onSave}
            setEditMode={setEditMode}
          />
        </>
      ) : (
        <>
          <EditableInputDisplay renderAs={renderAs}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div style={{ width: 50, display: 'inline-flex', marginTop: 15 }}>
                <IoPencil
                  id={`${idPrefix}-edit-btn`}
                  style={{ display: 'inline-block', marginRight: 20, fontSize: '20px' }}
                  onClick={() => {
                    setEditMode(true)
                  }}
                />
              </div>
              <div style={{ width: 'calc(100% - 50px', display: 'inline-flex', verticalAlign: 'top' }}>
                {value}
              </div>
            </div>
          </EditableInputDisplay>
        </>
      )}
    </div>
  )
}

function EditableInputDisplay({ renderAs, children }: { renderAs: 'h1' | 'div'; children: ReactNode }) {
  return renderAs === 'div' ? <div>{children}</div> : <h1>{children}</h1>
}

function EditableTextOrTextareaInput({
  inputType,
  idPrefix,
  value,
  onChange,
  onSave,
  setEditMode,
}: {
  inputType: 'text' | 'textarea'
  idPrefix: string
  value: string
  onChange: (val: string) => void
  onSave: (val: string) => void
  setEditMode: (val: boolean) => void
}) {
  switch (inputType) {
    case 'text':
      return (
        <Input
          id={`${idPrefix}-input`}
          value={value}
          fullWidth={false}
          classNames={{
            base: 'input-with-btn',
          }}
          onChange={e => {
            onChange(e.target.value)
          }}
          endContent={
            <Button
              id={`${idPrefix}-save-btn`}
              style={{
                display: 'inline-flex',
                verticalAlign: 'middle',
              }}
              onPress={() => {
                setEditMode(false)
                onSave(value)
              }}
            >
              save
            </Button>
          }
        />
      )

    case 'textarea':
      return (
        <Textarea
          id={`${idPrefix}-input`}
          value={value}
          fullWidth={false}
          classNames={{
            base: 'textarea-with-btn',
          }}
          onChange={e => {
            onChange(e.target.value)
          }}
          endContent={
            <Button
              id={`${idPrefix}-save-btn`}
              style={{
                display: 'inline-flex',
                verticalAlign: 'middle',
              }}
              onPress={() => {
                setEditMode(false)
                onSave(value)
              }}
            >
              save
            </Button>
          }
        />
      )
  }
}
