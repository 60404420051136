import { useCallback, useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { IoAddCircleOutline, IoCheckmark } from 'react-icons/io5'
import DietaryPreferencesAPI, { DietaryPreferenceSummary } from '../../api/dietaryPreferences'
import useMountEffect from '../../hooks/useMountEffect'

export default function AddDietaryPreferenceModal({
  open,
  onClose,
  onSelect,
  addedDietaryPreferenceIds = [],
}: {
  open: boolean
  onClose: () => void
  onSelect: (dietaryPreference: DietaryPreferenceSummary) => void
  addedDietaryPreferenceIds?: string[]
}) {
  const [dietaryPreferences, setDietaryPreferences] = useState<DietaryPreferenceSummary[]>([])
  const fetchDietaryPreferences = useCallback(async () => {
    const results = await DietaryPreferencesAPI.index()
    setDietaryPreferences(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchDietaryPreferences()
  }, [])

  return (
    <Modal size="3xl" placement="bottom-center" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Select a dietary preference</ModalHeader>
            <ModalBody>
              <div
                style={{
                  maxHeight: 'calc(100vh - 500px)',
                  overflowY: 'auto',
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                {dietaryPreferences.map((dietaryPreference, index) => (
                  <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                    <Button
                      disabled={addedDietaryPreferenceIds.includes(dietaryPreference.id)}
                      startContent={
                        addedDietaryPreferenceIds.includes(dietaryPreference.id) ? (
                          <IoCheckmark size={20} />
                        ) : (
                          <IoAddCircleOutline size={20} />
                        )
                      }
                      variant="bordered"
                      color={
                        addedDietaryPreferenceIds.includes(dietaryPreference.id) ? 'success' : 'secondary'
                      }
                      onClick={async () => {
                        onSelect(dietaryPreference)
                      }}
                    >
                      {dietaryPreference.name}
                    </Button>
                  </div>
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
