import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from '@nextui-org/react'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { IoStar } from 'react-icons/io5'
import { AdminRecipeVariantDetail } from '../../api/schema'

export default function PreviewRecipeVariantModal({
  recipeVariant,
  open,
  onClose,
}: {
  recipeVariant: AdminRecipeVariantDetail
  open: boolean
  onClose: () => void
}) {
  const [modalType, setModalType] = useState<PreviewRecipeVariantModalModalType>(null)

  const tags = [
    recipeVariant.mealType || recipeVariant.recipe.mealType,
    recipeVariant.recipe.cuisine,
    ...(recipeVariant.recipe.subCuisines || []),
  ].filter(tag => !!tag)

  return (
    <Modal
      size="md"
      placement="auto"
      isOpen={open}
      onClose={onClose}
      scrollBehavior="inside"
      style={{ backgroundColor: 'whitesmoke' }}
    >
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1" style={{ textAlign: 'center' }}>
              Preview {recipeVariant.name}
            </ModalHeader>
            <ModalBody>
              <div style={{ textAlign: 'center' }}>
                <div className="recipe-preview">
                  <div className="tag-container">
                    {tags.map(tag => (
                      <div className="tag" key={tag}>
                        {capitalize(tag.replace(/_/g, ' '))}
                      </div>
                    ))}
                  </div>

                  <div className="recipe-card">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${recipeVariant.imageUrl})` }}
                    ></div>
                    <div className="body">
                      <h3 className="title">{recipeVariant.name}</h3>

                      <div className="servings-and-ratings-container">
                        <div className="servings">{recipeVariant.recipe.servings} servings</div>
                        <div className="ratings">
                          {recipeVariant.ratingVotes ? (
                            <>
                              {recipeVariant.rating}/5 ({recipeVariant.ratingVotes}) <IoStar size={15} />
                            </>
                          ) : (
                            'No ratings yet'
                          )}
                        </div>
                      </div>

                      <div className="cook-times-container">
                        <div className="time">
                          <div className="label">PREP</div>
                          <div className="value">{recipeVariant.approximatePassiveMinutes} min</div>
                        </div>
                        <div className="time">
                          <div className="label">COOK</div>
                          <div className="value">{recipeVariant.approximateActiveMinutes} min</div>
                        </div>
                        <div className="time">
                          <div className="label">TOTAL</div>
                          <div className="value">{recipeVariant.approximateTotalMinutes} min</div>
                        </div>
                      </div>

                      <button className="add-to-meal">Add to Meal Plan</button>
                    </div>
                  </div>

                  <div className="ingredients-card list-card">
                    <div className="header">
                      <div className="title">Ingredients</div>
                      <button onClick={() => setModalType('nutrition_preview')}>Nutrition Facts</button>
                    </div>
                    <div className="body">
                      {recipeVariant.recipeVariantIngredients.map(rvi => (
                        <div key={rvi.id} className="ingredient">
                          <span className="amount">
                            {rvi.imperialAmount} {rvi.ingredient.imperialUnit}&nbsp;
                          </span>
                          <span className="name">{rvi.ingredient.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <button className="add-to-shopping-list">Add Ingredients to Shopping List</button>

                  <div className="directions-container">
                    <h3>Directions</h3>
                    <div className="body">
                      {recipeVariant.recipeVariantSteps.map((rvs: any, index: number) => (
                        <div className="recipe-variant-step" key={rvs.id}>
                          <div className="step-number">Step {index + 1}</div>
                          <div
                            className="step-body"
                            dangerouslySetInnerHTML={{ __html: rvs.renderedBody }}
                          ></div>
                        </div>
                      ))}
                    </div>

                    <button className="add-note">Add Note</button>
                  </div>

                  <div className="reviews-container">
                    <h3>Reviews</h3>
                    <div className="body">
                      {recipeVariant.ratingVotes ? (
                        <>
                          <div className="rating">
                            <span>{recipeVariant.rating}&nbsp;</span>
                            <IoStar size={25} color="#EFA515" style={{ display: 'inline-block' }} />
                          </div>
                          <div className="based-on">Based on {recipeVariant.ratingVotes} reviews</div>
                        </>
                      ) : (
                        'No ratings yet'
                      )}
                    </div>

                    <button className="leave-a-review">Leave A Review</button>
                  </div>
                </div>
              </div>

              <NutritionModal
                open={modalType === 'nutrition_preview'}
                recipeVariant={recipeVariant}
                onClose={() => setModalType(null)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="success" variant="flat" onPress={onClose}>
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

type PreviewRecipeVariantModalModalType = 'nutrition_preview' | null

function NutritionModal({
  recipeVariant,
  open,
  onClose,
}: {
  recipeVariant: AdminRecipeVariantDetail
  open: boolean
  onClose: () => void
}) {
  console.log(recipeVariant.nutritionInfo)
  return (
    <Modal
      size="md"
      placement="bottom-center"
      isOpen={open}
      onClose={onClose}
      scrollBehavior="inside"
      style={{ backgroundColor: 'rgba(210, 210, 210, 1)' }}
    >
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1" style={{ textAlign: 'center' }}>
              Nutrition Facts
            </ModalHeader>
            <ModalBody>
              <div style={{ textAlign: 'center' }}>
                <div className="nutrition-preview">
                  <div className="macros">
                    <div className="header">
                      <h3>Nutrition Facts</h3>
                      <div className="subtitle">1 Serving</div>
                    </div>

                    <div className="body">
                      {recipeVariant.nutritionInfo.macros.map((macro: any) => (
                        <div className="macro">
                          <div className="label">{macro.label}</div>
                          <div className="value">
                            {macro.value.value}
                            {macro.value.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="nutrients">
                    {recipeVariant.nutritionInfo.nutrition
                      .filter(
                        (nutrient: any) =>
                          !['calories', 'fat', 'carbs', 'protein'].includes(nutrient.nutrient)
                      )
                      .map((nutrient: any, index: number) => (
                        <div className="nutrient" key={index}>
                          <div className="label">{nutrient.label}</div>
                          <div className="value">
                            {nutrient.value.value}
                            {nutrient.value.label}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="success" variant="flat" onPress={onClose}>
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
