import { useCallback, useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { IoAddCircleOutline } from 'react-icons/io5'
import RecipeVariantStepCookingTipsAPI from '../../api/recipeVariantStepCookingTips'
import useMountEffect from '../../hooks/useMountEffect'
import { AdminRecipeVariantCookingTipSummary } from '../../api/schema'

export default function AddRecipeVariantStepCookingTipModal({
  open,
  onClose,
  onSelect,
}: {
  open: boolean
  onClose: () => void
  onSelect: (recipeVariantStepCookingTip: AdminRecipeVariantCookingTipSummary) => void
}) {
  const [recipeVariantStepCookingTips, setRecipeVariantStepCookingTips] = useState<
    AdminRecipeVariantCookingTipSummary[]
  >([])
  const fetchRecipeVariantStepCookingTips = useCallback(async () => {
    const results = await RecipeVariantStepCookingTipsAPI.index()
    setRecipeVariantStepCookingTips(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariantStepCookingTips()
  }, [])

  return (
    <Modal size="3xl" placement="bottom-center" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Select a recipe variant step cooking tip
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  maxHeight: 'calc(100vh - 500px)',
                  overflowY: 'auto',
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                {recipeVariantStepCookingTips.map((recipeVariantStepCookingTip, index) => (
                  <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                    <Button
                      startContent={<IoAddCircleOutline size={20} />}
                      variant="bordered"
                      color={'secondary'}
                      onClick={async () => {
                        onSelect(recipeVariantStepCookingTip)
                      }}
                    >
                      {recipeVariantStepCookingTip.name}
                    </Button>
                  </div>
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
