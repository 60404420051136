import React, { useEffect, useRef } from 'react'

const useUpdateEffect = (effect: () => void, dependencies: any[]) => {
  const changedAtLeastOnce = useRef(false)
  const originalData = useRef(JSON.stringify(dependencies))

  useEffect(() => {
    if (changedAtLeastOnce || JSON.stringify(dependencies) !== originalData.current) {
      if (!changedAtLeastOnce.current) changedAtLeastOnce.current = true
      return effect()
    }
  }, dependencies)
}

export default useUpdateEffect
