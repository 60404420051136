import routes from '../config/routes'
import { api } from './common'
import {
  AdminIngredientDetail,
  ImperialGroceryListUnitsEnum,
  ImperialUnitsEnum,
  MetricGroceryListUnitsEnum,
  MetricUnitsEnum,
  PlaceholderTypesEnum,
  ProteinTypesEnum,
  SeasonalitiesEnum,
  SecondaryProteinTypesEnum,
  SubstituteBreadTypesEnum,
  SubstituteMilkTypesEnum,
  SubstitutePastaTypesEnum,
  SubstitutePreChoppedVegetableTypesEnum,
  SubstitutePreCookedProteinTypesEnum,
  SubstituteRiceTypesEnum,
} from './schema'

export default class IngredientsAPI {
  static index({
    search = null,
    scope = null,
    page = 1,
  }: {
    search?: string | null
    page?: number
    scope?: IngredientsIndexScope | null
  } = {}) {
    return api.get(routes.api.ingredients.index, {
      params: {
        search,
        page,
        scope,
      },
    })
  }

  static create(data: Partial<IngredientParams>) {
    return api.post(routes.api.ingredients.create, data)
  }

  static show(id: string) {
    return api.get(routes.api.ingredients.show(id))
  }

  static update(id: string, data: Partial<IngredientParams>) {
    return api.patch(routes.api.ingredients.update(id), data)
  }
}

export interface IngredientSummary {
  id: string
  name: string
  isPlaceholder: boolean
}

export type IngredientParams = {
  name: any
  isPlaceholder: any
  tag: any
  metricUnit: MetricUnitsEnum | null
  imperialUnit: ImperialUnitsEnum | null
  metricGroceryListUnit: MetricGroceryListUnitsEnum | null
  imperialGroceryListUnit: ImperialGroceryListUnitsEnum | null
  metricGroceryListUnitPerMetricUnit: any
  imperialGroceryListUnitPerImperialUnit: any
  costPerMetricGroceryListUnit: any
  costPerImperialGroceryListUnit: any
  gramsPerMetricUnit: any
  gramsPerImperialUnit: any
  integerUnits: any
  commonPantryItem: any
  dairy: any
  excludeFromHalfSizeRoundingConstraint: any
  omitFromGroceryList: any
  userIngredient: any
  addedSugar: any
  calcium: any
  calories: any
  carbs: any
  fat: any
  fiber: any
  iron: any
  potassium: any
  protein: any
  saturatedFat: any
  sodium: any
  sugar: any
  vitaminD: any
  seasonality: SeasonalitiesEnum | null
  northernHemisphereSeasonStartDay: any
  northernHemisphereSeasonEndDay: any
  southernHemisphereSeasonStartDay: any
  southernHemisphereSeasonEndDay: any
  proteinType: ProteinTypesEnum | null
  secondaryProteinType: SecondaryProteinTypesEnum | null
  placeholderType: PlaceholderTypesEnum | null
  substituteBreadType: SubstituteBreadTypesEnum | null
  substituteMilkType: SubstituteMilkTypesEnum | null
  substitutePastaType: SubstitutePastaTypesEnum | null
  substitutePreChoppedVegetableType: SubstitutePreChoppedVegetableTypesEnum | null
  substitutePreCookedProteinType: SubstitutePreCookedProteinTypesEnum | null
  substituteRiceType: SubstituteRiceTypesEnum | null
  substituteForDietaryPreference: any
  defaultSubstituteForDietaryPreferences: any
  directlyIncludableSubstitute: any
  undoEshaCandidateIds: any
  redoEshaCandidateIds: any
  substitutionNotes: any
  platejoyId: any
  tempMetricRecipeIngredientAmountMultiplier: any
}

export interface IngredientWithSubstitutes {
  id: string
  name: string
  isPlaceholder: boolean
  substitutes: IngredientSummary[]
}

export type IngredientsIndexScope = 'non-placeholder-ingredients' | 'non-substitute-ingredients'
