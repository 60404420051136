import { useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
} from '@nextui-org/react'
import RecipeVariantCookingTipsAPI from '../../api/recipeVariantCookingTips'
import { AdminRecipeVariantCookingTipSummary } from '../../api/schema'

export default function EditRecipeVariantCookingTipModal({
  open,
  onClose,
  onSave,
  recipeVariantCookingTip,
}: {
  open: boolean
  onClose: () => void
  onSave: (data: { id: string; closeModal: boolean }) => void
  recipeVariantCookingTip?: AdminRecipeVariantCookingTipSummary
}) {
  let [body, setBody] = useState(recipeVariantCookingTip?.body || '')
  let [name, setName] = useState(recipeVariantCookingTip?.name || '')

  return (
    <Modal size="3xl" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {recipeVariantCookingTip ? 'Edit' : 'Create'} cooking tip
            </ModalHeader>
            <ModalBody>
              <Input
                value={name}
                id="cooking-tip-name-input"
                type="text"
                label="Name"
                onChange={e => {
                  setName(e.target.value)
                }}
              />

              <Textarea
                id="cooking-tip-body-input"
                value={body}
                onChange={e => {
                  setBody(e.target.value)
                }}
                label="body"
              />
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  if (recipeVariantCookingTip) {
                    await RecipeVariantCookingTipsAPI.update(recipeVariantCookingTip.id, {
                      body,
                      name,
                    })

                    onSave({
                      id: recipeVariantCookingTip.id,
                      closeModal: true,
                    })
                  } else {
                    const res = await RecipeVariantCookingTipsAPI.create({
                      body,
                      name,
                    })
                    const id = res.data
                    onSave({
                      id,
                      closeModal: true,
                    })
                  }
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
