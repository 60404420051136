import { Button, Input } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../../api/ingredients'
import FormGroup from '../../../forms/form-group'
import { AdminIngredientDetail } from '../../../../api/schema'

export default function IngredientNutritionInfoForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  const [addedSugar, setAddedSugar] = useState(ingredient.addedSugar)
  const [calcium, setCalcium] = useState(ingredient.calcium)
  const [calories, setCalories] = useState(ingredient.calories)
  const [carbs, setCarbs] = useState(ingredient.carbs)
  const [fat, setFat] = useState(ingredient.fat)
  const [fiber, setFiber] = useState(ingredient.fiber)
  const [iron, setIron] = useState(ingredient.iron)
  const [potassium, setPotassium] = useState(ingredient.potassium || 0)
  const [protein, setProtein] = useState(ingredient.protein)
  const [saturatedFat, setSaturatedFat] = useState(ingredient.saturatedFat)
  const [sodium, setSodium] = useState(ingredient.sodium)
  const [sugar, setSugar] = useState(ingredient.sugar)
  const [vitaminD, setVitaminD] = useState(ingredient.vitaminD)

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Nutrition info</h2>

      <FormGroup>
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Added sugar"
          name="addedSugar"
          autoComplete="off"
          id="edit-ingredient-added-sugar-input"
          value={addedSugar?.toString()}
          onChange={async e => {
            setAddedSugar(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Calcium"
          name="calcium"
          autoComplete="off"
          id="edit-ingredient-calcium-input"
          value={calcium?.toString()}
          onChange={async e => {
            setCalcium(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Calories"
          name="calories"
          autoComplete="off"
          id="edit-ingredient-calories-input"
          value={calories?.toString()}
          onChange={async e => {
            setCalories(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Carbs"
          name="carbs"
          autoComplete="off"
          id="edit-ingredient-carbs-input"
          value={carbs?.toString()}
          onChange={async e => {
            setCarbs(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Fat"
          name="fat"
          autoComplete="off"
          id="edit-ingredient-fat-input"
          value={fat?.toString()}
          onChange={async e => {
            setFat(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Fiber"
          name="fiber"
          autoComplete="off"
          id="edit-ingredient-fiber-input"
          value={fiber?.toString()}
          onChange={async e => {
            setFiber(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Iron"
          name="iron"
          autoComplete="off"
          id="edit-ingredient-iron-input"
          value={iron?.toString()}
          onChange={async e => {
            setIron(parseFloat(e.target.value))
          }}
        />

        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Potassium"
          name="potassium"
          autoComplete="off"
          id="edit-ingredient-potassium-input"
          value={potassium?.toString()}
          onChange={async e => {
            setPotassium(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Protein"
          name="protein"
          autoComplete="off"
          id="edit-ingredient-protein-input"
          value={protein?.toString()}
          onChange={async e => {
            setProtein(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Saturated fat"
          name="saturatedFat"
          autoComplete="off"
          id="edit-ingredient-saturated-fat-input"
          value={saturatedFat?.toString()}
          onChange={async e => {
            setSaturatedFat(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Sodium"
          name="sodium"
          autoComplete="off"
          id="edit-ingredient-sodium-input"
          value={sodium?.toString()}
          onChange={async e => {
            setSodium(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Sugar"
          name="sugar"
          autoComplete="off"
          id="edit-ingredient-sugar-input"
          value={sugar?.toString()}
          onChange={async e => {
            setSugar(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Vitamin D"
          name="vitaminD"
          autoComplete="off"
          id="edit-ingredient-vitamin-d-input"
          value={vitaminD?.toString()}
          onChange={async e => {
            setVitaminD(parseFloat(e.target.value))
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await IngredientsAPI.update(ingredient.id, {
              addedSugar,
              calcium,
              calories,
              carbs,
              fat,
              fiber,
              iron,
              potassium,
              protein,
              sodium,
              sugar,
              vitaminD,
              saturatedFat,
            })
            onSave()
          }}
        >
          Save nutrition info
        </Button>
      </div>
    </div>
  )
}
