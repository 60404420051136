import React, { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from '@nextui-org/react'
import { IoAddCircleOutline, IoCheckmark } from 'react-icons/io5'
import { AdminRecipeVariantSummary } from '../../api/schema'

export default function AddRecipeVariantToRecipeBrowseCategoryModal({
  open,
  onClose,
  onSelect,
  availableRecipeVariants,
  currentRecipeVariant,
}: {
  open: boolean
  onClose: () => void
  onSelect: (recipeVariant: AdminRecipeVariantSummary) => void
  availableRecipeVariants: AdminRecipeVariantSummary[]
  currentRecipeVariant?: AdminRecipeVariantSummary
}) {
  const [query, setQuery] = useState('')

  return (
    <Modal size={'full'} isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Select a recipe variant</ModalHeader>
            <ModalBody>
              <div
                style={{
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'auto',
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                <Input value={query} onChange={e => setQuery(e.target.value)} placeholder="Search" />

                {availableRecipeVariants
                  .filter(rv => (query ? new RegExp(query, 'i').test(rv.name.replace(/,/g, '')) : true))
                  .map((recipeVariant, index) => (
                    <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                      <Button
                        disabled={recipeVariant.id === currentRecipeVariant?.id}
                        startContent={
                          currentRecipeVariant?.id === recipeVariant.id ? (
                            <IoCheckmark size={20} />
                          ) : (
                            <IoAddCircleOutline size={20} />
                          )
                        }
                        variant="bordered"
                        color={
                          currentRecipeVariant && currentRecipeVariant.id === recipeVariant.id
                            ? 'success'
                            : 'secondary'
                        }
                        onClick={async () => {
                          onSelect(recipeVariant)
                        }}
                      >
                        {recipeVariant.name}
                      </Button>
                    </div>
                  ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
