import { Button, Input } from '@nextui-org/react'
import { useState } from 'react'
import IngredientExclusionCategoriesAPI from '../../../api/ingredientExclusionCategories'
import FormGroup from '../../forms/form-group'
import { AdminIngredientExclusionCategoryDetail } from '../../../api/schema'

export default function IngredientExclusionCategoryForm({
  ingredientExclusionCategory,
  onSave,
}: {
  ingredientExclusionCategory?: AdminIngredientExclusionCategoryDetail
  onSave: (id: string) => void
}) {
  const [name, setName] = useState(ingredientExclusionCategory?.name || '')

  return (
    <div style={{ marginTop: 100 }}>
      {ingredientExclusionCategory ? (
        <h2>Edit {ingredientExclusionCategory.name}</h2>
      ) : (
        <h2>New ingredient exclusion category</h2>
      )}

      <FormGroup>
        <Input
          type="text"
          classNames={{
            base: 'align-top',
          }}
          label="Name"
          name="name"
          autoComplete="off"
          id={`${ingredientExclusionCategory ? 'edit' : 'create'}-ingredient-exclusion-category-name-input`}
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            if (ingredientExclusionCategory) {
              await IngredientExclusionCategoriesAPI.update(ingredientExclusionCategory.id, {
                name,
              })
              onSave(ingredientExclusionCategory.id)
            } else {
              const res = await IngredientExclusionCategoriesAPI.create({
                name,
              })
              onSave(res.data)
            }
          }}
        >
          {ingredientExclusionCategory ? 'Save' : 'Create ingredient exclusion category'}
        </Button>
      </div>
    </div>
  )
}
