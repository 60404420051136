import React, { ReactNode } from 'react'
import { Listbox, ListboxItem } from '@nextui-org/react'
import routes from '../../config/routes'
import { NavLink } from 'react-router-dom'
import { sidebarWidth } from '../../config/dimensions'
import {
  IoAlbums,
  IoChatboxOutline,
  IoChatboxSharp,
  IoPricetagOutline,
  IoRestaurant,
  IoSettingsOutline,
  IoTrailSignOutline,
} from 'react-icons/io5'
import { LuApple } from 'react-icons/lu'
import useWindowSize from '../../hooks/useWindowSize'

export default function Sidebar() {
  const windowSize = useWindowSize()
  return (
    <div
      style={{
        height: '100vh',
        position: 'fixed',
        width: sidebarWidth(windowSize.width),
        padding: 20,
      }}
    >
      {process.env.REACT_APP_PSYCHIC_ENV === 'test' && (
        <button id="test-exit-select-btn">test exit select</button>
      )}
      <NavLink to={routes.app.home}>
        {windowSize.width > 720 ? (
          <img
            src={'/img/logo.svg'}
            style={{ marginLeft: 10, width: 100, height: 40, backgroundSize: 'cover' }}
          />
        ) : (
          <img
            src="/img/logo-isolated.svg"
            style={{ marginLeft: 7, width: 28, height: 28, backgroundSize: 'cover' }}
          />
        )}
      </NavLink>
      <Listbox aria-label="Actions">
        <ListboxItem key="recipes">
          <SidebarLink icon={IoRestaurant} to={routes.app.recipes.index}>
            Recipes
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="ingredients">
          <SidebarLink icon={LuApple} to={routes.app.ingredients.index}>
            Ingredients
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="recipe cooking tips">
          <SidebarLink icon={IoChatboxOutline} to={routes.app.recipeVariantCookingTips.index}>
            Recipe Variant Cooking Tips
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="recipe step cooking tips">
          <SidebarLink icon={IoChatboxSharp} to={routes.app.recipeVariantStepCookingTips.index}>
            Recipe Variant Step Cooking Tips
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="recipe collections">
          <SidebarLink icon={IoAlbums} to={routes.app.recipeCollectionSeries.edit}>
            Recipe Collection Series
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="recipe browse categories">
          <SidebarLink icon={IoPricetagOutline} to={routes.app.recipeBrowseCategories.index}>
            Recipe Browse Categories
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="ingredient exclusion categories">
          <SidebarLink icon={IoTrailSignOutline} to={routes.app.ingredientExclusionCategories.index}>
            Ingredient Exclusion Categories
          </SidebarLink>
        </ListboxItem>
        <ListboxItem key="system">
          <SidebarLink icon={IoSettingsOutline} to={routes.app.system.index}>
            System
          </SidebarLink>
        </ListboxItem>
      </Listbox>
    </div>
  )
}

function SidebarLink({ to, children, icon }: { to: string; children: ReactNode; icon: any }) {
  const windowSize = useWindowSize()
  const iconSize = windowSize.width > 720 ? 15 : 20
  const Icon = icon

  return (
    <NavLink to={to} style={{ verticalAlign: 'center', width: '100%', display: 'inline-block' }}>
      <Icon size={iconSize} style={{ display: 'inline-block', marginRight: 10 }} />
      {windowSize.width > 720 ? (
        <div style={{ position: 'relative', top: 2, display: 'inline-block' }}>{children}</div>
      ) : null}
    </NavLink>
  )
}
