import { CheckboxGroup, Checkbox } from '@nextui-org/react'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { AdminDietaryPreference } from '../../../api/schema'

export default function RecipeVariantDietaryPreferencesCrud({
  id,
  header,
  dietaryPreferences,
  selectedDietaryPreferences,
  onAddDietaryPreference,
  onRemoveDietaryPreference,
}: {
  id: string
  header: string
  dietaryPreferences: AdminDietaryPreference[]
  selectedDietaryPreferences: AdminDietaryPreference[]
  onAddDietaryPreference: (dietaryPreference: AdminDietaryPreference) => void
  onRemoveDietaryPreference: (dietaryPreference: AdminDietaryPreference) => void
}) {
  const [selectedDietaryPreferenceIds, setSelectedDietaryPreferenceIds] = useState<string[]>(
    selectedDietaryPreferences.map(dp => dp.id)
  )

  return (
    <div id={id} style={{ marginBottom: 40 }}>
      <h2>{header}</h2>

      <CheckboxGroup
        id={`${id}-checkbox-group`}
        orientation="horizontal"
        color="secondary"
        value={selectedDietaryPreferenceIds}
        onValueChange={newDietaryPreferenceIds => {
          setSelectedDietaryPreferenceIds(newDietaryPreferenceIds)
          const variantDietaryPreferenceIds = selectedDietaryPreferences.map(dp => dp.id)

          if (newDietaryPreferenceIds.length > selectedDietaryPreferences.length) {
            const addedPreferenceId = newDietaryPreferenceIds.find(
              id => !variantDietaryPreferenceIds.includes(id)
            )
            const addedPreference = dietaryPreferences.find(dp => dp.id === addedPreferenceId)!
            onAddDietaryPreference(addedPreference)
          } else {
            const removedPreferenceId = variantDietaryPreferenceIds.find(
              id => !newDietaryPreferenceIds.includes(id)
            )
            const removedPreference = dietaryPreferences.find(dp => dp.id === removedPreferenceId)!
            onRemoveDietaryPreference(removedPreference)
          }
        }}
      >
        {sortBy(dietaryPreferences, 'name').map((dietaryPreference, index) => (
          <Checkbox
            id={`${id}-${dietaryPreference.id}-checkbox`}
            key={index}
            value={dietaryPreference.id}
            style={{ minWidth: 300 }}
          >
            {dietaryPreference.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
  )
}
