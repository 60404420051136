import React, { ReactNode } from 'react'

export default function FormGroup({
  children,
  className = '',
  mb = 40,
  mt = 40,
}: {
  children: ReactNode | ReactNode[]
  className?: string
  mb?: number
  mt?: number
}) {
  return (
    <div className={`form-group ${className}`} style={{ marginTop: mt, marginBottom: mb }}>
      {children}
    </div>
  )
}
