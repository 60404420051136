import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../../config/routes'
import RecipeVariantForm from '../../../components/resources/recipe-variant/form'
import Loader from '../../../components/loader'
import { AdminRecipe, AdminRecipeVariantDetail } from '../../../api/schema'
import RecipesAPI from '../../../api/recipes'
import RecipeVariantsAPI from '../../../api/recipeVariants'

export default function RecipeVariantNewPage() {
  const navigate = useNavigate()
  const [recipe, setRecipe] = useState<AdminRecipe | null>(null)
  const [baseVariant, setBaseVariant] = useState<AdminRecipeVariantDetail | null>(null)
  const { id } = useParams()

  const fetchRecipe = useCallback(async () => {
    const results = await RecipesAPI.show(id!)
    const _recipe = results.data as AdminRecipe

    const baseVariantSummary = _recipe.recipeVariants.find(rv => rv.baseVariant)
    if (baseVariantSummary) {
      const baseVariantResults = await RecipeVariantsAPI.show(baseVariantSummary?.id)
      setBaseVariant(baseVariantResults.data)
      setRecipe(_recipe)
    } else {
      setRecipe(_recipe)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchRecipe()
  }, [id])

  if (!recipe) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.show(id!)}>{recipe.name}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.recipeVariants.new(id!)}>new</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <h1>New recipe variant</h1>

      <RecipeVariantForm
        recipeId={id}
        recipeVariant={baseVariant || undefined}
        newRecord={true}
        onSave={async id => {
          navigate(routes.app.recipes.recipeVariants.show(id!))
        }}
      />
    </div>
  )
}
