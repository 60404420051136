import React from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { setAuthToken } from '../stores/app'

export default function DummyAuth() {
  const dispatch = useAppDispatch()
  const authToken = useAppSelector(state => state.app.authToken)

  useEffect(() => {
    // @ts-expect-error using for test
    window.dummyAuth = (authToken: string) => {
      dispatch(setAuthToken(authToken))
      sessionStorage.setItem('test-token', authToken)
    }
  }, [dispatch])

  return authToken ? <div>token set</div> : <div>ready for token</div>
}
