import routes from '../config/routes'
import { api } from './common'

export default class IngredientExclusionCategoriesAPI {
  static create(data: IngredientExclusionCategoryParams) {
    return api.post(routes.api.ingredientExclusionCategories.create, data)
  }

  static index() {
    return api.get(routes.api.ingredientExclusionCategories.index)
  }

  static show(id: string) {
    return api.get(routes.api.ingredientExclusionCategories.show(id))
  }

  static update(id: string, data: IngredientExclusionCategoryParams) {
    return api.patch(routes.api.ingredientExclusionCategories.update(id), data)
  }

  static addIngredient(id: string, ingredientId: string) {
    return api.post(routes.api.ingredientExclusionCategories.ingredients.create(id), { ingredientId })
  }
}

export type IngredientExclusionCategoryParams = {
  name?: string
}
