import routes from '../config/routes'
import { api } from './common'
import { IngredientWithSubstitutes } from './ingredients'

export default class RecipeVariantIngredientsAPI {
  static update(id: string, attrs: Partial<RecipeVariantIngredientParams>) {
    return api.patch(routes.api.recipeVariantIngredients.update(id), attrs)
  }

  static destroy(id: string) {
    return api.delete(routes.api.recipeVariantIngredients.destroy(id))
  }
}

export interface RecipeVariantIngredientSummary {
  id: string
  metricAmount: number
  imperialAmount: number
  optional: boolean
  recipeVariantId: string
  ingredient: IngredientWithSubstitutes
}

export interface RecipeVariantIngredientParams {
  metricAmount: number
  imperialAmount: number
  optional: boolean
  recipeVariantId: string
}
