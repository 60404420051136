import routes from '../config/routes'
import { api } from './common'

export default class AppliancesAPI {
  static index() {
    return api.get(routes.api.appliances.index)
  }
}

export interface ApplianceSummary {
  id: string
  name: string
}

export interface ApplianceDetail {
  id: string
  name: string
}

export const appliances = [
  'Blender',
  'Food processor',
  'Instant pot',
  'Microwave',
  'Oven',
  'Slow cooker',
  'Stovetop',
] as const
