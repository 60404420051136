import { useCallback, useEffect, useState } from 'react'
import IngredientExclusionCategoriesAPI from '../../api/ingredientExclusionCategories'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem, Input } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import IngredientExclusionCategoryForm from '../../components/resources/ingredient-exclusion-category/form'
import { AdminIngredientExclusionCategoryDetail } from '../../api/schema'
import useMountEffect from '../../hooks/useMountEffect'
import Loader from '../../components/loader'

export default function IngredientExclusionCategoriesEditPage() {
  const navigate = useNavigate()
  const [ingredientExclusionCategory, setIngredientExclusionCategory] =
    useState<AdminIngredientExclusionCategoryDetail | null>(null)
  const { id } = useParams()

  const fetchIngredientExclusionCategory = useCallback(async () => {
    const results = await IngredientExclusionCategoriesAPI.show(id!)
    setIngredientExclusionCategory(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchIngredientExclusionCategory()
  }, [id])

  if (!ingredientExclusionCategory) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.index}>IngredientExclusionCategories</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.show(id!)}>
            {ingredientExclusionCategory.name}
          </NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredientExclusionCategories.edit(id!)}>edit</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <IngredientExclusionCategoryForm
        ingredientExclusionCategory={ingredientExclusionCategory}
        onSave={async () => {
          await fetchIngredientExclusionCategory()
          navigate(routes.app.ingredientExclusionCategories.show(id!))
        }}
      />
    </div>
  )
}
