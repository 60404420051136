import { AdminIngredientDetail } from '../../../api/schema'
import IngredientNutritionInfoForm from './form-sections/nutrition-info-form'
import IngredientBasicInfoForm from './form-sections/basic-info'
import IngredientSeasonalityForm from './form-sections/seasonality'
import IngredientMetricsForm from './form-sections/metrics'
import IngredientSubstitutionForm from './form-sections/substitution'

export default function IngredientForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  return (
    <div style={{ marginTop: 100 }}>
      <IngredientBasicInfoForm ingredient={ingredient} onSave={onSave} />
      <IngredientNutritionInfoForm ingredient={ingredient} onSave={onSave} />
      <IngredientSeasonalityForm ingredient={ingredient} onSave={onSave} />
      <IngredientMetricsForm ingredient={ingredient} onSave={onSave} />
      <IngredientSubstitutionForm ingredient={ingredient} onSave={onSave} />
    </div>
  )
}
