import { useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableColumn,
  Chip,
  Progress,
} from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../../config/routes'
import RecipeVariantStepsCrud from '../../../components/resources/recipe-variant-step/crud'
import RecipeVariantsAPI, { RecipeVariantNutritionInfo } from '../../../api/recipeVariants'
import notify from '../../../helpers/notify'
import useMountEffect from '../../../hooks/useMountEffect'
import RecipeVariantIngredientsCrud from '../../../components/resources/recipe-variant-ingredient/crud'
import { AdminDietaryPreference, AdminRecipeVariantDetail } from '../../../api/schema'
import Loader from '../../../components/loader'
import RecipeVariantDietaryPreferencesCrud from '../../../components/resources/recipe-variant-dietary-preference/crud'
import DietaryPreferencesAPI from '../../../api/dietaryPreferences'
import RecipeVariantDietaryPreferencesAPI from '../../../api/recipeVariantDietaryPreferences'
import RecipeVariantExclusiveToDietaryPreferencesAPI from '../../../api/recipeVariantExclusiveToDietaryPreferences'
import isTest from '../../../helpers/isTest'
import RecipeVariantAppliancesCrud from '../../../components/resources/recipe-variant-appliance/crud'
import RecipeVariantAppliancesAPI from '../../../api/recipeVariantAppliances'
import { IoEye, IoPencil } from 'react-icons/io5'
import { RecipeVariantCookingTipCrud } from '../../../components/resources/recipe-variant-cooking-tip/crud'
import Banner from '../../../components/ui/banner'
import EditableImage from '../../../components/ui/editable-image'
import getImageContentType from '../../../helpers/getImageContentType'
import axios from 'axios'
import PreviewRecipeVariantModal from '../../../components/modals/preview-recipe-variant-modal'

export default function RecipeVariantShowPage() {
  const [recipeVariant, setRecipeVariant] = useState<AdminRecipeVariantDetail | null>(null)
  const [dietaryPreferences, setDietaryPreferences] = useState<AdminDietaryPreference[]>([])
  const [contentTypeError, setContentTypeError] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [modalType, setModalType] = useState<RecipeVariantShowPageModalType>(null)

  const { id } = useParams()

  const fetchRecipeVariant = useCallback(async () => {
    const results = await RecipeVariantsAPI.show(id!)
    setRecipeVariant(results.data)
  }, [id])

  const fetchDietaryPreferences = useCallback(async () => {
    const results = await DietaryPreferencesAPI.index()
    setDietaryPreferences(results.data)
  }, [id])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariant()

    // eslint-disable-next-line
    fetchDietaryPreferences()
  }, [])

  if (!recipeVariant) return <Loader />
  const nutritionInfo: RecipeVariantNutritionInfo = recipeVariant?.nutritionInfo

  const sizedImageUrl = recipeVariant.imageUrl ? `${recipeVariant.imageUrl}?h=500&w=500` : null

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.show(recipeVariant.recipeId)}>{recipeVariant.recipeName}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.recipeVariants.show(id!)}>{recipeVariant.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1 style={{ verticalAlign: 'top' }}>
          {recipeVariant.name}
          {recipeVariant.published ? (
            <Chip
              color="success"
              variant="flat"
              style={{ marginLeft: 10, top: '-3px', position: 'relative' }}
            >
              Published
            </Chip>
          ) : (
            <Chip
              color="warning"
              variant="flat"
              style={{ marginLeft: 10, top: '-3px', position: 'relative' }}
            >
              Unpublished
            </Chip>
          )}
        </h1>

        <div style={{ marginLeft: 'auto' }}>
          <Button
            as={Link}
            to={routes.app.recipes.recipeVariants.edit(id!)}
            className="text-white"
            color="success"
            aria-label="Edit"
          >
            <IoPencil size={20} />
            Edit
          </Button>

          <Button
            className="text-white"
            color="success"
            aria-label="Edit"
            style={{ marginLeft: 20 }}
            onPress={() => {
              setModalType('preview_recipe_variant')
            }}
          >
            <IoEye size={20} />
            Preview
          </Button>
        </div>
      </div>

      {contentTypeError && <div>The content type of the file you selected must be either jpg or png</div>}
      <EditableImage
        id="recipe-variant-image-edit"
        src={sizedImageUrl}
        size="lg"
        progress={uploadProgress}
        onChange={async file => {
          if (file && !getImageContentType(file)) setContentTypeError(true)
          else if (file) {
            try {
              const res = await RecipeVariantsAPI.updateImage(recipeVariant.id, getImageContentType(file)!)
              const { uploadUrl, viewUrl } = res.data

              await axios.put(uploadUrl, file, {
                headers: {
                  'Content-Type': file?.type,
                  // make sure not to send normal auth token, since it is unrelated to this api
                  // and can create authorization issues
                  Authorization: null,
                },
                onUploadProgress: event => {
                  setUploadProgress(event.progress! * 100)
                },
              })

              setRecipeVariant({
                ...recipeVariant,
                imageUrl: viewUrl,
              })
              setUploadProgress(0)
              notify('Image uploaded')
            } catch (err: any) {
              console.error('Unexpected recipe variant image upload error: ', err)
            }
          }
        }}
      />

      <h2>Properties</h2>

      <Table
        aria-label="Properties"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell>Published</TableCell>
            <TableCell>{recipeVariant.published ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Image credit name</TableCell>
            <TableCell>{recipeVariant.imageCreditName || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Image credit url</TableCell>
            <TableCell>
              {recipeVariant.imageCreditUrl ? (
                <a href={recipeVariant.imageCreditUrl} rel="noopener" target="_blank">
                  {recipeVariant.imageCreditUrl}
                </a>
              ) : (
                'N/A'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Image credit details</TableCell>
            <TableCell>{recipeVariant.imageCreditDetails || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Base Variant</TableCell>
            <TableCell>{recipeVariant.baseVariant ? 'true' : 'false'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Variant-defining Dietary Preference</TableCell>
            <TableCell>{recipeVariant.variantDefiningDietaryPreference?.name || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Protein Type</TableCell>
            <TableCell>{recipeVariant.proteinType || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Secondary Protein Type</TableCell>
            <TableCell>{recipeVariant.secondaryProteinType || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rating</TableCell>
            <TableCell>{recipeVariant.rating || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rating Votes</TableCell>
            <TableCell>{recipeVariant.ratingVotes || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Approximate Active Minutes</TableCell>
            <TableCell>{recipeVariant.approximateActiveMinutes || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Approximate Passive Minutes</TableCell>
            <TableCell>{recipeVariant.approximatePassiveMinutes || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Approximate Total Minutes</TableCell>
            <TableCell>{recipeVariant.approximateTotalMinutes || 'N/A'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Macros</h2>

      <Table
        aria-label="Macros"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>NUTRIENT</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {nutritionInfo.macros.map(nutrientInfo => (
            <TableRow>
              <TableCell>{nutrientInfo.label}</TableCell>
              <TableCell>
                {nutrientInfo.value.value} {nutrientInfo.value.label}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Nutrition</h2>

      <Table
        aria-label="Nutrition"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>NUTRIENT</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          {nutritionInfo.nutrition.map(nutrientInfo => (
            <TableRow>
              <TableCell>{nutrientInfo.label}</TableCell>
              <TableCell>
                {nutrientInfo.value.value} {nutrientInfo.value.label}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <RecipeVariantIngredientsCrud
        recipeVariant={recipeVariant}
        onSave={async () => {
          await fetchRecipeVariant()
        }}
      />

      {recipeVariant.recipeVariantsWithConflictingDietaryPreferences.length ? (
        <Banner type="error">
          The current dietary preference selection conflicts with other recipe variants on this recipe:
          {recipeVariant.recipeVariantsWithConflictingDietaryPreferences.map(rv => (
            <div key={rv.id}>
              <NavLink to={routes.app.recipes.recipeVariants.show(rv.id)} style={{ color: 'blue' }}>
                {rv.name}
              </NavLink>
            </div>
          ))}
        </Banner>
      ) : null}

      {![
        ...recipeVariant.recipeVariantDietaryPreferences,
        ...recipeVariant.recipeVariantExclusiveToDietaryPreferences,
      ].length ? (
        <Banner type="error" heading="Warning">
          You have not selected any dietary preferences for this Recipe Variant.
        </Banner>
      ) : null}

      <RecipeVariantDietaryPreferencesCrud
        id="dietary-preferences-crud"
        header="Dietary Preferences"
        selectedDietaryPreferences={recipeVariant.recipeVariantDietaryPreferences.map(
          rvdp => rvdp.dietaryPreference
        )}
        dietaryPreferences={dietaryPreferences}
        onAddDietaryPreference={async dietaryPreference => {
          await RecipeVariantsAPI.addDietaryPreference(recipeVariant.id, dietaryPreference.id)
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully added ${dietaryPreference.name}`)
        }}
        onRemoveDietaryPreference={async dietaryPreference => {
          const recipeVariantDietaryPreference = recipeVariant.recipeVariantDietaryPreferences.find(
            rvdp => rvdp.dietaryPreference.id === dietaryPreference.id
          )!
          await RecipeVariantDietaryPreferencesAPI.destroy(recipeVariantDietaryPreference.id)
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully removed ${dietaryPreference.name}`)
        }}
      />

      <RecipeVariantDietaryPreferencesCrud
        id="exclusive-to-dietary-preferences-crud"
        header="Exclusive to Dietary Preferences"
        selectedDietaryPreferences={recipeVariant.recipeVariantExclusiveToDietaryPreferences.map(
          rvdp => rvdp.dietaryPreference
        )}
        dietaryPreferences={dietaryPreferences}
        onAddDietaryPreference={async dietaryPreference => {
          await RecipeVariantsAPI.addExclusiveToDietaryPreference(recipeVariant.id, dietaryPreference.id)
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully added ${dietaryPreference.name}`)
        }}
        onRemoveDietaryPreference={async dietaryPreference => {
          const recipeVariantExclusiveToDietaryPreference =
            recipeVariant.recipeVariantExclusiveToDietaryPreferences.find(
              rvdp => rvdp.dietaryPreference.id === dietaryPreference.id
            )!
          await RecipeVariantExclusiveToDietaryPreferencesAPI.destroy(
            recipeVariantExclusiveToDietaryPreference.id
          )
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully removed ${dietaryPreference.name}`)
        }}
      />

      <RecipeVariantAppliancesCrud
        selectedAppliances={recipeVariant.recipeVariantAppliances.map(rvdp => rvdp.appliance)}
        onAddAppliance={async appliance => {
          await RecipeVariantsAPI.addAppliance(recipeVariant.id, appliance.id)
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully added ${appliance.name}`)
        }}
        onRemoveAppliance={async appliance => {
          const recipeVariantAppliance = recipeVariant.recipeVariantAppliances.find(
            rvdp => rvdp.appliance.id === appliance.id
          )!
          await RecipeVariantAppliancesAPI.destroy(recipeVariantAppliance.id)
          await fetchRecipeVariant()
          if (isTest()) notify(`Successfully removed ${appliance.name}`)
        }}
      />

      <RecipeVariantStepsCrud
        recipeVariant={recipeVariant}
        onSave={async () => {
          await fetchRecipeVariant()
        }}
      />

      <RecipeVariantCookingTipCrud
        recipeVariant={recipeVariant}
        recipeVariantCookingTip={recipeVariant.recipeVariantCookingTip}
        onSave={async () => {
          await fetchRecipeVariant()
        }}
      />

      <PreviewRecipeVariantModal
        recipeVariant={recipeVariant}
        open={modalType === 'preview_recipe_variant'}
        onClose={() => setModalType(null)}
      />
    </div>
  )
}

type RecipeVariantShowPageModalType = 'preview_recipe_variant' | null
