import { DateTime } from 'luxon'

export default function interpretIngredientSeasonalityRange(start: string, end: string, startYear: number) {
  const [startMonth, startDay] = start.split('-').map(d => parseInt(d))
  const [endMonth, endDay] = end.split('-').map(d => parseInt(d))
  const validEndMonth = trueEndMonth(endMonth)
  const validEndYear = trueEndYear(startMonth, endMonth, startYear)

  const startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: startDay })
  const endDate = DateTime.fromObject({
    year: validEndYear,
    month: validEndMonth,
    day: endDay,
  })
  const maxDate = startDate.plus({ year: 1 })
  const minDate = endDate.minus({ year: 1 })

  return {
    startMonth,
    startDay,
    endMonth,
    endDay,
    startDate,
    endDate,
    maxDate,
    minDate,
  }
}

function trueEndYear(startMonth: number, endMonth: number, startYear: number) {
  const realEndMonth = endMonth > 12 ? endMonth - 12 : endMonth

  if (startMonth <= realEndMonth) {
    return startYear
  } else {
    return startYear + 1
  }
}

function trueEndMonth(end: number) {
  return end > 12 ? end - 12 : end
}
