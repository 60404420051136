import { useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
} from '@nextui-org/react'
import RecipeVariantStepCookingTipsAPI from '../../api/recipeVariantStepCookingTips'
import { AdminRecipeVariantCookingTipSummary, AdminRecipeVariantStep } from '../../api/schema'

export default function EditRecipeVariantStepCookingTipModal({
  open,
  onClose,
  onSave,
  recipeVariantStepCookingTip,
}: {
  open: boolean
  onClose: () => void
  onSave: (data: { id: string; closeModal: boolean }) => void
  recipeVariantStepCookingTip?: AdminRecipeVariantCookingTipSummary
}) {
  let [body, setBody] = useState(recipeVariantStepCookingTip?.body || '')
  let [name, setName] = useState(recipeVariantStepCookingTip?.name || '')

  return (
    <Modal size="3xl" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {recipeVariantStepCookingTip ? 'Edit' : 'Create'} cooking tip
            </ModalHeader>
            <ModalBody>
              <Input
                value={name}
                id="cooking-tip-name-input"
                type="text"
                label="Name"
                onChange={e => {
                  setName(e.target.value)
                }}
              />

              <Textarea
                id="cooking-tip-body-input"
                value={body}
                onChange={e => {
                  setBody(e.target.value)
                }}
                label="body"
              />
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                id="recipe-variant-step-cooking-tip-save-button"
                color="success"
                variant="flat"
                onPress={async () => {
                  if (recipeVariantStepCookingTip) {
                    await RecipeVariantStepCookingTipsAPI.update(recipeVariantStepCookingTip.id, {
                      body,
                      name,
                    })
                    onSave({
                      id: recipeVariantStepCookingTip.id,
                      closeModal: true,
                    })
                  } else {
                    const res = await RecipeVariantStepCookingTipsAPI.create({
                      body,
                      name,
                    })
                    onSave({
                      id: res.data,
                      closeModal: true,
                    })
                  }
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
