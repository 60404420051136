import React, { useEffect, useState } from 'react'

export default function AdminDashboardPage() {
  const [imgClass, setImgClass] = useState('')
  useEffect(() => {
    setImgClass('animate')
  })

  return (
    <div className="page" id="dash">
      {process.env.REACT_APP_PSYCHIC_ENV === 'test' && 'Dashboard'}
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '70vh' }}>
        <img style={{ marginLeft: -280 }} className={imgClass} src="/img/logo.svg" width={400} />
        <div style={{ padding: 5, border: 'solid black thin', borderRadius: '10px', fontSize: 10 }}>
          Meals
        </div>
      </div>
    </div>
  )
}
