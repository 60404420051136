import { ReactNode } from 'react'
import { toast } from 'react-toastify'

export default function notify(message: string | ReactNode, type: NotificationType = 'success') {
  toast[type](message, {
    theme: 'colored',
    position: 'bottom-right',
  })
}

type NotificationType = 'success' | 'error' | 'info'
