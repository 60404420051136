import routes from '../config/routes'
import { api } from './common'
import { RecipeVariantSummary } from './recipeVariants'
import {
  CuisinesEnum,
  DishTypesEnum,
  MealTypesEnum,
  SeasonalitiesEnum,
  AdminRecipe,
  SubCuisinesEnum,
} from './schema'

export default class RecipesAPI {
  static index({
    search = null,
    page = 1,
    cuisine = null,
    subCuisines = null,
    applianceId = null,
    dietaryPreferenceId = null,
    mealType = null,
    dishType = null,
    totalTime = null,
    activeTime = null,
    showHidden = null,
  }: {
    search?: string | null
    page?: number
    cuisine?: (typeof cuisines)[number] | null
    subCuisines?: (typeof subcuisines)[number][] | null
    mealType?: (typeof mealTypes)[number] | null
    dishType?: (typeof dishTypes)[number] | null
    applianceId?: string | null
    dietaryPreferenceId?: string | null
    totalTime?: number | null
    activeTime?: number | null
    showHidden?: boolean | null
  } = {}) {
    return api.get(routes.api.recipes.index, {
      params: {
        search,
        page,
        cuisine,
        subCuisines,
        applianceId,
        dietaryPreferenceId,
        mealType,
        dishType,
        totalTime,
        activeTime,
        showHidden: showHidden ? 1 : 0,
      },
    })
  }

  static show(id: string) {
    return api.get(routes.api.recipes.show(id))
  }

  static update(id: string, data: RecipeParams) {
    return api.patch(routes.api.recipes.update(id), data)
  }

  static bulkUpload(csv: File) {
    return api.post(
      routes.api.recipes.bulkUpload,
      { csv },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
}

export interface RecipeSummary {
  id: string
  name: string
  mealType: string
  cuisine: (typeof cuisines)[number]
  subCuisines: (typeof subcuisines)[number][]
}

export interface RecipeParams {
  name?: string
  mealType?: MealTypesEnum | null
  dishType?: DishTypesEnum | null
  cuisine?: CuisinesEnum | null
  subCuisines?: SubCuisinesEnum[]
  northernHemisphereSeasonStartDay?: string
  northernHemisphereSeasonEndDay?: string
  southernHemisphereSeasonStartDay?: string
  southernHemisphereSeasonEndDay?: string
  seasonality?: SeasonalitiesEnum
  servings?: number
  fixedPortionSize?: boolean
  doubleable?: boolean
  prePrepared?: boolean
  exotic?: boolean
  introduction?: string
  description?: string
  hidden?: boolean
}

export interface RecipeFilterData {
  applianceId: string | null
  mealType: (typeof mealTypes)[number] | null
  cuisine: (typeof cuisines)[number] | null
  subCuisines: (typeof subcuisines)[number][]
  dietaryPreferenceId: string | null
  dishType: (typeof dishTypes)[number] | null
  totalTime?: number | null
  activeTime?: number | null
  showHidden?: boolean
}

export const mealTypes = ['batch', 'breakfast', 'dinner', 'lunch', 'snack_dessert'] as const

export const dishTypes = [
  'batch',
  'bento',
  'bowl',
  'curry',
  'eggs',
  'hashes',
  'oats',
  'other',
  'pasta',
  'pizza',
  'salad',
  'sandwich',
  'skillet',
  'smoothie',
  'soup',
  'toast',
] as const

export const cuisines = [
  'african',
  'asian',
  'caribbean',
  'eastern_european',
  'indian',
  'latin_american',
  'mediterranean',
  'middle_eastern',
  'northern_european',
  'southern_us',
  'traditional_american',
] as const

export const subcuisines = [
  'argentinian',
  'austrian',
  'brazilian',
  'british',
  'burmese',
  'cajun',
  'californian',
  'cambodian',
  'canadian',
  'central_american',
  'chilean',
  'chinese',
  'creole',
  'cuban',
  'east_african',
  'egyptian',
  'english',
  'ethiopian',
  'filipino',
  'french',
  'german',
  'greek',
  'hawaiian',
  'hungarian',
  'irish',
  'italian',
  'jamaican',
  'japanese',
  'jewish',
  'korean',
  'lebanese',
  'mexican',
  'mongolian',
  'moroccan',
  'north_african',
  'north_indian',
  'persian',
  'peruvian',
  'polish',
  'portuguese',
  'russian',
  'scottish',
  'soul_food',
  'south_african',
  'south_indian',
  'southeast_asian',
  'southern',
  'southwest',
  'spanish',
  'swiss',
  'tex_mex',
  'thai',
  'turkish',
  'vietnamese',
  'west_african',
] as const
