import { Button, Input, Radio, RadioGroup, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../api/ingredients'
import FormGroup from '../../forms/form-group'
import {
  ImperialUnitsEnum,
  ImperialUnitsEnumValues,
  MetricUnitsEnum,
  MetricUnitsEnumValues,
  ProteinTypesEnum,
  ProteinTypesEnumValues,
  SecondaryProteinTypesEnum,
  SecondaryProteinTypesEnumValues,
} from '../../../api/schema'
import routes from '../../../config/routes'
import { useNavigate } from 'react-router-dom'

export default function IngredientCreateForm() {
  const navigate = useNavigate()

  const [name, setName] = useState('Untitled ingredient')
  const [proteinType, setProteinType] = useState<ProteinTypesEnum>('vegetable_protein')
  const [secondaryProteinType, setSecondaryProteinType] = useState<SecondaryProteinTypesEnum | null>(null)
  const [dairy, setDairy] = useState(false)
  const [commonPantryItem, setCommonPantryItem] = useState(false)
  const [omitFromGroceryList, setOmitFromGroceryList] = useState(false)

  const [imperialUnit, setImperialUnit] = useState<ImperialUnitsEnum>('count')
  const [metricUnit, setMetricUnit] = useState<MetricUnitsEnum>('count')

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Basic info</h2>

      <FormGroup>
        <Input
          type="text"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Name"
          name="name"
          autoComplete="off"
          id="create-ingredient-name-input"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
        <Select
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Protein type"
          id="create-ingredient-protein-type-select"
          onChange={e => {
            setProteinType(e.target.value as any)
          }}
          selectedKeys={[proteinType]}
        >
          {ProteinTypesEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
        <Select
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Secondary protein type"
          id="create-ingredient-secondary-protein-type-select"
          onChange={e => {
            if (e.target.value === 'none') setSecondaryProteinType(null)
            else setSecondaryProteinType(e.target.value as any)
          }}
          selectedKeys={[secondaryProteinType || 'none']}
        >
          {[...SecondaryProteinTypesEnumValues, 'none'].map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="create-ingredient-common-pantry-item-radio-group"
          label="Common pantry ingredient"
          onChange={e => {
            setCommonPantryItem(e.target.value === 'true')
          }}
          value={commonPantryItem ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="create-ingredient-dairy-radio-group"
          label="Dairy"
          onChange={e => {
            setDairy(e.target.value === 'true')
          }}
          value={dairy ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="create-ingredient-omit-from-grocery-list-radio-group"
          label="Omit from grocery list"
          onChange={e => {
            setOmitFromGroceryList(e.target.value === 'true')
          }}
          value={omitFromGroceryList ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup mb={0}>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Metric unit"
          id="create-ingredient-metric-unit-select"
          onChange={e => {
            setMetricUnit(e.target.value as any)
          }}
          selectedKeys={[metricUnit]}
        >
          {MetricUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Imperial unit"
          id="create-ingredient-imperial-unit-select"
          onChange={e => {
            setImperialUnit(e.target.value as any)
          }}
          selectedKeys={[imperialUnit]}
        >
          {ImperialUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            const res = await IngredientsAPI.create({
              name,
              commonPantryItem,
              dairy,
              omitFromGroceryList,
              proteinType,
              secondaryProteinType,
              metricUnit,
              imperialUnit,
            })
            const id = res.data
            navigate(routes.app.ingredients.show(id))
          }}
        >
          Create ingredient
        </Button>
      </div>
    </div>
  )
}
