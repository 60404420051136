import { useCallback, useEffect, useState } from 'react'
import RecipesAPI from '../../api/recipes'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import RecipeForm from '../../components/resources/recipe/form'
import Loader from '../../components/loader'
import { AdminRecipe } from '../../api/schema'

export default function RecipeEditPage() {
  const navigate = useNavigate()
  const [recipe, setRecipe] = useState<AdminRecipe | null>(null)
  const { id } = useParams()

  const fetchRecipe = useCallback(async () => {
    const results = await RecipesAPI.show(id!)
    setRecipe(results.data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchRecipe()
  }, [id])

  if (!recipe) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.index}>Recipes</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.show(id!)}>{recipe.name}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipes.edit(id!)}>edit</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <h1>{recipe.name}</h1>

      <RecipeForm
        recipe={recipe}
        onSave={async () => {
          await fetchRecipe()
          navigate(routes.app.recipes.show(id!))
        }}
      />
    </div>
  )
}
