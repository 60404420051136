import React, { useCallback, useState } from 'react'
import { Button, Input, Select, SelectItem, RadioGroup, Radio, Switch, Textarea } from '@nextui-org/react'
import {
  AdminDietaryPreference,
  AdminRecipeVariantDetail,
  ProteinTypesEnum,
  ProteinTypesEnumValues,
  SecondaryProteinTypesEnum,
  SecondaryProteinTypesEnumValues,
} from '../../../api/schema'
import FormGroup from '../../forms/form-group'
import RecipeVariantsAPI, { RecipeVariantParams } from '../../../api/recipeVariants'
import useMountEffect from '../../../hooks/useMountEffect'
import DietaryPreferencesAPI from '../../../api/dietaryPreferences'

export default function RecipeVariantForm({
  recipeVariant,
  recipeId,
  newRecord = !recipeVariant,
  onSave,
}: {
  recipeVariant?: AdminRecipeVariantDetail
  recipeId?: string
  newRecord?: boolean
  onSave: (id?: string) => void
}) {
  const [name, setName] = useState(recipeVariant?.name || '')
  const [imageCreditName, setImageCreditName] = useState(recipeVariant?.imageCreditName || '')
  const [imageCreditUrl, setImageCreditUrl] = useState(recipeVariant?.imageCreditUrl || '')
  const [imageCreditDetails, setImageCreditDetails] = useState(recipeVariant?.imageCreditDetails || '')
  const [proteinType, setProteinType] = useState<ProteinTypesEnum | null>(recipeVariant?.proteinType || null)
  const [secondaryProteinType, setSecondaryProteinType] = useState<SecondaryProteinTypesEnum | null>(
    recipeVariant?.secondaryProteinType || null
  )
  const [baseVariant, setBaseVariant] = useState(recipeVariant?.baseVariant && !newRecord ? true : false)
  const [dietaryPreferences, setDietaryPreferences] = useState<AdminDietaryPreference[]>([])
  const [variantDefiningDietaryPreferenceId, setVariantDefiningDietaryPreferenceId] = useState<string | null>(
    recipeVariant?.variantDefiningDietaryPreference?.id || null
  )
  const [published, setPublished] = useState(recipeVariant?.published && !newRecord ? true : false)

  const fetchDietaryPreferences = useCallback(async () => {
    const results = await DietaryPreferencesAPI.index()
    setDietaryPreferences(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchDietaryPreferences()
  }, [])

  const idForField = (fieldName: string) =>
    `${recipeVariant && !newRecord ? 'edit' : 'new'}-recipe-variant-${fieldName}`

  return (
    <div style={{ marginTop: 100 }}>
      <FormGroup>
        <Switch
          id="edit-recipe-variant-published-toggle-switch"
          className="toggle-switch"
          aria-label="Automatic updates"
          isSelected={published}
          onValueChange={setPublished}
        >
          Published
        </Switch>
      </FormGroup>

      <FormGroup>
        <Input
          type="text"
          label="Name"
          name="name"
          autoComplete="off"
          id={idForField('name-input')}
          labelPlacement="outside"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id={idForField('protein-type-select')}
          label="protein type"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'None') setProteinType(null)
            else setProteinType(e.target.value as any)
          }}
          selectedKeys={proteinType ? [proteinType as string] : []}
        >
          {['none', ...ProteinTypesEnumValues].map(proteinType =>
            proteinType === 'none' ? (
              <SelectItem key="None" value="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={proteinType as any} textValue={proteinType}>
                {proteinType}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id={idForField('secondary-protein-type-select')}
          label="secondary protein type"
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'None') setSecondaryProteinType(null)
            else setSecondaryProteinType(e.target.value as any)
          }}
          selectedKeys={secondaryProteinType ? [secondaryProteinType as string] : []}
        >
          {['none', ...SecondaryProteinTypesEnumValues].map(secondaryProteinType =>
            secondaryProteinType === 'none' ? (
              <SelectItem key="None" value="None">
                None
              </SelectItem>
            ) : (
              <SelectItem key={secondaryProteinType as any} textValue={secondaryProteinType}>
                {secondaryProteinType}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={idForField('variant-defining-dietary-preference-select')}
          label="dietary preference"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setVariantDefiningDietaryPreferenceId(null)
            else setVariantDefiningDietaryPreferenceId(e.target.value)
          }}
          selectedKeys={variantDefiningDietaryPreferenceId ? [variantDefiningDietaryPreferenceId] : ['none']}
        >
          {['none', ...dietaryPreferences].map(dietaryPreference =>
            dietaryPreference === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem
                key={(dietaryPreference as AdminDietaryPreference).id}
                value={(dietaryPreference as AdminDietaryPreference).id}
              >
                {(dietaryPreference as AdminDietaryPreference).name}
              </SelectItem>
            )
          )}
        </Select>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id={idForField('base-variant-radio-group')}
          label="base variant"
          onChange={e => {
            setBaseVariant(e.target.value === 'true')
          }}
          value={baseVariant ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <Input
          type="text"
          className="input-sm"
          label="Image credit name"
          name="imageCreditName"
          autoComplete="off"
          id={idForField('image-credit-name-input')}
          labelPlacement="outside"
          value={imageCreditName}
          onChange={async e => {
            setImageCreditName(e.target.value)
          }}
        />
        <Input
          type="text"
          className="input-md"
          label="Image credit url"
          name="imageCreditUrl"
          autoComplete="off"
          id={idForField('image-credit-url-input')}
          labelPlacement="outside"
          value={imageCreditUrl}
          onChange={async e => {
            setImageCreditUrl(e.target.value)
          }}
        />
      </FormGroup>

      <FormGroup>
        <Textarea
          label="Image credit details"
          name="imageCreditDetails"
          autoComplete="off"
          id={idForField('image-credit-details-input')}
          labelPlacement="outside"
          value={imageCreditDetails}
          onChange={async e => {
            setImageCreditDetails(e.target.value)
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            const params: RecipeVariantParams = {
              name,
              proteinType,
              secondaryProteinType,
              baseVariant,
              variantDefiningDietaryPreferenceId,
              published,
              imageCreditName,
              imageCreditUrl,
              imageCreditDetails,
            }

            if (!newRecord && recipeVariant) {
              await RecipeVariantsAPI.update(recipeVariant.id, params)
              onSave()
            } else {
              const results = await RecipeVariantsAPI.create({ ...params, recipeId })
              onSave(results.data as string)
            }
          }}
        >
          {newRecord ? 'Create' : 'Save basic info'}
        </Button>
      </div>
    </div>
  )
}
