import routes from '../config/routes'
import { api } from './common'
import { IngredientSummary } from './ingredients'

export default class RecipeVariantIngredientOverrideOptionsAPI {
  static create(attrs: RecipeVariantIngredientOverrideOptionsParams) {
    return api.post(routes.api.recipeVariantIngredientOverrideOptions.create(attrs.recipeVariantId), attrs)
  }

  static destroy(id: string) {
    return api.delete(routes.api.recipeVariantIngredientOverrideOptions.destroy(id))
  }
}

export interface RecipeVariantIngredientOverrideOptionDetail {
  id: string
  metricAmount: number
  imperialAmount: number
  optional: boolean
  recipeVariantId: string
  ingredient: IngredientSummary
  overriddenIngredient: IngredientSummary
}

export interface RecipeVariantIngredientOverrideOptionsParams {
  recipeVariantId: string
  ingredientId: string
  overriddenIngredientId: string
}
