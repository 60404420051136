import { Button, Input, Radio, RadioGroup, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../../api/ingredients'
import FormGroup from '../../../forms/form-group'
import {
  AdminIngredientDetail,
  ProteinTypesEnumValues,
  SecondaryProteinTypesEnumValues,
} from '../../../../api/schema'
import IngredientNutritionInfoForm from './nutrition-info-form'

export default function IngredientBasicInfoForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  const [name, setName] = useState(ingredient.name.replace(/\(placeholder\)$/, ''))
  const [proteinType, setProteinType] = useState(ingredient.proteinType)
  const [dairy, setDairy] = useState(ingredient.dairy)
  const [commonPantryItem, setCommonPantryItem] = useState(ingredient.commonPantryItem)
  const [omitFromGroceryList, setOmitFromGroceryList] = useState(ingredient.omitFromGroceryList)
  const [secondaryProteinType, setSecondaryProteinType] = useState(ingredient.secondaryProteinType)

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Basic info</h2>

      <FormGroup>
        <Input
          type="text"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Name"
          name="name"
          autoComplete="off"
          id="edit-ingredient-name-input"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
        <Select
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Protein type"
          id="edit-ingredient-protein-type-select"
          onChange={e => {
            setProteinType(e.target.value as any)
          }}
          selectedKeys={[proteinType]}
        >
          {ProteinTypesEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
        <Select
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Secondary protein type"
          id="edit-ingredient-secondary-protein-type-select"
          onChange={e => {
            setSecondaryProteinType(e.target.value as any)
          }}
          selectedKeys={[secondaryProteinType]}
        >
          {SecondaryProteinTypesEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-ingredient-common-pantry-item-radio-group"
          label="Common pantry ingredient"
          onChange={e => {
            setCommonPantryItem(e.target.value === 'true')
          }}
          value={commonPantryItem ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-ingredient-dairy-radio-group"
          label="Dairy"
          onChange={e => {
            setDairy(e.target.value === 'true')
          }}
          value={dairy ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <RadioGroup
          id="edit-ingredient-omit-from-grocery-list-radio-group"
          label="Omit from grocery list"
          onChange={e => {
            setOmitFromGroceryList(e.target.value === 'true')
          }}
          value={omitFromGroceryList ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await IngredientsAPI.update(ingredient.id, {
              name,
              commonPantryItem,
              dairy,
              omitFromGroceryList,
              proteinType,
              secondaryProteinType,
            })
            onSave()
          }}
        >
          Save basic info
        </Button>
      </div>
    </div>
  )
}
