import { useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  SelectItem,
  Chip,
  Input,
} from '@nextui-org/react'
import RecipeVariantStepsAPI from '../../api/recipeVariantSteps'
import Tiptap from '../providers/Tiptap'
import { useCurrentEditor } from '@tiptap/react'
import { IngredientSummary } from '../../api/ingredients'
import { IoAddCircleOutline, IoClose } from 'react-icons/io5'
import AddIngredientToRecipeVariantStepModal from './add-ingredient-to-recipe-variant-step-modal'
import { AdminRecipeVariantDetail, AdminRecipeVariantStep } from '../../api/schema'
import { RecipeVariantStepCookingTipCrud } from '../resources/recipe-variant-step-cooking-tip/crud'

export default function EditRecipeVariantStepModal({
  open,
  onClose,
  onSave,
  recipeVariant,
  recipeVariantStep,
}: {
  open: boolean
  onClose: () => void
  onSave: (data: { body: string; header: string; closeModal: boolean }) => void
  recipeVariant: AdminRecipeVariantDetail
  recipeVariantStep: AdminRecipeVariantStep
}) {
  let [body, setBody] = useState(recipeVariantStep.body)
  let [header, setHeader] = useState(recipeVariantStep.header)
  let [activeMinutes, setActiveMinutes] = useState(recipeVariantStep.activeMinutes)
  let [activeMinutesFactor, setActiveMinutesFactor] = useState(recipeVariantStep.activeMinutesFactor)
  let [passiveMinutes, setPassiveMinutes] = useState(recipeVariantStep.passiveMinutes)
  let [passiveMinutesFactor, setPassiveMinutesFactor] = useState(recipeVariantStep.passiveMinutesFactor)
  let [modalType, setModalType] = useState<EditRecipeVariantStepNestedModalType | null>(null)

  const overrides = recipeVariant.recipeVariantIngredientOverrideOptions
  const ingredients = [
    ...recipeVariant.recipeVariantIngredients.map(rvi => rvi.ingredient),
    ...overrides.map(override => override.ingredient),
  ]

  const overrideIngredientIds = new Set(overrides.map(override => override.ingredient.id))
  const placeholderIngredients = recipeVariant.recipeVariantIngredients
    .filter(rvi => rvi.ingredient.isPlaceholder)
    .map(rvi => rvi.ingredient)

  const substitutes = placeholderIngredients.flatMap(ingredient => ingredient.substitutes)
  const overridingIngredients = recipeVariant.recipeVariantIngredients
    .filter(rvi => overrides.map(o => o.overriddenIngredient.id).includes(rvi.ingredient.id))
    .map(rvi => rvi.ingredient)
  const selectableIngredients = [
    ...[...overrideIngredientIds].map(
      id => overrides.find(override => override.ingredient.id === id)!.ingredient
    ),
    ...overridingIngredients,
    ...substitutes,
  ]

  return (
    <Modal size="3xl" isOpen={open} onClose={onClose} scrollBehavior="inside">
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Edit recipe step</ModalHeader>
            <ModalBody>
              <Tiptap
                id="tiptap-edit-recipe-step-header"
                content={header}
                onUpdate={newHeader => {
                  setHeader(newHeader)
                }}
                label="header"
              />

              <Tiptap
                id="tiptap-edit-recipe-step-body"
                content={body}
                onUpdate={newBody => {
                  setBody(newBody)
                }}
                slotBefore={<MenuBar ingredients={ingredients} />}
                label="body"
              />

              <Input
                value={activeMinutes.toString()}
                id="edit-recipe-step-active-minutes"
                type="number"
                label="Active minutes"
                onChange={e => {
                  setActiveMinutes(parseInt(e.target.value))
                }}
              />

              <Input
                value={activeMinutesFactor.toString()}
                id="edit-recipe-step-active-minutes-factor"
                type="number"
                label="Active minutes factor"
                onChange={e => {
                  setActiveMinutesFactor(parseFloat(e.target.value))
                }}
              />

              <Input
                value={passiveMinutes.toString()}
                id="edit-recipe-step-passive-minutes"
                type="number"
                label="Passive minutes"
                onChange={e => {
                  setPassiveMinutes(parseInt(e.target.value))
                }}
              />

              <Input
                value={passiveMinutesFactor.toString()}
                id="edit-recipe-step-passive-minutes-factor"
                type="number"
                label="Passive minutes factor"
                onChange={e => {
                  setPassiveMinutesFactor(parseFloat(e.target.value))
                }}
              />

              {recipeVariantStep.ingredient ? (
                <>
                  <h3>Exclusive ingredient:</h3>
                  <Chip
                    color={'secondary'}
                    variant="flat"
                    endContent={
                      <IoClose
                        id={`delete-recipe-variant-step-ingredient-${recipeVariantStep.id}`}
                        style={{ marginRight: 5 }}
                        size={12}
                        onClick={async () => {
                          await RecipeVariantStepsAPI.update(recipeVariantStep.id, { ingredientId: null })
                          onSave({ header, body, closeModal: false })
                        }}
                      />
                    }
                    style={{ cursor: 'pointer', marginRight: 10 }}
                  >
                    {recipeVariantStep.ingredient.name}
                  </Chip>
                </>
              ) : (
                <div>
                  <>
                    <h2>Exclusive ingredient</h2>
                    <Button
                      id={`add-recipe-variant-step-ingredient-${recipeVariantStep.id}`}
                      className="text-white"
                      color="success"
                      aria-label="Add recipe"
                      style={{ marginLeft: 'auto' }}
                      onClick={async () => {
                        setModalType('add_ingredient')
                      }}
                    >
                      <IoAddCircleOutline size={20} />
                      Add ingredient dependency
                    </Button>
                  </>
                </div>
              )}

              <AddIngredientToRecipeVariantStepModal
                open={modalType === 'add_ingredient'}
                onClose={() => setModalType(null)}
                onSelect={async ingredient => {
                  await RecipeVariantStepsAPI.update(recipeVariantStep.id, { ingredientId: ingredient.id })
                  onSave({
                    body,
                    header,
                    closeModal: false,
                  })
                  setModalType(null)
                }}
                ingredients={selectableIngredients}
              />

              <RecipeVariantStepCookingTipCrud
                recipeVariantStep={recipeVariantStep}
                recipeVariantStepStepCookingTip={recipeVariantStep.recipeVariantStepCookingTip}
                onSave={async () => {
                  onSave({
                    body,
                    header,
                    closeModal: false,
                  })
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  await RecipeVariantStepsAPI.update(recipeVariantStep.id, {
                    body,
                    header,
                    activeMinutes,
                    passiveMinutes,
                    activeMinutesFactor,
                    passiveMinutesFactor,
                  })
                  onSave({
                    body,
                    header,
                    closeModal: true,
                  })
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export function MenuBar({ ingredients }: { ingredients: IngredientSummary[] }) {
  const { editor } = useCurrentEditor()
  return (
    <div>
      <Select
        style={{ marginBottom: 20 }}
        label="inject ingredient"
        onChange={e => {
          if (!editor) return
          const value = e.target.value
          const position = editor.state.selection.anchor
          editor.commands.deleteSelection()
          editor.commands.insertContentAt(position, `{% ingredient ${value} %}`, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: 'full',
            },
          })
        }}
      >
        {ingredients.map(ingredient => (
          <SelectItem key={ingredient.id} value={ingredient.id}>
            {ingredient.name} ({ingredient.id})
          </SelectItem>
        ))}
      </Select>

      <Select
        style={{ marginBottom: 20 }}
        label="inject tag"
        onChange={e => {
          if (!editor) return
          const tagName = e.target.value
          const position = editor.state.selection.anchor
          editor.commands.deleteSelection()
          editor.commands.insertContentAt(position, `{% ${tagName}  %}`, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: 'full',
            },
          })
          editor
            .chain()
            .focus()
            .setTextSelection(position + tagName.length + 4)
            .run()
        }}
      >
        {['auto_size'].map(tagName => (
          <SelectItem key={tagName} value={tagName}>
            {tagName}
          </SelectItem>
        ))}
      </Select>
    </div>
  )
}

type EditRecipeVariantStepNestedModalType = 'add_ingredient'
