import { useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  SelectItem,
} from '@nextui-org/react'
import RecipeVariantsAPI from '../../api/recipeVariants'
import Tiptap from '../providers/Tiptap'
import { useCurrentEditor } from '@tiptap/react'
import { IngredientSummary } from '../../api/ingredients'
import { AdminRecipeVariantDetail } from '../../api/schema'

export default function AddRecipeVariantStepModal({
  open,
  onClose,
  onSave,
  recipeVariant,
}: {
  open: boolean
  onClose: () => void
  onSave: (data: { body: string; header: string }) => void
  recipeVariant: AdminRecipeVariantDetail
}) {
  let [body, setBody] = useState('')
  let [header, setHeader] = useState('')
  const ingredients = recipeVariant.recipeVariantIngredients.map(rvi => rvi.ingredient)

  return (
    <Modal size="3xl" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Create recipe step</ModalHeader>
            <ModalBody>
              <Tiptap
                id="tiptap-create-recipe-step-header"
                label="header"
                content={header}
                onUpdate={newHeader => {
                  setHeader(newHeader)
                }}
              />
              <Tiptap
                id="tiptap-create-recipe-step-body"
                label="body"
                content={body}
                onUpdate={newBody => {
                  setBody(newBody)
                }}
                slotBefore={<MenuBar ingredients={ingredients} />}
              />
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  await RecipeVariantsAPI.addStep(recipeVariant.id, { body, header })
                  onSave({
                    header,
                    body,
                  })
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export function MenuBar({ ingredients }: { ingredients: IngredientSummary[] }) {
  const { editor } = useCurrentEditor()
  return (
    <Select
      style={{ marginBottom: 20 }}
      label="inject ingredient"
      onChange={e => {
        if (!editor) return
        const value = e.target.value
        const position = editor.state.selection.anchor
        editor.commands.deleteSelection()
        editor.commands.insertContentAt(position, `{% ingredient ${value} %}`, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: 'full',
          },
        })
      }}
    >
      {ingredients.map(ingredient => (
        <SelectItem key={ingredient.id} value={ingredient.id}>
          {ingredient.name} ({ingredient.id})
        </SelectItem>
      ))}
    </Select>
  )
}
