import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import RecipeVariantCookingTipsAPI from '../../api/recipeVariantCookingTips'
import { useParams, useNavigate } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import { AdminRecipeVariantCookingTipSummary } from '../../api/schema'
import RecipeVariantCookingTipForm from '../../components/resources/recipe-variant-cooking-tip/form'
import Loader from '../../components/loader'

export default function RecipeVariantCookingTipsEditPage() {
  const navigate = useNavigate()
  const [recipeVariantCookingTip, setRecipeVariantCookingTip] =
    useState<AdminRecipeVariantCookingTipSummary | null>(null)
  const { id } = useParams()

  const fetchRecipeVariantCookingTip = useCallback(async () => {
    const results = await RecipeVariantCookingTipsAPI.show(id!)
    setRecipeVariantCookingTip(results.data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariantCookingTip()
  }, [id])

  if (!recipeVariantCookingTip) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.index}>Recipe Variant Cooking Tips</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.show(id!)}>{recipeVariantCookingTip.name}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.edit(id!)}>edit</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <h1>{recipeVariantCookingTip.name}</h1>

      <RecipeVariantCookingTipForm
        recipeVariantCookingTip={recipeVariantCookingTip}
        onSave={async () => {
          navigate(routes.app.recipeVariantCookingTips.show(id!))
        }}
      />
    </div>
  )
}
