import { Button, Input, Radio, RadioGroup, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../../api/ingredients'
import FormGroup from '../../../forms/form-group'
import {
  AdminIngredientDetail,
  ImperialGroceryListUnitsEnumValues,
  ImperialUnitsEnumValues,
  MetricGroceryListUnitsEnumValues,
  MetricUnitsEnumValues,
} from '../../../../api/schema'

export default function IngredientMetricsForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  const [costPerImperialGroceryListUnit, setCostPerImperialGroceryListUnit] = useState(
    ingredient.costPerImperialGroceryListUnit
  )
  const [costPerMetricGroceryListUnit, setCostPerMetricGroceryListUnit] = useState(
    ingredient.costPerMetricGroceryListUnit
  )
  const [excludeFromHalfSizeRoundingConstraint, setExcludeFromHalfSizeRoundingConstraint] = useState(
    ingredient.excludeFromHalfSizeRoundingConstraint
  )
  const [gramsPerImperialUnit, setGramsPerImperialUnit] = useState(ingredient.gramsPerImperialUnit)
  const [gramsPerMetricUnit, setGramsPerMetricUnit] = useState(ingredient.gramsPerMetricUnit)
  const [imperialUnit, setImperialUnit] = useState(ingredient.imperialUnit)
  const [imperialGroceryListUnit, setImperialGroceryListUnit] = useState(ingredient.imperialGroceryListUnit)
  const [imperialGroceryListUnitPerImperialUnit, setImperialGroceryListUnitPerImperialUnit] = useState(
    ingredient.imperialGroceryListUnitPerImperialUnit
  )
  const [integerUnits, setIntegerUnits] = useState(ingredient.integerUnits)
  const [metricUnit, setMetricUnit] = useState(ingredient.metricUnit)
  const [metricGroceryListUnit, setMetricGroceryListUnit] = useState(ingredient.metricGroceryListUnit)
  const [metricGroceryListUnitPerMetricUnit, setMetricGroceryListUnitPerMetricUnit] = useState(
    ingredient.metricGroceryListUnitPerMetricUnit
  )

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Metrics</h2>

      <FormGroup>
        <RadioGroup
          id="edit-ingredient-integer-units-radio-group"
          label="Integer units"
          onChange={e => {
            setIntegerUnits(e.target.value === 'true')
          }}
          value={integerUnits ? 'true' : 'false'}
          style={{ display: 'inline-block', width: 200 }}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>

        <RadioGroup
          id="edit-ingredient-exclude-from-half-size-rounding-constraint-radio-group"
          label="Exclude from half size rounding constraint"
          onChange={e => {
            setExcludeFromHalfSizeRoundingConstraint(e.target.value === 'true')
          }}
          value={excludeFromHalfSizeRoundingConstraint ? 'true' : 'false'}
          style={{ display: 'inline-block', width: 400 }}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>

      <FormGroup mb={0}>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Imperial unit"
          id="edit-ingredient-imperial-unit-select"
          onChange={e => {
            setImperialUnit(e.target.value as any)
          }}
          selectedKeys={[imperialUnit]}
        >
          {ImperialUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Imperial grocery list unit"
          id="edit-ingredient-imperial-grocery-list-unit-select"
          onChange={e => {
            setImperialGroceryListUnit(e.target.value as any)
          }}
          selectedKeys={[imperialGroceryListUnit]}
        >
          {ImperialGroceryListUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup mt={0}>
        <Input
          classNames={{
            base: 'input-md align-top',
          }}
          type="number"
          label="Cost per imperial grocery list unit"
          name="costPerImperialGroceryListUnit"
          autoComplete="off"
          id="edit-ingredient-cost-per-imperial-grocery-list-unit-input"
          value={costPerImperialGroceryListUnit?.toString()}
          onChange={async e => {
            setCostPerImperialGroceryListUnit(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Grams per imperial unit"
          name="gramsPerImperialUnit"
          autoComplete="off"
          id="edit-ingredient-grams-per-imperial-unit-input"
          value={gramsPerImperialUnit?.toString()}
          onChange={async e => {
            setGramsPerImperialUnit(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Imperial grocery list unit per imperial unit"
          name="imperialGroceryListUnitPerImperialUnit"
          autoComplete="off"
          id="edit-ingredient-imperial-grocery-list-unit-per-imperial-unit-input"
          value={imperialGroceryListUnitPerImperialUnit?.toString()}
          onChange={async e => {
            setImperialGroceryListUnitPerImperialUnit(parseFloat(e.target.value))
          }}
        />
      </FormGroup>

      <FormGroup mb={0}>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Metric unit"
          id="edit-ingredient-metric-unit-select"
          onChange={e => {
            setMetricUnit(e.target.value as any)
          }}
          selectedKeys={[metricUnit]}
        >
          {MetricUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
        <Select
          style={{ marginBottom: 20 }}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          label="Metric grocery list unit"
          id="edit-ingredient-metric-grocery-list-unit-select"
          onChange={e => {
            setMetricGroceryListUnit(e.target.value as any)
          }}
          selectedKeys={[metricGroceryListUnit]}
        >
          {MetricGroceryListUnitsEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup mt={0}>
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Cost per metric grocery list unit"
          name="costPerMetricGroceryListUnit"
          autoComplete="off"
          id="edit-ingredient-cost-per-metric-grocery-list-unit-input"
          value={costPerMetricGroceryListUnit?.toString()}
          onChange={async e => {
            setCostPerMetricGroceryListUnit(parseFloat(e.target.value))
          }}
          style={{ display: 'inline-block' }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Grams per metric unit"
          name="gramsPerMetricUnit"
          autoComplete="off"
          id="edit-ingredient-grams-per-metric-unit-input"
          value={gramsPerMetricUnit?.toString()}
          onChange={async e => {
            setGramsPerMetricUnit(parseFloat(e.target.value))
          }}
        />
        <Input
          type="number"
          classNames={{
            base: 'input-md align-top',
          }}
          label="Metric grocery list unit per metric unit"
          name="metricGroceryListUnitPerMetricUnit"
          autoComplete="off"
          id="edit-ingredient-metric-grocery-list-unit-per-metric-unit-input"
          value={metricGroceryListUnitPerMetricUnit?.toString()}
          onChange={async e => {
            setMetricGroceryListUnitPerMetricUnit(parseFloat(e.target.value))
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await IngredientsAPI.update(ingredient.id, {
              costPerImperialGroceryListUnit,
              costPerMetricGroceryListUnit,
              excludeFromHalfSizeRoundingConstraint,
              gramsPerImperialUnit,
              gramsPerMetricUnit,
              imperialGroceryListUnit,
              imperialGroceryListUnitPerImperialUnit,
              imperialUnit,
              integerUnits,
              metricUnit,
              metricGroceryListUnit,
              metricGroceryListUnitPerMetricUnit,
            })
            onSave()
          }}
        >
          Save metrics
        </Button>
      </div>
    </div>
  )
}
