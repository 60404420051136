import { Button, Radio, RadioGroup, Select, SelectItem, Textarea } from '@nextui-org/react'
import { useState } from 'react'
import IngredientsAPI from '../../../../api/ingredients'
import FormGroup from '../../../forms/form-group'
import {
  AdminIngredientDetail,
  PlaceholderTypesEnum,
  PlaceholderTypesEnumValues,
  SubstituteBreadTypesEnum,
  SubstituteBreadTypesEnumValues,
  SubstituteMilkTypesEnum,
  SubstituteMilkTypesEnumValues,
  SubstitutePastaTypesEnum,
  SubstitutePastaTypesEnumValues,
  SubstitutePreChoppedVegetableTypesEnum,
  SubstitutePreChoppedVegetableTypesEnumValues,
  SubstitutePreCookedProteinTypesEnum,
  SubstitutePreCookedProteinTypesEnumValues,
  SubstituteRiceTypesEnum,
  SubstituteRiceTypesEnumValues,
} from '../../../../api/schema'

export default function IngredientSubstitutionForm({
  ingredient,
  onSave,
}: {
  ingredient: AdminIngredientDetail
  onSave: () => void
}) {
  const [defaultSubstituteForDietaryPreferences, setDefaultSubstituteForDietaryPreferences] = useState(
    ingredient.defaultSubstituteForDietaryPreferences
  )
  const [directlyIncludableSubstitute, setDirectlyIncludableSubstitute] = useState(
    ingredient.directlyIncludableSubstitute
  )
  const [placeholderType, setPlaceholderType] = useState<PlaceholderTypesEnum | null>(
    ingredient.placeholderType
  )
  const [substituteBreadType, setSubstituteBreadType] = useState<SubstituteBreadTypesEnum | null>(
    ingredient.substituteBreadType
  )
  const [substituteForDietaryPreference, setSubstituteForDietaryPreference] = useState(
    ingredient.substituteForDietaryPreference
  )
  const [substituteMilkType, setSubstituteMilkType] = useState<SubstituteMilkTypesEnum | null>(
    ingredient.substituteMilkType
  )
  const [substitutePastaType, setSubstitutePastaType] = useState<SubstitutePastaTypesEnum | null>(
    ingredient.substitutePastaType
  )
  const [substitutePreChoppedVegetableType, setSubstitutePreChoppedVegetableType] =
    useState<SubstitutePreChoppedVegetableTypesEnum | null>(ingredient.substitutePreChoppedVegetableType)
  const [substitutePreCookedProteinType, setSubstitutePreCookedProteinType] =
    useState<SubstitutePreCookedProteinTypesEnum | null>(ingredient.substitutePreCookedProteinType)
  const [substituteRiceType, setSubstituteRiceType] = useState<SubstituteRiceTypesEnum | null>(
    ingredient.substituteRiceType
  )
  const [substitutionNotes, setSubstitutionNotes] = useState(ingredient.substitutionNotes)

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Substitution</h2>

      {!substituteBreadType &&
        !substituteMilkType &&
        !substitutePastaType &&
        !substitutePreChoppedVegetableType &&
        !substitutePreCookedProteinType &&
        !substituteRiceType && (
          <FormGroup>
            <Select
              classNames={{
                base: 'select-box-container-sm align-top',
              }}
              label="Placeholder type"
              id="edit-ingredient-placeholder-type-select"
              onChange={e => {
                if (e.target.value === 'none') setPlaceholderType(null)
                else setPlaceholderType(e.target.value as any)
              }}
              selectedKeys={placeholderType ? [placeholderType] : ['none']}
            >
              {['none', ...PlaceholderTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </FormGroup>
        )}

      {!placeholderType && (
        <>
          <FormGroup>
            <Select
              classNames={{
                base: 'select-box-container-sm align-top',
              }}
              label="Substitute milk type"
              id="edit-ingredient-substitute-milk-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstituteMilkType(null)
                else setSubstituteMilkType(e.target.value as any)
              }}
              selectedKeys={substituteMilkType ? [substituteMilkType] : ['none']}
            >
              {['none', ...SubstituteMilkTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
            <Select
              classNames={{
                base: 'select-box-container-sm align-top',
              }}
              label="Substitute pasta type"
              id="edit-ingredient-substitute-pasta-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstitutePastaType(null)
                else setSubstitutePastaType(e.target.value as any)
              }}
              selectedKeys={substitutePastaType ? [substitutePastaType] : ['none']}
            >
              {['none', ...SubstitutePastaTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
            <Select
              classNames={{
                base: 'select-box-container-sm align-top',
              }}
              label="Substitute rice type"
              id="edit-ingredient-substitute-rice-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstituteRiceType(null)
                else setSubstituteRiceType(e.target.value as any)
              }}
              selectedKeys={substituteRiceType ? [substituteRiceType] : ['none']}
            >
              {['none', ...SubstituteRiceTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
            <Select
              classNames={{
                base: 'select-box-container-sm align-top',
              }}
              label="Substitute bread type"
              id="edit-ingredient-substitute-bread-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstituteBreadType(null)
                else setSubstituteBreadType(e.target.value as any)
              }}
              selectedKeys={substituteBreadType ? [substituteBreadType] : ['none']}
            >
              {['none', ...SubstituteBreadTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Select
              classNames={{
                base: 'select-box-container-md align-top',
              }}
              label="Substitute pre-chopped vegetable type"
              id="edit-ingredient-substitute-pre-chopped-vegetable-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstitutePreChoppedVegetableType(null)
                else setSubstitutePreChoppedVegetableType(e.target.value as any)
              }}
              selectedKeys={
                substitutePreChoppedVegetableType ? [substitutePreChoppedVegetableType] : ['none']
              }
            >
              {['none', ...SubstitutePreChoppedVegetableTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
            <Select
              classNames={{
                base: 'select-box-container-md align-top',
              }}
              label="Substitute pre-cooked protein type"
              id="edit-ingredient-substitute-pre-cooked-protein-type-select"
              onChange={e => {
                if (e.target.value === 'none') setSubstitutePreCookedProteinType(null)
                else setSubstitutePreCookedProteinType(e.target.value as any)
              }}
              selectedKeys={substitutePreCookedProteinType ? [substitutePreCookedProteinType] : ['none']}
            >
              {['none', ...SubstitutePreCookedProteinTypesEnumValues].map(value => (
                <SelectItem key={value} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <RadioGroup
              id="edit-ingredient-substitute-for-dietary-preference-radio-group"
              label="Substitute for dietary preference"
              onChange={e => {
                setSubstituteForDietaryPreference(e.target.value === 'true')
              }}
              value={substituteForDietaryPreference ? 'true' : 'false'}
            >
              <Radio value="true">true</Radio>
              <Radio value="false">false</Radio>
            </RadioGroup>
          </FormGroup>

          <FormGroup>
            <RadioGroup
              id="edit-ingredient-default-substitute-for-dietary-preferences-radio-group"
              label="Default substitute for dietary preferences"
              onChange={e => {
                setDefaultSubstituteForDietaryPreferences(e.target.value === 'true')
              }}
              value={defaultSubstituteForDietaryPreferences ? 'true' : 'false'}
            >
              <Radio value="true">true</Radio>
              <Radio value="false">false</Radio>
            </RadioGroup>
          </FormGroup>

          <FormGroup>
            <RadioGroup
              id="edit-ingredient-directly-includable-substitute-radio-group"
              label="Directly includable substitute"
              onChange={e => {
                setDirectlyIncludableSubstitute(e.target.value === 'true')
              }}
              value={directlyIncludableSubstitute ? 'true' : 'false'}
            >
              <Radio value="true">true</Radio>
              <Radio value="false">false</Radio>
            </RadioGroup>
          </FormGroup>

          <FormGroup>
            <Textarea
              type="text"
              label="Substitution notes"
              name="substitutionNotes"
              autoComplete="off"
              id="edit-ingredient-substitution-notes-input"
              labelPlacement="outside"
              value={substitutionNotes}
              onChange={async e => {
                setSubstitutionNotes(e.target.value)
              }}
            />
          </FormGroup>
        </>
      )}

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await IngredientsAPI.update(ingredient.id, {
              placeholderType,
              defaultSubstituteForDietaryPreferences,
              directlyIncludableSubstitute,
              substituteForDietaryPreference,
              substituteBreadType: placeholderType ? null : substituteBreadType,
              substituteMilkType: placeholderType ? null : substituteMilkType,
              substitutePastaType: placeholderType ? null : substitutePastaType,
              substitutePreChoppedVegetableType: placeholderType ? null : substitutePreChoppedVegetableType,
              substitutePreCookedProteinType: placeholderType ? null : substitutePreCookedProteinType,
              substituteRiceType: placeholderType ? null : substituteRiceType,
              substitutionNotes: placeholderType ? null : substitutionNotes,
            })
            onSave()
          }}
        >
          Save substitution
        </Button>
      </div>
    </div>
  )
}
