import { useCallback, useEffect, useState } from 'react'
import RecipeVariantCookingTipsAPI from '../../api/recipeVariantCookingTips'
import { useParams, NavLink } from 'react-router-dom'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import routes from '../../config/routes'
import { IoPencil } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import useMountEffect from '../../hooks/useMountEffect'
import Loader from '../../components/loader'
import { AdminRecipeVariantCookingTipSummary } from '../../api/schema'

export default function RecipeVariantCookingTipsShowPage() {
  const [recipeVariantCookingTip, setRecipeVariantCookingTip] =
    useState<AdminRecipeVariantCookingTipSummary | null>(null)
  const { id } = useParams()

  const fetchRecipeVariantCookingTips = useCallback(async () => {
    const results = await RecipeVariantCookingTipsAPI.show(id!)
    setRecipeVariantCookingTip(results.data)
  }, [])

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariantCookingTips()
  }, [id])

  if (!recipeVariantCookingTip) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.index}>Recipe Variant Cooking Tips</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.show(id!)}>{recipeVariantCookingTip.name}</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>{recipeVariantCookingTip.name}</h1>

        <Button
          as={Link}
          to={routes.app.recipeVariantCookingTips.edit(id!)}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoPencil size={20} />
          Edit
        </Button>
      </div>

      <Table
        isHeaderSticky
        aria-label="fields"
        classNames={{
          base: 'table-base search',
        }}
      >
        <TableHeader>
          <TableColumn>FIELD</TableColumn>
          <TableColumn>VALUE</TableColumn>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{recipeVariantCookingTip.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Body</TableCell>
            <TableCell>{recipeVariantCookingTip.body}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
