import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import RecipeBrowseCategoriesAPI from '../../api/recipeBrowseCategories'
import { useParams, useNavigate } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import { AdminRecipeBrowseCategoryDetail } from '../../api/schema'
import RecipeBrowseCategoryForm from '../../components/resources/recipe-browse-category/form'
import Loader from '../../components/loader'

export default function RecipeBrowseCategoriesEditPage() {
  const navigate = useNavigate()
  const [recipeBrowseCategory, setRecipeBrowseCategory] = useState<AdminRecipeBrowseCategoryDetail | null>(
    null
  )
  const { id } = useParams()

  const fetchRecipeBrowseCategory = useCallback(async () => {
    const results = await RecipeBrowseCategoriesAPI.show(id!)
    setRecipeBrowseCategory(results.data)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchRecipeBrowseCategory()
  }, [id])

  if (!recipeBrowseCategory) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.index}>Recipe Browse Categories</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.show(id!)}>{recipeBrowseCategory.name}</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.edit(id!)}>edit</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <h1>{recipeBrowseCategory.name}</h1>

      <RecipeBrowseCategoryForm
        recipeBrowseCategory={recipeBrowseCategory}
        onSave={async () => {
          await fetchRecipeBrowseCategory()
          navigate(routes.app.recipeBrowseCategories.show(id!))
        }}
      />
    </div>
  )
}
