import { useState } from 'react'
import { IngredientSummary } from '../../api/ingredients'
import {
  BreadcrumbItem,
  Breadcrumbs,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import { Link, NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import IngredientSearch from '../../components/resources/ingredient/search'
import { IoAdd } from 'react-icons/io5'

export default function IngredientsIndexPage() {
  const [ingredients, setIngredients] = useState<IngredientSummary[]>([])

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.ingredients.index}>Ingredients</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>Ingredients</h1>
        <Button
          as={Link}
          to={routes.app.ingredients.new}
          className="text-white"
          color="success"
          aria-label="Edit"
          style={{ marginLeft: 'auto' }}
        >
          <IoAdd size={20} />
          New ingredient
        </Button>
      </div>

      <IngredientSearch onChange={ingredients => setIngredients(ingredients)}>
        <Table
          aria-label="Recipes"
          classNames={{
            base: 'table-base search',
          }}
          shadow="none"
        >
          <TableHeader>
            <TableColumn>ID</TableColumn>
            <TableColumn>NAME</TableColumn>
          </TableHeader>

          <TableBody
            style={{
              maxHeight: 'calc(100vh - 400px)',
            }}
          >
            {ingredients.map((ingredient, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: 40 }}>
                  <NavLink
                    to={routes.app.ingredients.show(ingredient.id)}
                    style={{ position: 'relative', top: '-4px' }}
                  >
                    {ingredient.id}
                  </NavLink>
                </TableCell>
                <TableCell style={{ verticalAlign: 'center' }}>
                  <NavLink
                    to={routes.app.ingredients.show(ingredient.id)}
                    style={{ marginLeft: 10, display: 'inline-block', verticalAlign: 'top' }}
                  >
                    {ingredient.name}
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </IngredientSearch>
    </div>
  )
}
