import routes from '../config/routes'
import { api } from './common'

export default class RecipeVariantStepCookingTipsAPI {
  static index() {
    return api.get(routes.api.recipeVariantStepCookingTips.index)
  }

  static create(attrs: Partial<RecipeVariantStepCookingTipParams>) {
    return api.post(routes.api.recipeVariantStepCookingTips.create, attrs)
  }

  static show(id: string) {
    return api.get(routes.api.recipeVariantStepCookingTips.show(id))
  }

  static update(id: string, attrs: Partial<RecipeVariantStepCookingTipParams>) {
    return api.patch(routes.api.recipeVariantStepCookingTips.update(id), attrs)
  }

  static destroy(id: string) {
    return api.delete(routes.api.recipeVariantStepCookingTips.destroy(id))
  }
}

export interface RecipeVariantStepCookingTipParams {
  name: string
  body: string
}
