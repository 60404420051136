import React, { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { IoAddCircleOutline, IoCheckmark } from 'react-icons/io5'
import RecipeSearch from '../resources/recipe/search'
import { AdminRecipeSummary } from '../../api/schema'

export default function AddRecipeModal({
  open,
  onClose,
  onSelect,
  addedRecipeIds = [],
}: {
  open: boolean
  onClose: () => void
  onSelect: (recipe: AdminRecipeSummary) => void
  addedRecipeIds?: string[]
}) {
  const [recipes, setRecipes] = useState<AdminRecipeSummary[]>([])

  return (
    <Modal size={'full'} isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Select a recipe</ModalHeader>
            <ModalBody>
              <RecipeSearch<AdminRecipeSummary> onChange={recipes => setRecipes(recipes)}>
                <div
                  style={{
                    maxHeight: 'calc(100vh - 500px)',
                    overflowY: 'auto',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {recipes.map((recipe, index) => (
                    <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                      <Button
                        disabled={addedRecipeIds.includes(recipe.id)}
                        startContent={
                          addedRecipeIds.includes(recipe.id) ? (
                            <IoCheckmark size={20} />
                          ) : (
                            <IoAddCircleOutline size={20} />
                          )
                        }
                        variant="bordered"
                        color={addedRecipeIds.includes(recipe.id) ? 'success' : 'secondary'}
                        onClick={async () => {
                          onSelect(recipe)
                        }}
                      >
                        {recipe.name}
                      </Button>
                    </div>
                  ))}
                </div>
              </RecipeSearch>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
