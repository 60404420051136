import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminRecipeBrowseCategorySummary } from '../../api/schema'
import routes from '../../config/routes'
import { NavLink } from 'react-router-dom'
import useMountEffect from '../../hooks/useMountEffect'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import RecipeBrowseCategoriesAPI from '../../api/recipeBrowseCategories'
import Loader from '../../components/loader'

export default function RecipeBrowseCategoriesIndexPage() {
  const navigate = useNavigate()
  const [recipeBrowseCategories, setRecipeBrowseCategories] = useState<AdminRecipeBrowseCategorySummary[]>([])

  const fetchRecipeBrowseCategories = async () => {
    const results = await RecipeBrowseCategoriesAPI.index()
    setRecipeBrowseCategories(results.data)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeBrowseCategories()
  }, [])

  if (!recipeBrowseCategories.length) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeBrowseCategories.index}>Recipe Browse Categories</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <Table
        aria-label="Filters"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
        </TableHeader>

        <TableBody>
          {recipeBrowseCategories.map((recipeBrowseCategory, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: '60px' }}>{recipeBrowseCategory.id}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipeBrowseCategories.show(recipeBrowseCategory.id)}>
                  {recipeBrowseCategory.name}
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
