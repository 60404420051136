import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { IngredientSummary } from '../../api/ingredients'
import { IoAddCircleOutline } from 'react-icons/io5'

export default function AddIngredientToRecipeVariantStepModal({
  open,
  onClose,
  onSelect,
  ingredients,
  label = 'Select ingredient',
}: {
  open: boolean
  onClose: () => void
  onSelect: (ingredient: IngredientSummary) => void
  ingredients: IngredientSummary[]
  label?: string
}) {
  return (
    <Modal size={'full'} isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">{label}</ModalHeader>
            <ModalBody>
              <div
                style={{
                  maxHeight: 'calc(100vh - 500px)',
                  overflowY: 'auto',
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                {ingredients.map((ingredient, index) => (
                  <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                    <Button
                      startContent={<IoAddCircleOutline size={20} />}
                      variant="bordered"
                      color={'secondary'}
                      onClick={async () => {
                        onSelect(ingredient)
                      }}
                    >
                      {ingredient.name}
                    </Button>
                  </div>
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
