import { useCallback, useEffect, useState } from 'react'
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react'
import { NavLink } from 'react-router-dom'
import routes from '../../config/routes'
import RecipeCollectionSeriesAPI, { RecipeCollectionSeriesSummary } from '../../api/recipeCollectionSeries'
import RecipeCollectionSeriesCard from '../../components/resources/recipe-collection-series/card'
import Loader from '../../components/loader'

export default function RecipeCollectionSeriesEditPage() {
  const [fetching, setFetching] = useState(true)
  const [series, setSeries] = useState<RecipeCollectionSeriesSummary[]>([])

  const fetchSeries = useCallback(async () => {
    const results = await RecipeCollectionSeriesAPI.index()
    setSeries(results.data.recipeCollectionSeries)
    setFetching(false)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    fetchSeries()
  }, [])

  if (fetching) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeCollectionSeries.edit}>Recipe Collection Series</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      {series.map((singleSeries, key) => (
        <RecipeCollectionSeriesCard key={key} series={singleSeries} seriesNumber={key + 1} />
      ))}
    </div>
  )
}
