import { Button, Input } from '@nextui-org/react'
import { useState } from 'react'
import FormGroup from '../../forms/form-group'
import { AdminRecipeBrowseCategoryDetail } from '../../../api/schema'
import RecipeBrowseCategoriesAPI from '../../../api/recipeBrowseCategories'
import RecipeBrowseCategoryFilters from './filters'

export default function RecipeBrowseCategoryForm({
  recipeBrowseCategory,
  onSave,
}: {
  recipeBrowseCategory: AdminRecipeBrowseCategoryDetail
  onSave: () => void
}) {
  const [name, setName] = useState(recipeBrowseCategory.name)
  const [filters, setFilters] = useState(recipeBrowseCategory.filters)

  return (
    <div style={{ marginTop: 100 }}>
      <FormGroup>
        <Input
          type="text"
          label="Name"
          name="name"
          autoComplete="off"
          id="edit-recipe-browse-category-name-input"
          labelPlacement="outside"
          value={name}
          onChange={async e => {
            setName(e.target.value)
          }}
        />
      </FormGroup>

      <RecipeBrowseCategoryFilters
        recipeBrowseCategory={recipeBrowseCategory}
        onChange={newFilters => {
          setFilters(newFilters)
        }}
      />

      <Button
        onPress={async () => {
          await RecipeBrowseCategoriesAPI.update(recipeBrowseCategory.id, {
            name,
            filters,
          })
          onSave()
        }}
      >
        Save
      </Button>
    </div>
  )
}
