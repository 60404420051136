import { useState } from 'react'
import { AdminRecipeBrowseCategorySummary } from '../../api/schema'
import routes from '../../config/routes'
import { NavLink, useNavigate } from 'react-router-dom'
import useMountEffect from '../../hooks/useMountEffect'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import RecipeVariantCookingTipsAPI from '../../api/recipeVariantCookingTips'
import Loader from '../../components/loader'
import { IoAddCircleOutline } from 'react-icons/io5'
import EditRecipeVariantCookingTipModal from '../../components/modals/edit-recipe-variant-cooking-tip-modal'

export default function RecipeVariantCookingTipsIndexPage() {
  const navigate = useNavigate()
  const [recipeVariantCookingTips, setRecipeVariantCookingTips] = useState<
    AdminRecipeBrowseCategorySummary[]
  >([])
  const [fetching, setFetching] = useState(true)
  const [modalType, setModalType] = useState<RecipeVariantCookingTipsIndexModalType>(null)

  const fetchRecipeVariantCookingTips = async () => {
    const results = await RecipeVariantCookingTipsAPI.index()
    setRecipeVariantCookingTips(results.data)
    setFetching(false)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariantCookingTips()
  }, [])

  if (fetching) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantCookingTips.index}>Recipe Variant Cooking Tips</NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>Recipe Variant Cooking Tips</h1>
        <Button
          className="text-white"
          color="success"
          aria-label="Add cooking tip"
          style={{ marginLeft: 'auto' }}
          onClick={async () => {
            setModalType('add_cooking_tip')
          }}
        >
          <IoAddCircleOutline size={20} />
          Add Cooking Tip
        </Button>
      </div>

      <Table
        aria-label="cooking tips"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
        </TableHeader>

        <TableBody>
          {recipeVariantCookingTips.map((recipeBrowseCategory, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: '60px' }}>{recipeBrowseCategory.id}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipeVariantCookingTips.show(recipeBrowseCategory.id)}>
                  {recipeBrowseCategory.name}
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <EditRecipeVariantCookingTipModal
        open={modalType === 'add_cooking_tip'}
        onClose={() => {
          setModalType(null)
        }}
        onSave={({ id, closeModal }) => {
          if (closeModal) setModalType(null)
          navigate(routes.app.recipeVariantCookingTips.show(id))
        }}
      />
    </div>
  )
}

type RecipeVariantCookingTipsIndexModalType = 'add_cooking_tip' | null
