import { useState } from 'react'
import { AdminRecipeBrowseCategorySummary } from '../../api/schema'
import routes from '../../config/routes'
import { NavLink, useNavigate } from 'react-router-dom'
import useMountEffect from '../../hooks/useMountEffect'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@nextui-org/react'
import RecipeVariantStepCookingTipsAPI from '../../api/recipeVariantStepCookingTips'
import Loader from '../../components/loader'
import { IoAddCircleOutline } from 'react-icons/io5'
import EditRecipeVariantStepCookingTipModal from '../../components/modals/edit-recipe-variant-step-cooking-tip-modal'

export default function RecipeVariantStepCookingTipsIndexPage() {
  const navigate = useNavigate()
  const [recipeVariantStepCookingTips, setRecipeVariantStepCookingTips] = useState<
    AdminRecipeBrowseCategorySummary[]
  >([])
  const [fetching, setFetching] = useState(true)
  const [modalType, setModalType] = useState<RecipeVariantStepCookingTipsIndexModalType>(null)

  const fetchRecipeVariantStepCookingTips = async () => {
    const results = await RecipeVariantStepCookingTipsAPI.index()
    setRecipeVariantStepCookingTips(results.data)
    setFetching(false)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchRecipeVariantStepCookingTips()
  }, [])

  if (fetching) return <Loader />

  return (
    <div>
      <Breadcrumbs classNames={{ base: 'breadcrumbs' }}>
        <BreadcrumbItem>
          <NavLink to={routes.app.home}>Home</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.app.recipeVariantStepCookingTips.index}>
            Recipe Variant Step Cooking Tips
          </NavLink>
        </BreadcrumbItem>
      </Breadcrumbs>

      <div className="flex gap-4 items-center">
        <h1>Recipe Variant Step Cooking Tips</h1>
        <Button
          className="text-white"
          color="success"
          aria-label="Add cooking tip"
          style={{ marginLeft: 'auto' }}
          onClick={async () => {
            setModalType('add_cooking_tip')
          }}
        >
          <IoAddCircleOutline size={20} />
          Add Cooking Tip
        </Button>
      </div>

      <Table
        aria-label="Recipe Variant Step Cooking Tips"
        classNames={{
          base: 'table-base',
        }}
        shadow="none"
      >
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>NAME</TableColumn>
        </TableHeader>

        <TableBody>
          {recipeVariantStepCookingTips.map((cookingTip, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: '60px' }}>{cookingTip.id}</TableCell>
              <TableCell>
                <NavLink to={routes.app.recipeVariantStepCookingTips.show(cookingTip.id)}>
                  {cookingTip.name}
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <EditRecipeVariantStepCookingTipModal
        open={modalType === 'add_cooking_tip'}
        onClose={() => {
          setModalType(null)
        }}
        onSave={({ id, closeModal }) => {
          if (closeModal) setModalType(null)
          navigate(routes.app.recipeVariantStepCookingTips.show(id))
        }}
      />
    </div>
  )
}

type RecipeVariantStepCookingTipsIndexModalType = 'add_cooking_tip' | null
