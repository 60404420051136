import routes from '../config/routes'
import { api } from './common'
import { IngredientSummary } from './ingredients'

export default class RecipeVariantStepsAPI {
  static update(id: string, attrs: Partial<RecipeVariantStepParams>) {
    return api.patch(routes.api.recipeVariantSteps.update(id), attrs)
  }

  static destroy(id: string) {
    return api.delete(routes.api.recipeVariantSteps.destroy(id))
  }
}

export interface RecipeVariantStepSummary {
  id: string
  name: string
  position: number
  header: string
  body: string
  renderedBody: string
  activeMinutes: number
  passiveMinutes: number
  activeMinutesFactor: number
  passiveMinutesFactor: number
  ingredient: IngredientSummary | null
}

export interface RecipeVariantStepParams {
  name: string
  position: number
  header: string
  body: string
  ingredientId: string | null
  activeMinutes: number
  activeMinutesFactor: number
  passiveMinutes: number
  passiveMinutesFactor: number
  recipeVariantStepCookingTipId: string | null
}
