import { useState } from 'react'
import { Radio, RadioGroup, Select, SelectItem, Input } from '@nextui-org/react'
import { cuisines } from '../../../api/recipes'
import AppliancesAPI, { ApplianceSummary } from '../../../api/appliances'
import useMountEffect from '../../../hooks/useMountEffect'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import DietaryPreferencesAPI, { DietaryPreferenceSummary } from '../../../api/dietaryPreferences'
import {
  AdminRecipeBrowseCategoryDetail,
  CuisinesEnum,
  DishTypesEnum,
  DishTypesEnumValues,
  MealTypesEnum,
  MealTypesEnumValues,
  SubCuisinesEnum,
} from '../../../api/schema'
import { RecipeVariantFilterData } from '../../../api/recipeBrowseCategories'
import FormGroup from '../../forms/form-group'

export default function RecipeBrowseCategoryFilters({
  recipeBrowseCategory,
  onChange,
  idPrefix = '',
}: {
  recipeBrowseCategory: AdminRecipeBrowseCategoryDetail
  onChange: (newFilters: RecipeVariantFilterData) => void
  idPrefix?: string
}) {
  const existingFilters = recipeBrowseCategory.filters as RecipeVariantFilterData

  const [query, setQuery] = useState<string>(recipeBrowseCategory.filters?.query || '')
  const [ingredientQuery, setIngredientQuery] = useState<string>(
    recipeBrowseCategory.filters?.ingredientQuery || ''
  )
  const [appliances, setAppliances] = useState<ApplianceSummary[]>([])
  const [dietaryPreferences, setDietaryPreferences] = useState<DietaryPreferenceSummary[]>([])

  const [selectedCuisines, setSelectedCuisines] = useState<CuisinesEnum[]>(
    existingFilters?.filters?.cuisines || []
  )
  const [mealTypes, setMealTypes] = useState<MealTypesEnum[]>(existingFilters?.filters?.mealTypes || [])
  const [dishTypes, setDishTypes] = useState<DishTypesEnum[]>(existingFilters?.filters?.dishTypes || [])
  const [applianceId, setApplianceId] = useState<string | null>(existingFilters?.filters?.applianceId || null)
  const [dietaryPreferenceId, setDietaryPreferenceId] = useState<string | null>(
    existingFilters?.filters?.dietaryPreferenceId || null
  )
  const [totalTime, setTotalTime] = useState<string | null>(
    existingFilters?.filters?.totalTime?.toString() || null
  )
  const [activeTime, setActiveTime] = useState<string | null>(
    existingFilters?.filters?.activeTime?.toString() || null
  )
  const [conformToUserPreferences, setConformToUserPreferences] = useState<boolean | null>(
    existingFilters?.filters?.conformToUserPreferences || false
  )

  const fetchAppliances = async () => {
    const results = await AppliancesAPI.index()
    setAppliances(results.data)
  }

  const fetchDietaryPreferences = async () => {
    const results = await DietaryPreferencesAPI.index()
    setDietaryPreferences(results.data)
  }

  useMountEffect(() => {
    // eslint-disable-next-line
    fetchAppliances()
    // eslint-disable-next-line
    fetchDietaryPreferences()
  }, [])

  useUpdateEffect(() => {
    onChange({
      query: query ? query : undefined,
      ingredientQuery: ingredientQuery ? ingredientQuery : undefined,
      filters: {
        mealTypes: mealTypes.length ? mealTypes : undefined,
        cuisines: selectedCuisines.length ? selectedCuisines : undefined,
        dishTypes: dishTypes.length ? dishTypes : undefined,
        applianceId: applianceId || undefined,
        dietaryPreferenceId: dietaryPreferenceId || undefined,
        totalTime: totalTime ? parseInt(totalTime) : undefined,
        activeTime: activeTime ? parseInt(activeTime) : undefined,
        conformToUserPreferences: conformToUserPreferences ? true : undefined,
      },
    })
  }, [
    applianceId,
    dietaryPreferenceId,
    selectedCuisines,
    mealTypes,
    dishTypes,
    totalTime,
    activeTime,
    conformToUserPreferences,
    query,
    ingredientQuery,
  ])

  const times = [10, 20, 40, 60, 80, 120]

  return (
    <div style={{ marginTop: 20, marginBottom: 60 }}>
      <FormGroup>
        <Input
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-query-input`}
          label="Query"
          onChange={e => {
            setQuery(e.target.value)
          }}
          value={query}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-ingredient-query-input`}
          label="Ingredient query"
          onChange={e => {
            setIngredientQuery(e.target.value)
          }}
          value={ingredientQuery}
        />
      </FormGroup>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Select
          selectionMode="multiple"
          fullWidth={false}
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-cuisines`}
          label="cuisines"
          style={{ marginRight: 10 }}
          onChange={e => {
            const values = e.target.value.split(',').filter(val => !!val)
            setSelectedCuisines(values as any)
          }}
          selectedKeys={selectedCuisines}
        >
          {cuisines.map(cuisine => (
            <SelectItem key={cuisine as any} value={cuisine}>
              {cuisine}
            </SelectItem>
          ))}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-dish-types`}
          selectionMode="multiple"
          label="dish types"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            const values = e.target.value.split(',').filter(val => !!val)
            setDishTypes(values as any)
          }}
          selectedKeys={dishTypes}
        >
          {DishTypesEnumValues.map(dishType => (
            <SelectItem key={dishType} value={dishType}>
              {dishType}
            </SelectItem>
          ))}
        </Select>

        <Select
          selectionMode="multiple"
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-meal-types`}
          label="meal types"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            const values = e.target.value.split(',').filter(val => !!val)
            setMealTypes(values as any)
          }}
          selectedKeys={mealTypes}
        >
          {MealTypesEnumValues.map(mealType => (
            <SelectItem key={mealType} value={mealType}>
              {mealType}
            </SelectItem>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: 60 }}>
        <Select
          fullWidth={false}
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-appliance`}
          label="appliance"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setApplianceId(null)
            else setApplianceId(e.target.value)
          }}
          selectedKeys={applianceId ? [applianceId] : ['none']}
        >
          {['none', ...appliances].map(appliance =>
            appliance === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={(appliance as ApplianceSummary).id} value={(appliance as ApplianceSummary).id}>
                {(appliance as ApplianceSummary).name}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-dietary-preference`}
          label="dietary preference"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setDietaryPreferenceId(null)
            else setDietaryPreferenceId(e.target.value)
          }}
          selectedKeys={dietaryPreferenceId ? [dietaryPreferenceId] : ['none']}
        >
          {['none', ...dietaryPreferences].map(dietaryPreference =>
            dietaryPreference === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem
                key={(dietaryPreference as DietaryPreferenceSummary).id}
                value={(dietaryPreference as DietaryPreferenceSummary).id}
              >
                {(dietaryPreference as DietaryPreferenceSummary).name}
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-total-time`}
          label="total time"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            console.log(e.target.value)
            if (e.target.value === 'none') setTotalTime(null)
            else setTotalTime(e.target.value as any)
          }}
          selectedKeys={totalTime ? [totalTime] : ['none']}
        >
          {['none', ...times].map(mins =>
            mins === 'none' ? (
              <SelectItem key="none" value="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mins} value={mins} textValue={`less than ${mins} minutes`}>
                less than {mins} minutes
              </SelectItem>
            )
          )}
        </Select>

        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}recipe-browse-category-select-active-time`}
          label="active time"
          classNames={{
            base: 'select-box-container-sm align-top',
          }}
          style={{ marginRight: 10 }}
          onChange={e => {
            if (e.target.value === 'none') setActiveTime(null)
            else setActiveTime(e.target.value as any)
          }}
          selectedKeys={activeTime ? [activeTime] : ['none']}
        >
          {['none', ...times].map(mins =>
            mins === 'none' ? (
              <SelectItem key="none" textValue="none">
                None
              </SelectItem>
            ) : (
              <SelectItem key={mins} value={mins} textValue={`less than ${mins} minutes`}>
                less than {mins} minutes
              </SelectItem>
            )
          )}
        </Select>
      </div>

      <FormGroup>
        <RadioGroup
          id="edit-recipe-browse-category-conform-to-user-preferences-radio-group"
          label="Conform to user preferences"
          onChange={e => {
            setConformToUserPreferences(e.target.value === 'true')
          }}
          value={conformToUserPreferences ? 'true' : 'false'}
        >
          <Radio value="true">true</Radio>
          <Radio value="false">false</Radio>
        </RadioGroup>
      </FormGroup>
    </div>
  )
}
