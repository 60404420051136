import React, { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { IngredientSummary, IngredientsIndexScope } from '../../api/ingredients'
import { IoAddCircleOutline, IoCheckmark } from 'react-icons/io5'
import IngredientSearch from '../resources/ingredient/search'

export default function AddIngredientModal({
  open,
  onClose,
  onSelect,
  addedIngredientIds = [],
  scope = null,
}: {
  open: boolean
  onClose: () => void
  onSelect: (ingredient: IngredientSummary) => void
  addedIngredientIds?: string[]
  scope?: IngredientsIndexScope | null
}) {
  const [ingredients, setIngredients] = useState<IngredientSummary[]>([])

  return (
    <Modal size="2xl" placement="bottom-center" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Select ingredients</ModalHeader>
            <ModalBody>
              <IngredientSearch onChange={ingredients => setIngredients(ingredients)} scope={scope}>
                <div
                  style={{
                    maxHeight: 'calc(100vh - 500px)',
                    overflowY: 'auto',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {ingredients.map((ingredient, index) => (
                    <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                      <Button
                        disabled={addedIngredientIds.includes(ingredient.id)}
                        startContent={
                          addedIngredientIds.includes(ingredient.id) ? (
                            <IoCheckmark size={20} />
                          ) : (
                            <IoAddCircleOutline size={20} />
                          )
                        }
                        variant="bordered"
                        color={addedIngredientIds.includes(ingredient.id) ? 'success' : 'secondary'}
                        onClick={async () => {
                          onSelect(ingredient)
                        }}
                      >
                        {ingredient.name}
                      </Button>
                    </div>
                  ))}
                </div>
              </IngredientSearch>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
