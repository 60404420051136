import { Button, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import RecipesAPI from '../../../../api/recipes'
import FormGroup from '../../../forms/form-group'
import { AdminRecipe, SeasonalitiesEnumValues } from '../../../../api/schema'
import SeasonalityInput from '../../../forms/seasonality-input'

export default function RecipeSeasonalityForm({
  recipe,
  onSave,
}: {
  recipe: AdminRecipe
  onSave: () => void
}) {
  const [northernHemisphereSeasonStartDay, setNorthernHemisphereSeasonStartDay] = useState(
    recipe.northernHemisphereSeasonStartDay
  )
  const [northernHemisphereSeasonEndDay, setNorthernHemisphereSeasonEndDay] = useState(
    recipe.northernHemisphereSeasonEndDay
  )
  const [southernHemisphereSeasonStartDay, setSouthernHemisphereSeasonStartDay] = useState(
    recipe.southernHemisphereSeasonStartDay
  )
  const [southernHemisphereSeasonEndDay, setSouthernHemisphereSeasonEndDay] = useState(
    recipe.southernHemisphereSeasonEndDay
  )
  const [seasonality, setSeasonality] = useState(recipe.seasonality)

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Seasonality</h2>

      <FormGroup>
        <Select
          style={{ marginBottom: 20 }}
          label="Seasonality"
          id="edit-recipe-seasonality-select"
          onChange={e => {
            setSeasonality(e.target.value as any)
          }}
          selectedKeys={[seasonality]}
        >
          {SeasonalitiesEnumValues.map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <SeasonalityInput
          id="edit-recipe-northern-hemisphere-seasonality-date-picker"
          label="Northern hemisphere season"
          startRange={northernHemisphereSeasonStartDay}
          endRange={northernHemisphereSeasonEndDay}
          onStartChange={newStart => {
            setNorthernHemisphereSeasonStartDay(newStart)
          }}
          onEndChange={newEnd => {
            setNorthernHemisphereSeasonEndDay(newEnd)
          }}
        />
      </FormGroup>

      <FormGroup>
        <SeasonalityInput
          id="edit-recipe-southern-hemisphere-seasonality-date-picker"
          label="Southern hemisphere season"
          startRange={southernHemisphereSeasonStartDay}
          endRange={southernHemisphereSeasonEndDay}
          onStartChange={newStart => {
            setSouthernHemisphereSeasonStartDay(newStart)
          }}
          onEndChange={newEnd => {
            setSouthernHemisphereSeasonEndDay(newEnd)
          }}
        />
      </FormGroup>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="secondary"
          onPress={async () => {
            await RecipesAPI.update(recipe.id, {
              northernHemisphereSeasonStartDay,
              northernHemisphereSeasonEndDay,
              southernHemisphereSeasonStartDay,
              southernHemisphereSeasonEndDay,
              seasonality,
            })
            onSave()
          }}
        >
          Save seasonality
        </Button>
      </div>
    </div>
  )
}
