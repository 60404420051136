import React, { useState } from 'react'
import { Button, Chip, Input, Select, SelectItem, Textarea } from '@nextui-org/react'
import { toast } from 'react-toastify'
import { IoClose } from 'react-icons/io5'
import LocalizedTextsAPI, { LOCALES, LocalesEnum, LocalizedText } from '../../api/localizedTexts'
import { IconX } from '@tabler/icons-react'
import { sortBy } from 'lodash'
import RecipeCollectionsAPI from '../../api/recipeCollections'
import notify from '../../helpers/notify'

export default function FormLocalizationSection({
  localizableId,
  existingLocalizedTexts,
  onChange,
  apiModule,
  extraArgs,
  extraFields = [],
  excludedFields = [],
  title = 'Localization',
  idPrefix,
}: {
  localizableId: string
  existingLocalizedTexts: LocalizedText[]
  onChange: (locale: LocalesEnum) => void | Promise<void>
  apiModule: typeof RecipeCollectionsAPI
  extraArgs?: any
  extraFields?: AdditionalLocalizedTextField[]
  excludedFields?: LocalizationField[]
  title?: string
  idPrefix?: string
}) {
  const [selectedLocale, setSelectedLocale] = useState<LocalesEnum>('en-US')
  const selectedLocalizedText = existingLocalizedTexts.find(locale => locale.locale === selectedLocale)

  const [selectedLocaleName, setSelectedLocaleName] = useState(selectedLocalizedText?.name || '')
  const [selectedLocaleTitle, setSelectedLocaleTitle] = useState(selectedLocalizedText?.title || '')
  const [selectedLocaleSubtitle, setSelectedLocaleSubtitle] = useState(selectedLocalizedText?.subtitle || '')
  const [selectedLocaleBody, setSelectedLocaleBody] = useState(selectedLocalizedText?.body || '')
  const [selectedLocaleAlt, setSelectedLocaleAlt] = useState(selectedLocalizedText?.alt || '')
  const [selectedLocaleCTA, setSelectedLocaleCTA] = useState(selectedLocalizedText?.cta || '')
  const [selectedLocaleCallout, setSelectedLocaleCallout] = useState(selectedLocalizedText?.callout || '')
  const [selectedLocaleDescription, setSelectedLocaleDescription] = useState(
    selectedLocalizedText?.description || ''
  )
  const [selectedLocaleSummaryDescription, setSelectedLocaleSummaryDescription] = useState(
    selectedLocalizedText?.summaryDescription || ''
  )
  const [selectedLocaleActiveSummaryDescription, setSelectedLocaleActiveSummaryDescription] = useState(
    selectedLocalizedText?.activeSummaryDescription || ''
  )
  const [selectedLocaleTranscription, setSelectedLocaleTranscription] = useState(
    selectedLocalizedText?.transcription || ''
  )

  function setSelectedLocaleAndResetSelectedValues(locale: LocalesEnum) {
    setSelectedLocale(locale)
    const selectedLocalizedText = existingLocalizedTexts.find(l => l.locale === locale)

    setSelectedLocaleName(selectedLocalizedText?.name || '')
    setSelectedLocaleTitle(selectedLocalizedText?.title || '')
    setSelectedLocaleSubtitle(selectedLocalizedText?.subtitle || '')
    setSelectedLocaleBody(selectedLocalizedText?.body || '')
    setSelectedLocaleAlt(selectedLocalizedText?.alt || '')
    setSelectedLocaleCTA(selectedLocalizedText?.cta || '')
    setSelectedLocaleCallout(selectedLocalizedText?.callout || '')
    setSelectedLocaleDescription(selectedLocalizedText?.description || '')
    setSelectedLocaleSummaryDescription(selectedLocalizedText?.summaryDescription || '')
    setSelectedLocaleActiveSummaryDescription(selectedLocalizedText?.activeSummaryDescription || '')
    setSelectedLocaleTranscription(selectedLocalizedText?.transcription || '')
  }

  function shouldShowField(field: LocalizationField) {
    return (
      selectedLocalizedText &&
      fieldExistsForLocalizedTextType(field, selectedLocalizedText) &&
      !excludedFields.includes(field)
    )
  }

  return (
    <div>
      <h1>{title}</h1>
      <div>
        <Select
          id={`${idPrefix ? `${idPrefix}-` : ''}titles-locale-select`}
          items={LOCALES.map(l => ({ label: l, value: l }))}
          value={selectedLocalizedText?.locale}
          label="Select a locale"
          onChange={async e => {
            const val = e.target.value as LocalesEnum
            setSelectedLocale(val)
            const existingLocale = existingLocalizedTexts.find(locale => locale.locale === val)
            if (!existingLocale) {
              await apiModule.createLocalizedText(localizableId, {
                title: '',
                description: '',
                summaryDescription: '',
                locale: val,
                extraArgs,
              })
              await onChange(val)
              setSelectedLocaleAndResetSelectedValues(val)
            }
          }}
        >
          {LOCALES.map((locale, key) => (
            <SelectItem key={locale} value={locale}>
              {locale}
            </SelectItem>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        {sortBy(existingLocalizedTexts, 'locale').map((localizedText, index) => (
          <Chip
            className={`locale-chip ${localizedText.locale} ${
              localizedText === selectedLocalizedText ? 'active' : ''
            }`}
            key={index}
            color={localizedText === selectedLocalizedText ? 'success' : undefined}
            variant="flat"
            endContent={
              localizedText.locale === 'en-US' ? (
                <div style={{ paddingRight: 5 }}></div>
              ) : (
                <IoClose
                  className={`delete-locale ${localizedText.locale}`}
                  size={12}
                  onClick={async () => {
                    await LocalizedTextsAPI.delete(localizedText!.id)
                    notify(`Successfully removed content for ${localizedText.locale}`)

                    const newLocale = 'en-US'
                    await onChange(newLocale)
                    setSelectedLocale(newLocale)
                    setSelectedLocaleAndResetSelectedValues(newLocale)
                  }}
                />
              )
            }
            style={{ cursor: 'pointer', marginRight: 10 }}
            onClick={() => {
              setSelectedLocaleAndResetSelectedValues(localizedText.locale)
            }}
          >
            {localizedText.locale}
          </Chip>
        ))}
      </div>

      {shouldShowField('name') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-name-input"
          placeholder={`Add a name for ${selectedLocale}`}
          label={`${selectedLocale} name`}
          value={selectedLocaleName}
          onChange={e => {
            setSelectedLocaleName(e.target.value)
          }}
        />
      )}

      {shouldShowField('title') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-title-input"
          placeholder={`Add a title for ${selectedLocale}`}
          label={`${selectedLocale} title`}
          value={selectedLocaleTitle}
          onChange={e => {
            setSelectedLocaleTitle(e.target.value)
          }}
        />
      )}

      {shouldShowField('subtitle') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-subtitle-input"
          placeholder={`Add a subtitle for ${selectedLocale}`}
          label={`${selectedLocale} subtitle`}
          value={selectedLocaleSubtitle}
          onChange={e => {
            setSelectedLocaleSubtitle(e.target.value)
          }}
        />
      )}

      {shouldShowField('description') && (
        <Textarea
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-description-input"
          placeholder={`Add a description for ${selectedLocale}`}
          label={`${selectedLocale} description`}
          value={selectedLocaleDescription}
          onChange={e => {
            setSelectedLocaleDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('alt') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-alt-input"
          placeholder={`Add a alt for ${selectedLocale}`}
          label={`${selectedLocale} alt`}
          value={selectedLocaleAlt}
          onChange={e => {
            setSelectedLocaleAlt(e.target.value)
          }}
        />
      )}

      {shouldShowField('summaryDescription') && (
        <Textarea
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-summary-description-input"
          placeholder={`Add a description summary for ${selectedLocale}`}
          label={`${selectedLocale} description summary`}
          value={selectedLocaleSummaryDescription}
          onChange={e => {
            setSelectedLocaleSummaryDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('activeSummaryDescription') && (
        <Textarea
          required
          classNames={{
            base: 'mb-5',
          }}
          id={`locale-active-summary-description-input`}
          placeholder="add an active summary description"
          label={`${selectedLocale} active summary description`}
          value={selectedLocaleActiveSummaryDescription}
          onChange={e => {
            setSelectedLocaleActiveSummaryDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('body') && (
        <Textarea
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-body-input"
          placeholder={`Add a body for ${selectedLocale}`}
          label={`${selectedLocale} body`}
          value={selectedLocaleBody}
          onChange={e => {
            setSelectedLocaleBody(e.target.value)
          }}
        />
      )}

      {shouldShowField('cta') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-cta-input"
          placeholder={`Add a cta for ${selectedLocale}`}
          label={`${selectedLocale} cta`}
          value={selectedLocaleCTA}
          onChange={e => {
            setSelectedLocaleCTA(e.target.value)
          }}
        />
      )}

      {shouldShowField('callout') && (
        <Input
          required
          classNames={{
            base: 'mb-5',
          }}
          id="locale-callout-input"
          placeholder={`Add a callout for ${selectedLocale}`}
          label={`${selectedLocale} callout`}
          value={selectedLocaleCallout}
          onChange={e => {
            setSelectedLocaleCallout(e.target.value)
          }}
        />
      )}

      {shouldShowField('transcription') && (
        <Textarea
          required
          classNames={{
            base: 'mb-5',
          }}
          id={`locale-transcription-input`}
          placeholder="add a transcription"
          label={`${selectedLocale} transcription`}
          value={selectedLocaleTranscription}
          onChange={e => {
            setSelectedLocaleTranscription(e.target.value)
          }}
        />
      )}

      <div style={{ textAlign: 'right' }}>
        <Button
          className="filled green text-white"
          color="success"
          onPress={async () => {
            await LocalizedTextsAPI.update(selectedLocalizedText!.id, {
              name: selectedLocaleName,
              title: selectedLocaleTitle,
              subtitle: selectedLocaleSubtitle,
              description: selectedLocaleDescription,
              alt: selectedLocaleAlt,
              summaryDescription: selectedLocaleSummaryDescription,
              activeSummaryDescription: selectedLocaleActiveSummaryDescription,
              body: selectedLocaleBody,
              cta: selectedLocaleCTA,
              callout: selectedLocaleCallout,
              transcription: selectedLocaleTranscription,
              locale: selectedLocale,
            })
            notify(`Successfully made changes to ${selectedLocale}`)
            await onChange(selectedLocale)
          }}
        >
          save
        </Button>
      </div>
    </div>
  )
}

export type LocalizationField =
  | 'name'
  | 'title'
  | 'subtitle'
  | 'description'
  | 'summaryDescription'
  | 'cta'
  | 'callout'
  | 'activeSummaryDescription'
  | 'body'
  | 'transcription'
  | 'alt'

export type AdditionalLocalizedTextField = 'transcription' | 'activeSummaryDescription'

function fieldExistsForLocalizedTextType(field: keyof LocalizedText, localizedText: LocalizedText) {
  switch (localizedText.type) {
    case 'ImageLocalizedText':
      return ['title', 'alt'].includes(field)
    case 'StandardLocalizedText':
      return ['title', 'subtitle', 'summaryDescription', 'description'].includes(field)
    default:
      throw `Unrecognized localized text type: ${localizedText.type}`
  }
}
