import { useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  SelectItem,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
} from '@nextui-org/react'
import { useCurrentEditor } from '@tiptap/react'
import { IngredientSummary } from '../../api/ingredients'
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5'
import AddIngredientModal from './add-ingredient-modal'
import RecipeVariantIngredientsAPI from '../../api/recipeVariantIngredients'
import RecipeVariantIngredientOverrideOptionsAPI from '../../api/recipeVariantIngredientOverrideOptions'
import { AdminRecipeVariantDetail, AdminRecipeVariantIngredient } from '../../api/schema'

export default function EditRecipeVariantIngredientModal({
  open,
  onClose,
  onSave,
  recipeVariant,
  recipeVariantIngredient,
}: {
  open: boolean
  onClose: () => void
  onSave: () => void
  recipeVariant: AdminRecipeVariantDetail
  recipeVariantIngredient: AdminRecipeVariantIngredient
}) {
  const [modalType, setModalType] = useState<EditRecipeVariantStepNestedModalType | null>(null)
  const [optional, setOptional] = useState(recipeVariantIngredient.optional)
  const [imperialAmount, setImperialAmount] = useState(recipeVariantIngredient.imperialAmount)
  const [metricAmount, setMetricAmount] = useState(recipeVariantIngredient.metricAmount)
  const overrides = recipeVariant.recipeVariantIngredientOverrideOptions.filter(
    override => override.overriddenIngredient.id === recipeVariantIngredient.ingredient.id
  )

  return (
    <Modal size="3xl" isOpen={open} onClose={onClose}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Edit {recipeVariantIngredient.ingredient.name}
            </ModalHeader>
            <ModalBody>
              <Select
                id="edit-recipe-variant-ingredient-optional"
                selectedKeys={optional ? ['true'] : ['false']}
                label="optional"
                onChange={async e => {
                  switch (e.target.value) {
                    case 'true':
                      setOptional(true)
                      break
                    case 'false':
                      setOptional(false)
                      break
                  }
                }}
              >
                <SelectItem key={'true'} textValue={'true'}>
                  true
                </SelectItem>
                <SelectItem key={'false'} textValue={'false'}>
                  false
                </SelectItem>
              </Select>

              <Input
                type="number"
                id="edit-recipe-variant-ingredient-imperial-amount"
                label={`Imperial amount (unit: ${recipeVariantIngredient.ingredient.imperialUnit})`}
                value={imperialAmount.toString()}
                onChange={e => {
                  setImperialAmount(parseFloat(e.target.value))
                }}
              />

              <Input
                type="number"
                id="edit-recipe-variant-ingredient-metric-amount"
                label={`Metric amount (unit: ${recipeVariantIngredient.ingredient.imperialUnit})`}
                value={metricAmount.toString()}
                onChange={e => {
                  setMetricAmount(parseFloat(e.target.value))
                }}
              />

              {recipeVariantIngredient.ingredient.isPlaceholder ? (
                <>
                  <div className="flex gap-4 items-center">
                    <h2>Substitute Ingredients</h2>
                  </div>
                  <Table aria-label="Alternate Ingredients">
                    <TableHeader>
                      <TableColumn>ID</TableColumn>
                      <TableColumn>NAME</TableColumn>
                    </TableHeader>

                    <TableBody>
                      {recipeVariantIngredient.ingredient.substitutes.map((ingredient, index) => (
                        <TableRow key={index}>
                          <TableCell>{ingredient.id || 'N/A'}</TableCell>
                          <TableCell>{ingredient.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <>
                  <div className="flex gap-4 items-center">
                    <h2>Alternate Ingredients</h2>
                    <Button
                      className="text-white"
                      color="success"
                      aria-label="Add recipe"
                      style={{ marginLeft: 'auto' }}
                      onClick={async () => {
                        setModalType('add_alternative_ingredient')
                      }}
                    >
                      <IoAddCircleOutline size={20} />
                      Add alternate ingredient
                    </Button>
                  </div>
                  <Table aria-label="Alternate Ingredients">
                    <TableHeader>
                      <TableColumn>ID</TableColumn>
                      <TableColumn>NAME</TableColumn>
                      <TableColumn>ACTIONS</TableColumn>
                    </TableHeader>

                    <TableBody>
                      {overrides.map((override, index) => (
                        <TableRow key={index}>
                          <TableCell>{override.ingredient.id || 'N/A'}</TableCell>
                          <TableCell>{override.ingredient.name}</TableCell>
                          <TableCell>
                            <Button
                              isIconOnly
                              style={{ marginLeft: 20 }}
                              id={`delete-recipe-variant-ingredient-override-${override.ingredient.id}`}
                              onClick={async () => {
                                await RecipeVariantIngredientOverrideOptionsAPI.destroy(override.id)
                                onSave()
                              }}
                            >
                              <IoTrash size={20} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <AddIngredientModal
                    open={modalType === 'add_alternative_ingredient'}
                    onClose={() => {
                      setModalType(null)
                    }}
                    onSelect={async ingredient => {
                      await RecipeVariantIngredientOverrideOptionsAPI.create({
                        recipeVariantId: recipeVariant.id,
                        ingredientId: ingredient.id,
                        overriddenIngredientId: recipeVariantIngredient.ingredient.id,
                      })
                      setModalType(null)
                      onSave()
                    }}
                    addedIngredientIds={overrides.map(override => override.ingredient.id)}
                    scope="non-placeholder-ingredients"
                  />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                color="success"
                variant="flat"
                onPress={async () => {
                  await RecipeVariantIngredientsAPI.update(recipeVariantIngredient.id, {
                    optional,
                    imperialAmount,
                    metricAmount,
                  })

                  onSave()
                  onClose()
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export function MenuBar({ ingredients }: { ingredients: IngredientSummary[] }) {
  const { editor } = useCurrentEditor()
  return (
    <Select
      style={{ marginBottom: 20 }}
      label="inject ingredient"
      onChange={e => {
        if (!editor) return
        const value = e.target.value
        const position = editor.state.selection.anchor
        editor.commands.deleteSelection()
        editor.commands.insertContentAt(position, `{% ingredient ${value} %}`, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: 'full',
          },
        })
      }}
    >
      {ingredients.map(ingredient => (
        <SelectItem key={ingredient.id} value={ingredient.id}>
          {ingredient.name} ({ingredient.id})
        </SelectItem>
      ))}
    </Select>
  )
}

type EditRecipeVariantStepNestedModalType = 'add_alternative_ingredient'
