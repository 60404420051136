import { useState } from 'react'
import Tiptap from '../../providers/Tiptap'
import { IoAddCircleOutline, IoPencil, IoTrash } from 'react-icons/io5'
import { Button } from '@nextui-org/react'
import ConfirmModal from '../../modals/confirm'
import { AdminRecipeVariantCookingTipSummary, AdminRecipeVariantStep } from '../../../api/schema'
import RecipeVariantStepsAPI from '../../../api/recipeVariantSteps'
import AddRecipeVariantStepCookingTipModal from '../../modals/add-recipe-variant-step-cooking-tip-modal'

export function RecipeVariantStepCookingTipCrud({
  recipeVariantStepStepCookingTip,
  recipeVariantStep,
  onSave,
}: {
  recipeVariantStepStepCookingTip?: AdminRecipeVariantCookingTipSummary
  recipeVariantStep: AdminRecipeVariantStep
  onSave: () => void
}) {
  const [activeModal, setActiveModal] = useState<RecipeVariantStepCookingTipCrudModalType | null>(null)

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <h2>Cooking tip</h2>

      <div style={{ width: 110, display: 'inline-flex', verticalAlign: 'top' }}>
        {recipeVariantStepStepCookingTip ? (
          <Button
            id={`edit-recipe-variant-step-cooking-tip-${recipeVariantStepStepCookingTip.id}`}
            isIconOnly
            onPress={() => setActiveModal('edit_recipe_cooking_tip')}
            startContent={<IoPencil />}
          ></Button>
        ) : (
          <Button
            id="create-recipe-variant-step-cooking-tip"
            className="text-white"
            color="success"
            aria-label="Add recipe"
            style={{ marginLeft: 'auto', minWidth: 170 }}
            onPress={() => setActiveModal('edit_recipe_cooking_tip')}
          >
            <IoAddCircleOutline size={20} />
            Add Cooking Tip
          </Button>
        )}

        {recipeVariantStepStepCookingTip ? (
          <Button
            id={`delete-recipe-variant-step-cooking-tip-${recipeVariantStepStepCookingTip.id}`}
            style={{ marginLeft: 10 }}
            isIconOnly
            onPress={() => setActiveModal('confirm_delete_recipe_cooking_tip')}
          >
            <IoTrash />
          </Button>
        ) : null}
      </div>

      {recipeVariantStepStepCookingTip ? (
        <div style={{ width: 400, display: 'inline-flex', verticalAlign: 'top' }}>
          <div>
            <Tiptap
              id={`display-recipe-cooking-step-tip-${recipeVariantStepStepCookingTip.id}`}
              readonly
              controlled
              content={recipeVariantStepStepCookingTip.body}
            />
          </div>
        </div>
      ) : null}

      <AddRecipeVariantStepCookingTipModal
        open={activeModal === 'edit_recipe_cooking_tip'}
        onClose={() => {
          setActiveModal(null)
        }}
        onSelect={async recipeVariantStepCookingTip => {
          await RecipeVariantStepsAPI.update(recipeVariantStep.id, {
            recipeVariantStepCookingTipId: recipeVariantStepCookingTip.id,
          })
          setActiveModal(null)
          onSave()
        }}
      />

      <ConfirmModal
        open={activeModal === 'confirm_delete_recipe_cooking_tip'}
        onClose={() => {
          setActiveModal(null)
        }}
        onConfirm={async () => {
          await RecipeVariantStepsAPI.update(recipeVariantStep.id, { recipeVariantStepCookingTipId: null })
          setActiveModal(null)
          onSave()
        }}
        header="Confirm"
      >
        Are you sure you want to delete this cooking tip?
      </ConfirmModal>
    </div>
  )
}

type RecipeVariantStepCookingTipCrudModalType =
  | 'edit_recipe_cooking_tip'
  | 'confirm_delete_recipe_cooking_tip'
